const theme = {
  color: {
    primary: '#FF7062',
    secondary: '#CCCCCC',
    action: '#FF7062',
    actionHover: '#000000',
    text: '#000000',
    textLight: '#333333',
    disabled: '#CCCCCC',
    white: '#FFFFFF',
    whiteOffset: '#EEEEEE',
    grayLighter: '#CCCCCC',
    gray60: '#9B9B9B',
    error: '#EE0000',
  },
  font: {
    sansSerif: '"Source Sans Pro", sans-serif',
    serif: '"Source Serif Pro", serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
  },
  button: {
    borderRadius: 3,
  },
  screen: {},
  section: {},
  padding: {
    small: 5,
    base: 10,
    large: 20,
  },
  border: {
    radius: 4,
  },
};

export default theme;
