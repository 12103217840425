import { connect } from 'react-redux';

import PageShowView from './page_show_view';

import { getPages } from '../../../../reducer';

const mapStateToProps = (state, { entity }) => ({
  page: getPages(state).filter((p) => p.referenced_by === entity.id).first(),
});

const PageShowContainer = connect(mapStateToProps)(PageShowView);

export default PageShowContainer;
