import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  referenced_by: null,
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  all_day: null,
  location: null,
  description: null,
  created_at: null,
  updated_at: null,
};

class EventModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::Event';

  static validate(values) {
    const errors = {};

    if (!values) return errors;

    const required = ['start_date', 'end_date', 'location'];
    if (!values.get('all_day')) required.push('start_time', 'end_time');

    required.forEach((f) => { if (!values.get(f)) errors[f] = 'Required'; });

    return errors;
  }
}

export default EventModel;
