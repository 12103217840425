import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  email: null,
  invitation_accepted_at: null,
  invitation_expires_at: null,
  is_expired: null,
  created_at: null,
  updated_at: null,
};

class InvitationModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::Invitation';
}

export default InvitationModel;
