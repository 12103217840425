import styled from 'styled-components';

const PageShowStyles = styled.div`
`;

export const Profile = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.foundation.tabBar.borderColor};
  box-shadow: 0 5px 5px rgba(0,0,0,0.1);
`;

export const Body = styled.div`
  margin: 30px 10px;
`;

export default PageShowStyles;
