import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { parse } from 'query-string';

import UserModel from '../../../session/models/user_model';

class InitializerView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel).isRequired,
    listEntityTypes: PropTypes.func.isRequired,
    listGlobalSettings: PropTypes.func.isRequired,
    refreshToken: PropTypes.func.isRequired,
    setFirstUrl: PropTypes.func.isRequired,
    setFirstParams: PropTypes.func.isRequired,
    router: PropTypes.instanceOf(Map).isRequired,
    settings: PropTypes.instanceOf(Map).isRequired,
  };

  componentDidMount() {
    const { user, router, settings, setFirstUrl, setFirstParams, refreshToken, listEntityTypes, listGlobalSettings } = this.props;

    if (refreshToken && user && user.signedIn) refreshToken();
    if (listGlobalSettings) listGlobalSettings();
    if (listEntityTypes) listEntityTypes();
    if (setFirstUrl && !settings.get('firstUrl')) setFirstUrl(router.get('location').get('pathname'));
    if (setFirstParams && !settings.get('firstParams')) setFirstParams(parse(router.get('location').get('search')));
  }

  render() {
    return null;
  }
}

export default InitializerView;
