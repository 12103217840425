import { connect } from 'react-redux';
import { Map, merge } from 'immutable';

import { responseValue } from '../data_set_response_form';

import DataSetResponseEditView from './data_set_response_edit_view';

import { getForm, getFormSettings, getCurrentUser, getCurrentEntity, getEntityType, getDataSets, getDataSetResponses } from '../../../../reducer';
import { fetchCurrentEntitySubject, updateDataSetResponse } from '../../../actions';

const mapStateToProps = (state, { entity }) => {
  const user = getCurrentUser(state);
  const subject = entity && getCurrentEntity(state, entity.subject_id);
  const entityType = entity && getEntityType(state, entity.entity_type);
  const dataSetResponse = getDataSetResponses(state).filter((dsr) => dsr.referenced_by === entity.id).first();
  const dataSet = dataSetResponse && getDataSets(state).filter((ds) => ds.id === dataSetResponse.data_set).first();
  const id = dataSetResponse && dataSetResponse.id;
  // eslint-disable-next-line new-cap
  const fields = dataSet && merge({}, ...dataSet.fields.map((field) => Map([[field.get('id'), responseValue(field, subject, dataSetResponse)]])));
  const form = `entity:${entity.id}:edit`;

  return {
    user,
    entityType,
    subject,
    dataSet,
    dataSetResponse,
    id,
    form,
    dataSetResponseForm: getForm(state).get(form),
    formSettings: getFormSettings(state),
    initialValues: { fields },
  };
};

const mapDispatchToProps = {
  fetchCurrentEntitySubject,
  updateDataSetResponse,
};

const DataSetResponseEditContainer = connect(mapStateToProps, mapDispatchToProps)(DataSetResponseEditView);

export default DataSetResponseEditContainer;
