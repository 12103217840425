import { Map } from 'immutable';
import actions from '../actions';

const DEFAULT_STATE = new Map();

function SelectedChildEntityPermissionsReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.SET_SELECTED_CHILD_ENTITY_PERMISSIONS:
      return action.permissions;
    default:
      return state;
  }
}

export default SelectedChildEntityPermissionsReducer;
