import { connect } from 'react-redux';
import { submit } from 'redux-form';

import GenericStatusView from './generic_status_view';

import { getForm } from '../../../../reducer';

const mapStateToProps = (state, { entity }) => ({
  form: `entity:${entity.id}:edit`,
  formData: entity && getForm(state).get(`entity:${entity.id}:edit`),
});

const mapDispatchToProps = {
  submit,
};

const GenericStatusContainer = connect(mapStateToProps, mapDispatchToProps)(GenericStatusView);

export default GenericStatusContainer;
