import styled from 'styled-components';

const DirectoryShowStyles = styled.div`
  padding-top: 20px;

  .load-more {
    text-align: center;
    padding: 10px 0 40px;
  }
`;

export const CtaBlock = styled.div`
  margin: 0 10px 20px;
  text-align: center;
  display: flex;
  justify-content: start;

  div.icon a, div.icon button {
    display: inline-block;
    background-color: ${(props) => props.theme.foundation.content.iconBackgroundColor};
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-right: 15px;
  }

  div.name-description {
    overflow: hidden;

    a, button {
      font-weight: 500;
      font-size: 1.1em;
      padding-bottom: 5px;
      overflow: hidden;
    }
  }
`;

export default DirectoryShowStyles;
