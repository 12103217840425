import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';

import InvitationsList from '../../invitations_list';

import UserModel from '../../../../session/models/user_model';

import InvitationsShowStyles from './invitations_show_styles';

class InvitationsShowView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel),
    invitations: PropTypes.instanceOf(OrderedSet),
    listInvitations: PropTypes.func.isRequired,
    deleteInvitation: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: null,
    invitations: new OrderedSet(),
  }

  constructor(props) {
    super(props);

    this.deleteInvitation = this.deleteInvitation.bind(this);
  }

  componentDidMount() {
    const { user, listInvitations } = this.props;

    if (user && listInvitations) listInvitations(user.authToken);
  }

  deleteInvitation(id) {
    const { user, deleteInvitation } = this.props;

    if (user && deleteInvitation) deleteInvitation(user.authToken, id);
  }

  render() {
    const { invitations } = this.props;

    return (
      <InvitationsShowStyles>
        <InvitationsList invitations={invitations} deleteInvitation={this.deleteInvitation} />
      </InvitationsShowStyles>
    );
  }
}

export default InvitationsShowView;
