import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  name: 'New Entity',
  entity_type: null,
  entity_user: null,
  visibility: null,
  join_data_set_id: null,
  redirect_id: null,
  subject_id: null,
  subject_type: null,
  reference_type: null,
  reference_data: null,
  reference_status: null,
  tags: [],
  field_responses: [],
  created_at: null,
  updated_at: null,
};

class EntityModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::Entity';

  initials() {
    return this.name.split(/ /).map((p) => p[0]).join('').substr(0, 2);
  }

  visibilityName() {
    switch (this.visibility) {
      case 'v_restricted': return 'Restricted';
      case 'v_unlisted': return 'Unlisted';
      case 'v_contextual': return 'Contextual';
      case 'v_public': return 'Public';
      case 'v_global': return 'Global';
      default: return '';
    }
  }

  visibilityIcon() {
    switch (this.visibility) {
      case 'v_restricted': return 'lock';
      case 'v_unlisted': return 'eye-slash';
      case 'v_contextual': return 'stream';
      case 'v_public': return 'lock-open';
      case 'v_global': return 'globe-americas';
      default: return '';
    }
  }
}

export default EntityModel;
