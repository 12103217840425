import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { OrderedSet, Record } from 'immutable';
import ReactTags from 'react-tag-autocomplete';

import Button from '../../button';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import RelationshipModel from '../../../models/relationship_model';

import HierarchyShowStyles, { ReactTagsContainer } from './hierarchy_show_styles';

const HierarchyShowView = ({ entity, entityType, childEntity, childEntityType, relationship, tagEntityType, currentEntities, currentRelationships,
  doHandleAddition, doHandleDelete, doCreateCurrentEntity, doFetchListEntity, doDeleteListRelationship, canManageEntity }) => {
  const [tempTagList, setTempTagList] = useState(null);
  const [redirectToEntity, setRedirectToEntity] = useState(false);

  const relationshipMap = currentRelationships.toMap().mapEntries(([_k, v]) => [`${v.parent}:${v.child}`, v]);
  const tagEntities = Record.isRecord(childEntity) && childEntity.tags.map((tag) => (Record.isRecord(tag) ? tag : new EntityModel(tag))).sortBy((e) => e.name)
    .filter((tagEntity) => {
      const tagRelationship = relationshipMap.get(`${tagEntity.id}:${entity.id}`);
      return (entity.id === tagEntity.id) || (tagRelationship && tagRelationship.relationship_type !== 'associated');
    });

  const selectedTagEntities = tempTagList || tagEntities;

  const tagEntityIds = selectedTagEntities && selectedTagEntities.map((e) => e.id);
  const tagSuggestions = currentEntities.filter((e) => (Record.isRecord(e) && Record.isRecord(tagEntityType) && e.entity_type === tagEntityType.id &&
                                                       !tagEntityIds.includes(e.id)));

  const addExistingTag = (tagId) => {
    if (tagId) {
      doHandleAddition(tagId, { tagged_add: childEntity.id })
        .catch(() => setTempTagList(null))
        .then(() => doFetchListEntity(childEntity.id)).then(() => setTempTagList(null));
    }
  };

  const handleAddition = (tag) => {
    setTempTagList(tagEntities.push(tag).sortBy((e) => e.name));

    if (tag.id) {
      addExistingTag(tag.id);
    } else if (tag.name) {
      doCreateCurrentEntity({ name: tag.name, entity_type: tagEntityType.id, children: [entity.id] })
        .then((response) => addExistingTag(response.result));
    }
  };

  const handleDelete = (idx) => {
    setTempTagList(tagEntities.splice(idx, 1).sortBy((e) => e.name));

    const tag = tagEntities.slice(idx, idx + 1).first();
    if (tag) {
      doHandleDelete(tag.id, { tagged_delete: childEntity.id })
        .catch(() => setTempTagList(null))
        .then(() => doFetchListEntity(childEntity.id)).then(() => setTempTagList(null));
    }
  };

  const deleteRelationship = (parent, child) => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (relationship && confirm(`Remove ${child.name} from ${parent.name}? ${child.name} will not be notified.`)) {
      doDeleteListRelationship(relationship.get('id')).then(() => setRedirectToEntity(true));
    }
  };

  if (redirectToEntity) return <Redirect to={entityType.absoluteUrl(entity.id)} />;

  return Record.isRecord(entity) && Record.isRecord(childEntity) && Record.isRecord(childEntityType) && (
    <HierarchyShowStyles>
      <div className="row">
        <div className="col-xs-8">
          <ReactTagsContainer>
            {canManageEntity && (
              <ReactTags
                tags={selectedTagEntities.toJS()}
                suggestions={tagSuggestions.toJS()}
                handleAddition={handleAddition}
                handleDelete={handleDelete}
                allowNew
              />
            )}
            {!canManageEntity && selectedTagEntities.map((e) => (
              <div className="react-tags__selected-tag react-tags__selected-tag-readonly" key={e}>
                {e.name}
              </div>
            ))}
          </ReactTagsContainer>
        </div>
        <div className="col-xs-4" style={{ textAlign: 'right' }}>
          {canManageEntity && (
            <Button treatment="button" secondary="true" thin="true" onClick={() => deleteRelationship(entity, childEntity)}>
              <i className="fas fa-minus-square" /> Remove...
            </Button>
          )}
        </div>
      </div>
    </HierarchyShowStyles>
  );
};

HierarchyShowView.propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  childEntity: PropTypes.instanceOf(EntityModel).isRequired,
  childEntityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  relationship: PropTypes.instanceOf(RelationshipModel).isRequired,
  tagEntityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  tagEntities: PropTypes.instanceOf(OrderedSet),
  currentEntities: PropTypes.instanceOf(OrderedSet),
  currentRelationships: PropTypes.instanceOf(OrderedSet),
  doCreateCurrentEntity: PropTypes.func.isRequired,
  doFetchListEntity: PropTypes.func.isRequired,
  doDeleteListRelationship: PropTypes.func.isRequired,
  doHandleAddition: PropTypes.func.isRequired,
  doHandleDelete: PropTypes.func.isRequired,
  canManageEntity: PropTypes.bool,
};

HierarchyShowView.defaultProps = {
  tagEntities: new OrderedSet(),
  currentEntities: new OrderedSet(),
  currentRelationships: new OrderedSet(),
  canManageEntity: false,
};

export default HierarchyShowView;
