import styled from 'styled-components';
// import theme from '../theme';

const InvitationCreateStyles = styled.div`
  i.fa-spin {
    margin-left: 20px;
  }
`;

export default InvitationCreateStyles;
