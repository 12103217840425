import styled from 'styled-components';

const DataSetShowStyles = styled.div`
`;

export const Body = styled.div`
  padding: 10px;
`;

export default DataSetShowStyles;
