import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import ReactMarkdown from 'react-markdown';
import { TextField, SelectField } from '../../../common/form';
import FoundationFieldStyles, { SubLabel, ContentName, ContentDescription } from './foundation_field_styles';

const fieldComponent = (field) => {
  switch (field && field.get('field_type')) {
    case 'text': return TextField;
    case 'select': return SelectField;
    case 'multiselect': return SelectField;
    default: return TextField;
  }
};

const fieldOptions = (field) => {
  if (field.get('field_type') !== 'select' && field.get('field_type') !== 'multiselect') return null;

  return new List(field.get('options')).filter((option) => !option.get('hidden'));
};

const FoundationFieldView = ({ field }) => {
  const FieldComponent = fieldComponent(field);

  return field && (
    <React.Fragment>
      {field.get('line_break_before') && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" />}
      <FoundationFieldStyles
        className={`col-md-${field.get('numeric_width_factor')} ` +
                   `col-sm-${Math.max(field.get('numeric_width_factor'), 12)} ` +
                   `col-xs-${Math.max(field.get('numeric_width_factor'), 12)}`}
      >
        {field.get('field_type') === 'content' && (
          <div>
            {field.get('name') && <ContentName>{field.get('name')}</ContentName>}
            {field.get('description') && <ContentDescription><ReactMarkdown source={field.get('description')} /></ContentDescription>}
          </div>
        )}
        {field.get('field_type') !== 'content' && (
          <FieldComponent
            name={`fields[${field.get('id')}]`}
            label={field.get('name')}
            type={field.get('field_type')}
            required={field.get('required')}
            options={fieldOptions(field)}
            rows={field.get('text_rows')}
            textarea={field.get('field_type') === 'text' && field.get('text_rows') > 1}
            isMulti={field.get('field_type') === 'multiselect'}
          >
            {field.get('description') && (
              <SubLabel>
                <ReactMarkdown source={`${field.get('description')}`} />
              </SubLabel>
            )}
          </FieldComponent>
        )}
      </FoundationFieldStyles>
      {field.get('line_break_after') && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" />}
    </React.Fragment>
  );
};

FoundationFieldView.propTypes = {
  field: PropTypes.instanceOf(Map).isRequired,
};

FoundationFieldView.defaultProps = {
};

export default FoundationFieldView;
