import styled from 'styled-components';

const TagShowStyles = styled.div`
`;

export const Body = styled.div`
  padding: 10px;
`;

export default TagShowStyles;
