import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { OrderedSet } from 'immutable';

import UserModel from '../../../session/models/user_model';

import Navigator from '../navigator';
import SignIn from '../../../session/components/sign_in';

import RootStyles from './root_styles';

import theme from '../../common/theme';

class RootView extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel),
    userEntities: PropTypes.instanceOf(OrderedSet),
    fetchUserEntity: PropTypes.func.isRequired,
    fetchUserEntityAncestors: PropTypes.func.isRequired,
    listEntityTypes: PropTypes.func.isRequired,
    doSetUserEntitiesPermissions: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
      }),
    }).isRequired,
    entityTypesLoading: PropTypes.bool,
  };

  static defaultProps = {
    user: null,
    userEntities: new OrderedSet(),
    entityTypesLoading: true,
  };

  componentDidMount() {
    const { doSetUserEntitiesPermissions } = this.props;

    this.syncUserEntities();
    this.syncEntityTypes();
    doSetUserEntitiesPermissions();
  }

  componentDidUpdate(prevProps) {
    const { user, userEntities, doSetUserEntitiesPermissions } = this.props;
    const prevUser = prevProps.user;
    const prevUserEntities = prevProps.userEntities;

    if (!user || !prevUser || (!user.get('entityUids') && !prevUser.get('entityUids'))) return;

    const userChanged = user && (!prevUser || user.id !== prevUser.id);
    const entityUidsChanged = !user.get('entityUids') || !prevUser.get('entityUids') ||
        JSON.stringify(user.get('entityUids').sort()) !== JSON.stringify(prevUser.get('entityUids').sort());
    const userEntitiesChanged = userEntities && (!prevUserEntities || userEntities.hashCode() !== prevUserEntities.hashCode());

    if (userChanged) this.syncEntityTypes();
    if (userChanged || entityUidsChanged) this.syncUserEntities();
    if (userEntitiesChanged) doSetUserEntitiesPermissions();
  }

  syncUserEntities() {
    const { user, fetchUserEntity, fetchUserEntityAncestors } = this.props;

    if (user && user.signedIn && user.get('entityUids')) {
      user.get('entityUids').forEach((id) => fetchUserEntity(user.authToken, id).then(() => fetchUserEntityAncestors(user.authToken, id)));
    }
  }

  syncEntityTypes() {
    const { user, listEntityTypes } = this.props;

    if (user && user.signedIn) {
      listEntityTypes(user.authToken);
    }
  }

  render() {
    const { user, entityTypesLoading } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <RootStyles className={`container-fluid ${(user && user.signedIn) ? 'user-signedin' : 'user-signedout'}`}>
          {!entityTypesLoading && (
            <Switch>
              {(!user || !user.signedIn) && <Route exact path="/" component={SignIn} />}
              {user && user.signedIn && <Route exact path="/" component={Navigator} />}
              <Route exact path="/:entityTypeSlug" component={Navigator} />
              <Route exact path="/:entityTypeSlug/:entityId" component={Navigator} />
              <Route exact path="/:entityTypeSlug/:entityId/:entityAction" component={Navigator} />
              <Route exact path="/:entityTypeSlug/:entityId/:childEntityTypeSlug/:childEntityId" component={Navigator} />
              <Route exact path="/:entityTypeSlug/:entityId/:childEntityTypeSlug/:childEntityId/:childEntityAction" component={Navigator} />
            </Switch>
          )}
        </RootStyles>
      </ThemeProvider>
    );
  }
}

export default RootView;

/*
  Routes:

  /:entityTypeSlug (list) --- Future
   - /organizations
   - /data_sets

  /:entityTypeSlug/:entityId (show)
   - /organizations/abcdefgh
   - /data_sets/ijklmnop

  /:entityTypeSlug/:entityId/:entityAction (edit + special pages: settings, permissions, etc)
   - /organizations/abcdefgh/edit --- What does it mean to "edit" a hierarchy entity? Possibly nothing?
   - /data_sets/ijklmnop/edit --- Edit the data set itself (fields, order, layout, etc)

  /:entityTypeSlug/:entityId/:childEntityTypeSlug/new (or other statically defined actions)
   - /organizations/abcdefgh/individuals/new --- New individual as a child of the entity
   - /data_sets/ijklmnop/responses/new --- New response to the data set

  /:entityTypeSlug/:entityId/:childEntityTypeSlug/:childEntityId (show childEntity in split screen view)
   - /organizations/abcdefgh/individuals/qrstuvwx
   - /data_sets/ijklmnop/responses/yz123456

  /:entityTypeSlug/:entityId/:childEntityTypeSlug/:childEntityId/:childEntityAction (edit + special pages for childEntity in split screen view)
   - /organizations/abcdefgh/individuals/qrstuvwx/edit --- Allow editing (per above), managing permissions, etc
   - /data_sets/ijklmnop/responses/yz123456/edit --- Edit the response
*/
