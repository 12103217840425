import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Map, OrderedSet, List, Record } from 'immutable';

import CollapseableSection from './collapseable_section';

import UserModel from '../../../session/models/user_model';
import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import Button from '../button';
import StyledLink from '../styled_link';

import SidebarStyles, { BackgroundContainer, ContentContainer, Content, Collapse, ReferencedEntities } from './sidebar_styles';

class SidebarView extends React.Component {
  static propTypes = {
    childEntityId: PropTypes.string,
    user: PropTypes.instanceOf(UserModel),
    toggleSidebar: PropTypes.func.isRequired,
    collapsed: PropTypes.bool,
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    currentEntities: PropTypes.instanceOf(OrderedSet),
    userEntitiesManager: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    dataSets: PropTypes.instanceOf(OrderedSet),
    dataSetResponses: PropTypes.instanceOf(OrderedSet),
    showPrimaryPanel: PropTypes.bool,
    exportedEntitiesLoading: PropTypes.bool,
    listExportedEntities: PropTypes.func.isRequired,
    createDataSetResponse: PropTypes.func.isRequired,
    getEntityType: PropTypes.func.isRequired,
    getEntityTypeByEntityClass: PropTypes.func.isRequired,
  }

  static defaultProps = {
    childEntityId: null,
    user: null,
    collapsed: true,
    entity: null,
    entityType: null,
    currentEntities: new OrderedSet(),
    userEntitiesManager: new OrderedSet(),
    dataSets: new OrderedSet(),
    dataSetResponses: new OrderedSet(),
    showPrimaryPanel: false,
    exportedEntitiesLoading: false,
  }

  constructor(props) {
    super(props);

    this.getContextualEntityReferences = this.getContextualEntityReferences.bind(this);
    this.parseCreatedEntity = this.parseCreatedEntity.bind(this);
    this.canCreateContextualEntity = this.canCreateContextualEntity.bind(this);
    this.newContextualEntity = this.newContextualEntity.bind(this);
    this.renderContextualEntityReferences = this.renderContextualEntityReferences.bind(this);
    this.renderContextualEntity = this.renderContextualEntity.bind(this);

    this.state = { createdEntity: null };
  }

  componentDidUpdate() {
    const { childEntityId } = this.props;
    const { createdEntityReferenceId } = this.parseCreatedEntity();

    if (createdEntityReferenceId && childEntityId === createdEntityReferenceId) {
      // Once we've successfully redirected, clear the createdEntity
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ createdEntity: null });
    }
  }

  getContextualEntityReferences(e) {
    const { entity, currentEntities, dataSets, dataSetResponses, getEntityType } = this.props;

    const entityTypeClass = getEntityType(e.entity_type).entity_class;

    if (entityTypeClass === 'ec_data_set') {
      const dataSet = dataSets.filter((ds) => ds.response_subject_entity_type === entity.entity_type && ds.referenced_by === e.id).first();

      return dataSet && dataSetResponses.filter((dsr) => dsr.data_set === dataSet.id)
        .map((dsr) => currentEntities.filter((currentEntity) => currentEntity.id === dsr.referenced_by).first());
    }

    return null;
  }

  parseCreatedEntity() {
    const { getEntityTypeByEntityClass } = this.props;
    const { createdEntity } = this.state;

    return {
      createdEntityType: createdEntity ? getEntityTypeByEntityClass(createdEntity.entity_class) : null,
      createdEntityReferenceId: createdEntity ? createdEntity.response.entities[createdEntity.key][createdEntity.response.result].referenced_by : null,
    };
  }

  canCreateContextualEntity(e) {
    const { entity, getEntityType, dataSets, userEntitiesManager } = this.props;

    const contextualEntityType = getEntityType(e.entity_type);

    if (contextualEntityType.entity_class === 'ec_data_set') {
      const dataSet = dataSets.filter((ds) => ds.referenced_by === e.id).first();

      return Record.isRecord(dataSet) && userEntitiesManager &&
        userEntitiesManager.filter((userEntity) => dataSet.response_owner_entity_type === userEntity.get('entity_type')).size > 0 &&
        dataSet.response_subject_entity_type === entity.entity_type;
    }

    return false;
  }

  newContextualEntity(e) {
    const { user, entity, getEntityType, dataSets, userEntitiesManager, createDataSetResponse } = this.props;

    const contextualEntityType = getEntityType(e.entity_type);

    if (contextualEntityType.entity_class === 'ec_data_set') {
      const dataSet = dataSets.filter((ds) => ds.referenced_by === e.id).first();

      if (Record.isRecord(dataSet)) {
        // TODO: this needs to be a modal if there's more than one owner entity candidate
        const ownerEntity = userEntitiesManager &&
              userEntitiesManager.filter((userEntity) => dataSet.response_owner_entity_type === userEntity.get('entity_type')).first();

        if (ownerEntity) {
          createDataSetResponse(user.authToken, dataSet.id, { value: entity.id }, { value: ownerEntity.id }, 0)
            .then((response) => this.setState({ createdEntity: { entity_class: 'ec_data_set_response', key: 'data_set_responses', response } }));
        }
      }
    }
  }

  renderContextualEntityReferences(e, referencedEntities) {
    const { entity, entityType, getEntityType } = this.props;

    const entityTypeClass = getEntityType(e.entity_type).entity_class;

    if (entityTypeClass === 'ec_data_set') {
      return referencedEntities.map((referencedEntity) => (
        <div style={{ paddingBottom: 10 }} key={referencedEntity}>
          <i className={`fa fa-${getEntityType(referencedEntity.entity_type).icon}`} />
          <StyledLink
            treatment="link"
            to={entityType.absoluteUrl(entity.id) + getEntityType(referencedEntity.entity_type).absoluteUrl(referencedEntity.id)}
            title={moment(referencedEntity.created_at).format('YYYY-MM-DD')}
          >
            {e.name}
          </StyledLink>
        </div>
      ));
    }

    return null;
  }

  renderContextualEntity(e) {
    const { collapsed, toggleSidebar } = this.props;

    const canCreateContextualEntity = this.canCreateContextualEntity(e);
    const referencedEntities = this.getContextualEntityReferences(e);

    return (canCreateContextualEntity || (referencedEntities && referencedEntities.size > 0)) && (
      <CollapseableSection collapsed={collapsed} toggleSidebar={toggleSidebar} title={e.name} key={e}>
        <ReferencedEntities>
          {referencedEntities && this.renderContextualEntityReferences(e, referencedEntities)}
        </ReferencedEntities>

        {canCreateContextualEntity && (
          <div>
            <Button treatment="button" primary="true" thin="true" onClick={() => this.newContextualEntity(e)}>Create</Button>
          </div>
        )}
      </CollapseableSection>
    );
  }

  render() {
    const { childEntityId, user, toggleSidebar, collapsed, entity, entityType, currentEntities, showPrimaryPanel,
      exportedEntitiesLoading, listExportedEntities } = this.props;

    const contextualEntities = currentEntities.filter((e) => e.visibility === 'v_contextual');

    const { createdEntityType, createdEntityReferenceId } = this.parseCreatedEntity();

    if (createdEntityType && childEntityId !== createdEntityReferenceId) {
      return <Redirect to={`${entityType.absoluteUrl(entity.id)}${createdEntityType.absoluteUrl(createdEntityReferenceId)}/edit`} />;
    }

    return showPrimaryPanel && (
      <SidebarStyles>
        <BackgroundContainer>
          <ContentContainer>
            {Record.isRecord(entity) && Record.isRecord(entityType) && (
              <Content>
                <Collapse onClick={toggleSidebar}>
                  <div className={collapsed ? 'sidebar-collapsed' : null}>
                    <span>
                      {!collapsed && <React.Fragment>&rarr;</React.Fragment>}
                      {collapsed && <React.Fragment>&larr;</React.Fragment>}
                    </span>
                  </div>
                </Collapse>

                <CollapseableSection collapsed={collapsed} toggleSidebar={toggleSidebar} title="Properties">
                  <div style={{ paddingBottom: 6 }}>
                    <i className={`fa fa-${entityType.icon}`} /> {entityType.name}
                  </div>
                  <div style={{ paddingBottom: 6 }}>
                    <i className={`fas fa-${entity.visibilityIcon()}`} /> {entity.visibilityName()}
                  </div>
                  <div style={{ paddingBottom: 6 }}>
                    <i className="fas fa-calendar-alt" /> {moment(entity.created_at).format('MMMM Do, YYYY')}
                  </div>
                  <div style={{ paddingBottom: 6 }}>
                    <i className="fas fa-pencil-alt" /> {moment(entity.updated_at).format('MMMM Do, YYYY')}
                  </div>
                </CollapseableSection>

                {contextualEntities.map((e) => this.renderContextualEntity(e))}

                {user && user.signedIn && entityType.entity_class === 'ec_data_set' && childEntityId !== 'new' && (
                  <CollapseableSection collapsed={collapsed} toggleSidebar={toggleSidebar} title="Export">
                    <Button
                      treatment="button"
                      primary="true"
                      thin="true"
                      disabled={exportedEntitiesLoading}
                      onClick={() => listExportedEntities(user.authToken, entity.get('id'), 'ec_data_set_response')}
                    >Export
                    </Button>
                  </CollapseableSection>
                )}
              </Content>
            )}
          </ContentContainer>
        </BackgroundContainer>
      </SidebarStyles>
    );
  }
}

export default SidebarView;
