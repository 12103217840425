import { connect } from 'react-redux';

import { Record } from 'immutable';

import RootView from './root_view';

import { getCurrentUser, getUserEntities, getUserDirectEntities, getEntityTypesLoadingStatus, permissionTypeMap,
  hasUserEntityPermissions } from '../../../reducer';
import { fetchUserEntity, fetchUserEntityAncestors, listEntityTypes, setUserEntitiesPermissions } from '../../actions';

import EntityModel from '../../models/entity_model';

const mapStateToProps = (state) => {
  const directEntities = getUserDirectEntities(state);

  return {
    user: getCurrentUser(state),
    userEntities: getUserEntities(state),
    entityTypesLoading: getEntityTypesLoadingStatus(state),
    hasUserEntityPermissions: (userEntity, permissionTypes) => hasUserEntityPermissions(state, userEntity, directEntities, permissionTypes),
  };
};

const mapDispatchToProps = {
  listEntityTypes,
  fetchUserEntity,
  fetchUserEntityAncestors,
  setUserEntitiesPermissions,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  doSetUserEntitiesPermissions: () => dispatchProps.setUserEntitiesPermissions(permissionTypeMap.map((val, _k) =>
    (stateProps.userEntities &&
     stateProps.userEntities.filter((userEntity) => stateProps.hasUserEntityPermissions(userEntity, val))
       .map((userEntity) => (Record.isRecord(userEntity) ? userEntity : new EntityModel(userEntity)))),
  )),
});

const RootContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(RootView);

export default RootContainer;
