import styled from 'styled-components';

import clickable, { conditionalStyles } from '../common/clickable';

const Button = styled.button`
  ${clickable}

  ${(props) => conditionalStyles(props)}
`;

export default Button;
