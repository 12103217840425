import actions from '../actions';

const DEFAULT_STATE = null;

function SelectedEntityTypeReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.SET_SELECTED_ENTITY_TYPE:
      return action.entityType;
    default:
      return state;
  }
}

export default SelectedEntityTypeReducer;
