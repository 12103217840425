import styled from 'styled-components';

const IntroductionStyles = styled.div`
  div.box {
    margin-top: 20px;

    h3 {
      margin-top: 0;
      text-align: center;
    }
  }
`;

export default IntroductionStyles;
