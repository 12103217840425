import { connect } from 'react-redux';

import NavigatorView from './navigator_view';

import { getCurrentUser, getUserEntity, getEntityType, getUserEntities, getUserDirectEntities, getCurrentEntity, getCurrentEntities,
  hasUserEntityPermissions, hasCurrentEntityPermissions, permissionTypeMap, getCurrentRelationships, getSelectedEntityPermissions,
  getSelectedChildEntityPermissions, getListEntity } from '../../../reducer';

import { setSelectedEntity, setSelectedEntityType, setSelectedEntityPermissions, setSelectedChildEntityPermissions, clearCurrentEntities,
  clearCurrentRelationships, fetchCurrentEntity, fetchCurrentEntityChildren, fetchCurrentEntityParents, fetchCurrentEntityReference,
  fetchCurrentEntitySubjectOf, fetchEntityTypeSubjectOf, clearListEntities, clearListRelationships, setEntityLoading } from '../../actions';

const mapStateToProps = (state, { match }) => {
  const userEntities = getUserEntities(state);
  const directEntities = getUserDirectEntities(state);
  const currentEntities = getCurrentEntities(state);
  const currentRelationships = getCurrentRelationships(state);
  const entityPermissions = getSelectedEntityPermissions(state);
  const childEntityPermissions = getSelectedChildEntityPermissions(state);

  return {
    user: getCurrentUser(state),
    userEntities,
    currentEntities,
    currentRelationships,
    entityPermissions,
    childEntityPermissions,
    getEntityType: (entityType) => getEntityType(state, entityType),
    getUserEntity: (id) => getUserEntity(state, id),
    getCurrentEntity: (id) => getCurrentEntity(state, id),
    getListEntity: (id) => getListEntity(state, id),
    hasUserEntityPermissions: (userEntity, permissionTypes) => hasUserEntityPermissions(state, userEntity, directEntities, permissionTypes),
    hasCurrentEntityPermissions: (currentEntity, entities, permissionTypes) => hasCurrentEntityPermissions(state, currentEntity, entities, permissionTypes),
    match,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setEntityLoading: (loading) => dispatch(setEntityLoading(loading)),
  setSelectedEntity: (entity) => dispatch(setSelectedEntity(entity)),
  setSelectedEntityType: (entityType) => dispatch(setSelectedEntityType(entityType)),
  setSelectedEntityPermissions: (permissions) => dispatch(setSelectedEntityPermissions(permissions)),
  setSelectedChildEntityPermissions: (permissions) => dispatch(setSelectedChildEntityPermissions(permissions)),
  clearCurrentEntities: () => dispatch(clearCurrentEntities()),
  clearCurrentRelationships: () => dispatch(clearCurrentRelationships()),
  fetchCurrentEntity: (token, id) => dispatch(fetchCurrentEntity(token, id)),
  fetchCurrentEntityParents: (token, id, entityTypeClass) => dispatch(fetchCurrentEntityParents(token, id, entityTypeClass)),
  fetchCurrentEntityChildren: (token, id) => dispatch(fetchCurrentEntityChildren(token, id)),
  fetchCurrentEntityReference: (token, id) => dispatch(fetchCurrentEntityReference(token, id)),
  fetchCurrentEntitySubjectOf: (token, id) => dispatch(fetchCurrentEntitySubjectOf(token, id)),
  fetchEntityTypeSubjectOf: (token, id) => dispatch(fetchEntityTypeSubjectOf(token, id)),
  clearListEntities: () => dispatch(clearListEntities()),
  clearListRelationships: () => dispatch(clearListRelationships()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doSetEntityLoading: (loading) => dispatchProps.setEntityLoading(loading),
  doSetSelectedEntity: (entity) => dispatchProps.setSelectedEntity(entity),
  doSetSelectedEntityType: (entityType) => dispatchProps.setSelectedEntityType(entityType),
  doSetSelectedEntityPermissions: (entity) => dispatchProps.setSelectedEntityPermissions(permissionTypeMap.map((val, _k) => {
    const userEntities = stateProps.userEntities && stateProps.userEntities.filter((userEntity) => stateProps.hasUserEntityPermissions(userEntity, val));
    return entity && userEntities && stateProps.hasCurrentEntityPermissions(entity, userEntities, val);
  })),
  doSetSelectedChildEntityPermissions: (childEntity) => dispatchProps.setSelectedChildEntityPermissions(permissionTypeMap.map((val, _k) => {
    const userEntities = stateProps.userEntities && stateProps.userEntities.filter((userEntity) => stateProps.hasUserEntityPermissions(userEntity, val));
    return childEntity && userEntities && stateProps.hasCurrentEntityPermissions(childEntity, userEntities, val);
  })),
  doClearCurrentEntities: () => dispatchProps.clearCurrentEntities(),
  doClearCurrentRelationships: () => dispatchProps.clearCurrentRelationships(),
  doFetchCurrentEntity: (id) => dispatchProps.fetchCurrentEntity(stateProps.user.authToken, id),
  doFetchCurrentEntityParents: (id, entityTypeClass) => dispatchProps.fetchCurrentEntityParents(stateProps.user.authToken, id, entityTypeClass),
  doFetchCurrentEntityChildren: (id) => dispatchProps.fetchCurrentEntityChildren(stateProps.user.authToken, id),
  doFetchCurrentEntityReference: (id) => dispatchProps.fetchCurrentEntityReference(stateProps.user.authToken, id),
  doFetchCurrentEntitySubjectOf: (id) => dispatchProps.fetchCurrentEntitySubjectOf(stateProps.user.authToken, id),
  doFetchEntityTypeSubjectOf: (id) => dispatchProps.fetchEntityTypeSubjectOf(stateProps.user.authToken, id),
  doClearListEntities: () => dispatchProps.clearListEntities(),
  doClearListRelationships: () => dispatchProps.clearListRelationships(),
});

const NavigatorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(NavigatorView);

export default NavigatorContainer;
