import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Map, OrderedSet, List, Record } from 'immutable';
import { reduxForm, Form } from 'redux-form/lib/immutable';

import SubjectOwnerModal from '../../subject_owner_modal';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';

import FoundationField from '../../foundation_field';

import DataSetResponseNewStyles, { Completed, CompletedInset } from './data_set_response_new_styles';

class DataSetResponseNewView extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.instanceOf(UserModel),
    entity: PropTypes.instanceOf(EntityModel),
    entityType: PropTypes.instanceOf(EntityTypeModel),
    userEntities: PropTypes.instanceOf(OrderedSet),
    userEntitiesManager: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    responseEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponseForm: PropTypes.instanceOf(Map),
    settings: PropTypes.instanceOf(Map),
    formSettings: PropTypes.instanceOf(Map),
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reduxFormReset: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    setOwner: PropTypes.func.isRequired,
    createDataSetResponse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: null,
    user: null,
    entity: null,
    entityType: null,
    userEntities: new OrderedSet(),
    userEntitiesManager: new OrderedSet(),
    responseEntityType: null,
    dataSet: null,
    dataSetResponseForm: new Map(),
    settings: new Map(),
    formSettings: new Map(),
  };

  constructor(props) {
    super(props);

    this.setSubjectOwner = this.setSubjectOwner.bind(this);
    this.createDataSetResponse = this.createDataSetResponse.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderField = this.renderField.bind(this);

    this.state = { modalCancelled: false, submitCompleted: false, feedbackCompleted: false, dataSetResponseEntityId: null };
  }

  componentWillUnmount() {
    const { setSubject, setOwner } = this.props;

    if (setSubject) setSubject(null);
    if (setOwner) setOwner(null);
  }

  setSubjectOwner(subject, owner) {
    const { setSubject, setOwner } = this.props;

    if (setSubject) setSubject(subject);
    if (setOwner) setOwner(owner);
  }

  createDataSetResponse() {
    const { user, dataSet, settings, formSettings, dataSetResponseForm, createDataSetResponse } = this.props;

    createDataSetResponse(user.authToken, dataSet.id, settings.get('subject'), settings.get('owner'), formSettings.get('status'),
      dataSetResponseForm.get('values').get('fields'))
      .then((response) => {
        this.setState({ submitCompleted: true, dataSetResponseEntityId: response.entities.data_set_responses[response.result].referenced_by });
        window.setTimeout(() => this.setState({ feedbackCompleted: true }), 2500);
      });
  }

  closeModal() {
    this.setState({ modalCancelled: true });
  }

  renderField(field) {
    const { dataSetResponseForm } = this.props;

    const valuesByField = dataSetResponseForm.get('values') && dataSetResponseForm.get('values').get('fields');
    return DataSetModel.displayConditionalField(field, valuesByField) && <FoundationField key={field} field={field} />;
  }

  render() {
    const { responseEntityType, entity, entityType, user, userEntities, userEntitiesManager, dataSet,
      settings, form, handleSubmit, reduxFormReset } = this.props;
    const { modalCancelled, submitCompleted, feedbackCompleted, dataSetResponseEntityId } = this.state;

    if (!Record.isRecord(entity) || !Record.isRecord(entityType) || !Record.isRecord(dataSet)) return null;

    if (submitCompleted && feedbackCompleted) {
      reduxFormReset(form);

      if (entity.redirect_id) {
        return <Redirect to={`/entities/${entity.redirect_id}`} />;
      }

      if (user && user.signedIn) {
        return <Redirect to={responseEntityType.absoluteUrl(dataSetResponseEntityId)} />;
      }

      return <Redirect to={entityType.absoluteUrl(entity.id)} />;
    }

    const potentialSubjects = dataSet.filterEntities(userEntities, 'response_subject_entity_type');
    const potentialOwners = dataSet.filterEntities(userEntitiesManager, 'response_owner_entity_type');
    const showSubjectOwnerModal = (potentialSubjects.size > 1 && !settings.get('subject')) || (potentialOwners.size > 1 && !settings.get('owner'));

    if (showSubjectOwnerModal && modalCancelled) return <Redirect to={entityType.absoluteUrl(entity.id)} />;

    return (
      <DataSetResponseNewStyles>
        <Form onSubmit={handleSubmit(this.createDataSetResponse)}>
          <div className="row">
            {dataSet.get('fields').sortBy((field) => field.get('sort_order')).map((field) => this.renderField(field))}
          </div>
        </Form>
        {showSubjectOwnerModal && (
          <SubjectOwnerModal
            entity={entity}
            isOpen
            potentialSubjects={potentialSubjects}
            potentialOwners={potentialOwners}
            submitCallback={this.setSubjectOwner}
            closeCallback={this.closeModal}
          />
        )}
        {submitCompleted && (
          <Completed>
            <CompletedInset>
              <i className="far fa-check-circle fa-5x" />
              <br />
              <span>Success!</span>
            </CompletedInset>
          </Completed>
        )}
      </DataSetResponseNewStyles>
    );
  }
}

export default reduxForm({ warn: DataSetModel.reduxFormValidate, enableReinitialize: true })(DataSetResponseNewView);
