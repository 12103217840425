import styled from 'styled-components';

const CollapseableSectionStyles = styled.div`
  margin: 10px;
  padding: 10px 0 20px;
  border-bottom: 1px solid ${(props) => props.theme.foundation.sidebar.borderColor};

  :last-of-type {
    border-bottom: 0 none;
  }

  text-align: left;

  .body-expanded {
    display: block;
  }
`;

export const Title = styled.div`
  font-size: 0.8em;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${(props) => props.theme.foundation.sidebar.textColorLight}
  cursor: pointer;

  i {
    width: 1.2em;
  }
`;

export const Body = styled.div`
  display: none;
  padding: 10px 10px 0;
  color: ${(props) => props.theme.foundation.sidebar.textColor}

  i {
    width: 1.2em;
  }
`;

export default CollapseableSectionStyles;
