import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { SelectWrapper } from '../../styles/form';

const propTypes = {
  input: PropTypes.shape({ name: PropTypes.string, value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), onChange: PropTypes.func }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })).isRequired,
  isMulti: PropTypes.bool,
  manageValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.string, warning: PropTypes.bool, initial: PropTypes.list }),
  children: PropTypes.node,
};

const defaultProps = {
  isMulti: false,
  manageValue: false,
  placeholder: 'Select...',
  required: false,
  meta: {},
  children: null,
};

const customStyles = {
  container: (provided, _state) => ({
    ...provided,
    marginTop: 9,
  }),
};

const ReactSelect = ({ children, input, options, label, placeholder, required, isMulti, manageValue, meta: { touched, error, warning, initial } }) => (
  <SelectWrapper>
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor={input.name}>
      {label} {required ? '*' : ''}
      {manageValue && (
        <Select
          value={input.value}
          styles={customStyles}
          onChange={input.onChange}
          isMulti={isMulti}
          options={options}
          placeholder={placeholder}
          className={touched && error ? 'field-error' : ''}
          defaultValue={initial && initial.toJS()}
        />
      )}

      {!manageValue && (
        <Select
          styles={customStyles}
          onChange={input.onChange}
          isMulti={isMulti}
          options={options}
          placeholder={placeholder}
          className={touched && error ? 'field-error' : ''}
          defaultValue={initial && initial.toJS()}
        />
      )}
      {children}
      {touched && ((error && <span className="error select-error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
    </label>
  </SelectWrapper>
);

ReactSelect.propTypes = propTypes;
ReactSelect.defaultProps = defaultProps;

export default ReactSelect;
