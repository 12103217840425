import { connect } from 'react-redux';

import { getCurrentUser, getEntityTypesById } from '../../../../reducer';

import { listCurrentEntities } from '../../../actions';

import DirectoryShowView from './directory_show_view';

const mapStateToProps = (state, { entityTypeSlug }) => ({
  user: getCurrentUser(state),
  entityTypes: getEntityTypesById(state),
  entityTypeSlug,
});

const mapDispatchToProps = {
  listCurrentEntities,
};

const DirectoryShowContainer = connect(mapStateToProps, mapDispatchToProps)(DirectoryShowView);

export default DirectoryShowContainer;
