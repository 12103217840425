import { connect } from 'react-redux';
import { submit } from 'redux-form';

import DataSetResponseStatusView from './data_set_response_status_view';

import { getCurrentUser, getForm, getEntityType, getDataSets, getDataSetResponses } from '../../../../reducer';

import { saveWithStatus } from '../../../actions';

const mapStateToProps = (state, { entity, entityType }) => {
  let dataSetResponse = null;
  let dataSet = null;
  let form = null;

  if (entityType.entity_class === 'ec_data_set_response') {
    dataSetResponse = entity && getDataSetResponses(state).filter((dsr) => dsr.referenced_by === entity.id).first();
    dataSet = dataSetResponse && getDataSets(state).filter((ds) => ds.id === dataSetResponse.data_set).first();
    form = entity && `entity:${entity.id}:edit`;
  } else if (entityType.entity_class === 'ec_data_set') {
    dataSet = getDataSets(state).filter((ds) => ds.referenced_by === entity.id).first();
    form = dataSet && `entity:${entity.id}:responses:new`;
  }

  const formData = entity && getForm(state).get(form);

  return {
    user: getCurrentUser(state),
    dataSet,
    dataSetResponse,
    responseEntityType: dataSet && getEntityType(state, dataSet.response_entity_type),
    form,
    formData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitForm: (form, status) => {
    dispatch(saveWithStatus(status));
    window.setTimeout(() => dispatch(submit(form)), 250);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doSubmitForm: (status) => dispatchProps.submitForm(stateProps.form, status),
});

const DataSetResponseStatusContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DataSetResponseStatusView);

export default DataSetResponseStatusContainer;
