import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet, Record } from 'immutable';

import StyledLink from '../../styled_link';
import Button from '../../button';

import UserModel from '../../../../session/models/user_model';

import DirectoryShowStyles, { CtaBlock } from './directory_show_styles';

class DirectoryShowView extends React.Component {
  static PAGE_SIZE = 12;

  static propTypes = {
    user: PropTypes.instanceOf(UserModel),
    currentEntities: PropTypes.instanceOf(OrderedSet),
    entityTypes: PropTypes.instanceOf(Map),
    entityTypeSlug: PropTypes.string,
    listCurrentEntities: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: null,
    currentEntities: new OrderedSet(),
    entityTypes: new Map(),
    entityTypeSlug: null,
  }

  constructor(props) {
    super(props);

    this.loadMore = this.loadMore.bind(this);

    this.state = { showLoadMore: true, nextPage: 1 };
  }

  componentDidMount() {
    this.loadMore();
  }

  loadMore() {
    const { user, entityTypeSlug, listCurrentEntities } = this.props;
    const { nextPage } = this.state;

    if (listCurrentEntities) {
      listCurrentEntities(user && user.authToken, entityTypeSlug, nextPage, DirectoryShowView.PAGE_SIZE).then((response) => {
        if (response.result.length < DirectoryShowView.PAGE_SIZE) this.setState({ showLoadMore: false });
      });
    }
  }

  renderBlock(e) {
    const { entityTypes, entityTypeSlug } = this.props;
    const entityType = entityTypes.get(e.entity_type);

    return Record.isRecord(entityType) && entityType.slug === entityTypeSlug && (
      <div className="col-md-3 col-sm-6 col-xs-12" key={e}>
        <CtaBlock>
          <div className="icon">
            <StyledLink treatment="link" to={entityType.absoluteUrl(e.id)}>
              <span><i className={`fa fa-2x fa-${entityType.icon}`} /></span>
            </StyledLink>
          </div>
          <div className="name-description">
            <StyledLink treatment="link" to={entityType.absoluteUrl(e.id)}>
              {e.name}
            </StyledLink>
          </div>
        </CtaBlock>
      </div>
    );
  }

  render() {
    const { currentEntities } = this.props;
    const { showLoadMore, nextPage } = this.state;

    return (
      <DirectoryShowStyles className="row">
        {currentEntities.filter((e) => Record.isRecord(e)).sortBy((e) => e.created_at).reverse().map((e) => this.renderBlock(e))}

        {showLoadMore && (
          <div className="col-xs-12 load-more">
            <Button secondary="true" treatment="button" onClick={() => this.setState({ nextPage: nextPage + 1 }, this.loadMore)}>Load More</Button>
          </div>
        )}
      </DirectoryShowStyles>
    );
  }
}

export default DirectoryShowView;
