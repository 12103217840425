import styled from 'styled-components';

const AddToModalStyles = styled.div`
  .modal-body .addTo-option {
    border: 1px solid ${(props) => props.theme.foundation.modal.borderColor};
    border-radius: 4px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;

    .header {
      font-weight: bold;
    }

    .description {
      margin-top: 4px;
      font-size: 0.8em;
      color: ${(props) => props.theme.foundation.modal.textColorLight};
    }

    i {
      margin: 0 10px;
    }
  }
`;

export default AddToModalStyles;
