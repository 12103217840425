import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { OrderedSet, Map } from 'immutable';
import { combineImmutableReducers } from './util';
import SessionReducer, * as SessionSelect from './session/reducer';
import ExampleAppReducer, * as ExampleAppSelectors from './example_app/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
// import { ContentReducer, ContentSelectors } from './vendor/flamelink';
import FoundationReducer, * as FoundationSelectors from './foundation/reducer';

const Reducer = (history) =>
  combineImmutableReducers({
    router: connectRouter(history),
    //    content: ContentReducer,
    form: FormReducer,
    session: SessionReducer,
    exampleApp: ExampleAppReducer,
    globalSettings: GlobalSettingsReducer,
    hubSpotForm: HubSpotFormReducer,
    foundation: FoundationReducer,
  });

export default Reducer;

// Flamelink Content
// export const getPages = (state) => ContentSelectors.getPages(state.get('content'));
// export const getPageByTitle = (state, title) => ContentSelectors.getPageByTitle(state.get('content'), title);
// export const getSnippets = (state) => ContentSelectors.getSnippets(state.get('content'));
// export const getSnippetByTitle = (state, title) => ContentSelectors.getSnippetByTitle(state.get('content'), title);

export const getRouter = (state) => state.get('router');

export const getForm = (state) => state.get('form');

// HubSpot Selectors
export const getHubSpotContactFormStatus = (state) => getContactFormStatus(state.get('hubSpotForm'));

export const getExampleAppFormStatus = (state) => ExampleAppSelectors.getFormStatus(state.get('exampleApp'));
export const getWidgets = (state) => ExampleAppSelectors.getWidgets(state.get('exampleApp'));
export const hasGlobalSetting = (state, key) => GlobalSettingsSelectors.hasSetting(state.get('globalSettings'), key);
export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.get('globalSettings'), key, notSetValue);
export const getSessionStatus = (state) => SessionSelect.getStatus(state.get('session'));
export const getFlashList = (state) => SessionSelect.getFlashList(state.get('session'));
export const getCurrentUser = (state) => SessionSelect.getCurrentUser(state.get('session'));
export const getTreatment = (state, experiemnt, defaultTreatment) => SessionSelect.getTreatment(state.get('session'), experiemnt, defaultTreatment);

export const administratorPermissionTypes = new OrderedSet(['administrator']);
export const managerPermissionTypes = new OrderedSet(['manager', 'administrator']);
export const editorPermissionTypes = new OrderedSet(['editor', 'manager', 'administrator']);
export const viewerPermissionTypes = new OrderedSet(['viewer', 'editor', 'manager', 'administrator']);
export const memberPermissionTypes = new OrderedSet(['member', 'viewer', 'editor', 'manager', 'administrator']);
export const associatedPermissionTypes = new OrderedSet(['associated', 'member', 'viewer', 'editor', 'manager', 'administrator']);

export const permissionTypeMap = new Map({
  administrator: administratorPermissionTypes,
  manager: managerPermissionTypes,
  editor: editorPermissionTypes,
  viewer: viewerPermissionTypes,
  member: memberPermissionTypes,
  associated: associatedPermissionTypes,
});

export const getSettings = (state) => FoundationSelectors.getSettings(state.get('foundation'));
export const getFormSettings = (state) => FoundationSelectors.getFormSettings(state.get('foundation'));

export const getInvitation = (state, id) => FoundationSelectors.getInvitation(state.get('foundation'), id);
export const getInvitations = (state) => FoundationSelectors.getInvitations(state.get('foundation'));

// Current Entity + Relationship Selectors

export const getCurrentEntity = (state, id) => FoundationSelectors.getCurrentEntity(state.get('foundation'), id);
export const getCurrentEntities = (state) => FoundationSelectors.getCurrentEntities(state.get('foundation'));
export const getCurrentEntitiesLoadingStatus = (state) => FoundationSelectors.getCurrentEntitiesLoadingStatus(state.get('foundation'));

export const getUserEntitiesPermissions = (state) => FoundationSelectors.getUserEntitiesPermissions(state.get('foundation'));

export const getCurrentRelationships = (state, permissionTypes = new OrderedSet()) => FoundationSelectors.getCurrentRelationships(state.get('foundation'))
  .filter((r) => permissionTypes.size === 0 || permissionTypes.includes(r.get('permission_type')));

export const getCurrentParentRelationships = (state, childEntities, permissionTypes = new OrderedSet()) => {
  const childEntitiesIds = childEntities.map((e) => e && e.get('id'));
  return getCurrentRelationships(state, permissionTypes).filter((r) => childEntitiesIds.includes(r.get('child')));
};

export const getCurrentChildRelationships = (state, parentEntities, permissionTypes = new OrderedSet()) => {
  const parentEntitiesIds = parentEntities.map((e) => e && e.get('id'));
  return getCurrentRelationships(state, permissionTypes).filter((r) => parentEntitiesIds.includes(r.get('parent')));
};

export const getCurrentDirectRelationshipsBetween = (state, parent, child, permissionTypes = new OrderedSet()) =>
  getCurrentRelationships(state, permissionTypes).filter((r) => r.get('parent') === parent.get('id') && r.get('child') === child.get('id'));

export const getCurrentParentEntities = (state, childEntities, permissionTypes = new OrderedSet()) =>
  getCurrentParentRelationships(state, childEntities, permissionTypes).map((r) => getCurrentEntity(state, r.get('parent')));

export const getCurrentChildEntities = (state, parentEntities, permissionTypes = new OrderedSet()) =>
  getCurrentChildRelationships(state, parentEntities, permissionTypes).map((r) => getCurrentEntity(state, r.get('child')));

export const getCurrentSubjectEntities = (state, subjectEntities) => getCurrentEntities(state)
  .filter((e) => subjectEntities.map((se) => se && se.get('id')).includes(e.get('subject_id')));

export const hasCurrentEntityPermissions = (state, target, entities, permissionTypes, depth = 0) => {
  const entityIds = entities.map((e) => e && e.get('id'));
  if (entityIds.includes(target.get('id'))) return true;

  const parentRelationships = getCurrentParentRelationships(state, entities, permissionTypes);
  if (!parentRelationships.size) return false;

  const parentEntities = getCurrentParentEntities(state, entities, permissionTypes)
    .filter((parentEntity) => !parentEntity || !entityIds.includes(parentEntity.get('id')));
  return hasCurrentEntityPermissions(state, target, parentEntities, permissionTypes, depth + 1);
};

// User Entity + User Relationship Selectors

export const getUserEntity = (state, id) => FoundationSelectors.getUserEntity(state.get('foundation'), id);
export const getUserEntities = (state) => FoundationSelectors.getUserEntities(state.get('foundation'));
export const getUserEntitiesLoadingStatus = (state) => FoundationSelectors.getUserEntitiesLoadingStatus(state.get('foundation'));

export const getUserDirectEntities = (state) => {
  const user = getCurrentUser(state);
  if (!user || !user.get('entityUids')) return new OrderedSet();

  return new OrderedSet(user.get('entityUids')).map((id) => getUserEntity(state, id)).filter((entity) => entity);
};

export const getUserRelationships = (state, permissionTypes = new OrderedSet()) => FoundationSelectors.getUserRelationships(state.get('foundation'))
  .filter((r) => permissionTypes.size === 0 || permissionTypes.includes(r.get('permission_type')));

export const getUserParentRelationships = (state, childEntities, permissionTypes = new OrderedSet()) => getUserRelationships(state, permissionTypes)
  .filter((r) => childEntities.map((e) => e && e.get('id')).includes(r.get('child')));

export const getUserChildRelationships = (state, parentEntities, permissionTypes = new OrderedSet()) => getUserRelationships(state, permissionTypes)
  .filter((r) => parentEntities.map((e) => e && e.get('id')).includes(r.get('parent')));

export const getUserDirectRelationshipsBetween = (state, parent, child, permissionTypes = new OrderedSet()) => getUserRelationships(state, permissionTypes)
  .filter((r) => r.get('parent') === parent.get('id') && r.get('child') === child.get('id'));

export const getUserParentEntities = (state, childEntities, permissionTypes = new OrderedSet()) =>
  getUserParentRelationships(state, childEntities, permissionTypes).map((r) => getUserEntity(state, r.get('parent')));

export const getUserChildEntities = (state, parentEntities, permissionTypes = new OrderedSet()) =>
  getUserChildRelationships(state, parentEntities, permissionTypes).map((r) => getUserEntity(state, r.get('child')));

export const getUserSubjectEntities = (state, subjectEntities) => getUserEntities(state)
  .filter((e) => subjectEntities.map((se) => se && se.get('id')).includes(e.get('subject_id')));

export const hasUserEntityPermissions = (state, target, entities, permissionTypes, depth = 0) => {
  const entityIds = entities.map((e) => e && e.get('id'));
  if (entityIds.includes(target.get('id'))) return true;

  const parentRelationships = getUserParentRelationships(state, entities, permissionTypes);
  if (!parentRelationships.size) return false;

  const parentEntities = getUserParentEntities(state, entities, permissionTypes)
    .filter((parentEntity) => !parentEntity || !entityIds.includes(parentEntity.get('id')));
  return hasUserEntityPermissions(state, target, parentEntities, permissionTypes, depth + 1);
};

// List Entity + List Relationship Selectors

export const getListEntity = (state, id) => FoundationSelectors.getListEntity(state.get('foundation'), id);
export const getListEntities = (state) => FoundationSelectors.getListEntities(state.get('foundation'));
export const getListEntitiesLoadingStatus = (state) => FoundationSelectors.getListEntitiesLoadingStatus(state.get('foundation'));
export const getListEntitiesCount = (state) => FoundationSelectors.getListEntitiesCount(state.get('foundation'));

export const getListDirectEntities = (state) => {
  const user = getCurrentUser(state);
  if (!user || !user.get('entityUids')) return new OrderedSet();

  return new OrderedSet(user.get('entityUids')).map((id) => getListEntity(state, id)).filter((entity) => entity);
};

export const getListRelationships = (state, permissionTypes = new OrderedSet()) => FoundationSelectors.getListRelationships(state.get('foundation'))
  .filter((r) => permissionTypes.size === 0 || permissionTypes.includes(r.get('permission_type')));

export const getListParentRelationships = (state, childEntities, permissionTypes = new OrderedSet()) => getListRelationships(state, permissionTypes)
  .filter((r) => childEntities.map((e) => e && e.get('id')).includes(r.get('child')));

export const getListChildRelationships = (state, parentEntities, permissionTypes = new OrderedSet()) => getListRelationships(state, permissionTypes)
  .filter((r) => parentEntities.map((e) => e && e.get('id')).includes(r.get('parent')));

export const getListDirectRelationshipsBetween = (state, parent, child, permissionTypes = new OrderedSet()) => getListRelationships(state, permissionTypes)
  .filter((r) => r.get('parent') === parent.get('id') && r.get('child') === child.get('id'));

export const getListParentEntities = (state, childEntities, permissionTypes = new OrderedSet()) =>
  getListParentRelationships(state, childEntities, permissionTypes).map((r) => getListEntity(state, r.get('parent')));

export const getListChildEntities = (state, parentEntities, permissionTypes = new OrderedSet()) =>
  getListChildRelationships(state, parentEntities, permissionTypes).map((r) => getListEntity(state, r.get('child')));

export const getListSubjectEntities = (state, subjectEntities) => getListEntities(state)
  .filter((e) => subjectEntities.map((se) => se && se.get('id')).includes(e.get('subject_id')));

export const hasListEntityPermissions = (state, target, entities, permissionTypes, depth = 0) => {
  const entityIds = entities.map((e) => e && e.get('id'));
  if (entityIds.includes(target.get('id'))) return true;

  const parentRelationships = getListParentRelationships(state, entities, permissionTypes);
  if (!parentRelationships.size) return false;

  const parentEntities = getListParentEntities(state, entities, permissionTypes)
    .filter((parentEntity) => !parentEntity || !entityIds.includes(parentEntity.get('id')));
  return hasListEntityPermissions(state, target, parentEntities, permissionTypes, depth + 1);
};

// Selected Entity Selectors

export const getSelectedEntity = (state) => FoundationSelectors.getSelectedEntity(state.get('foundation'));
export const getSelectedEntityType = (state) => FoundationSelectors.getSelectedEntityType(state.get('foundation'));
export const getSelectedEntityPermissions = (state) => FoundationSelectors.getSelectedEntityPermissions(state.get('foundation'));
export const getSelectedChildEntityPermissions = (state) => FoundationSelectors.getSelectedChildEntityPermissions(state.get('foundation'));

// Other Entity Selectors

export const getExportedEntitiesLoadingStatus = (state) => FoundationSelectors.getExportedEntitiesLoadingStatus(state.get('foundation'));

// Entity Type Selectors

export const getEntityType = (state, id) => FoundationSelectors.getEntityType(state.get('foundation'), id);
export const getEntityTypes = (state) => FoundationSelectors.getEntityTypes(state.get('foundation'));
export const getEntityTypesById = (state) => getEntityTypes(state).toMap().mapEntries(([_k, v]) => [v.id, v]);
export const getEntityTypeBySlug = (state, slug) => getEntityTypes(state).filter((t) => t && t.slug === slug).first();
export const getEntityTypeByEntityClass = (state, entityTypeClass) => getEntityTypes(state).filter((t) => t && t.entity_class === entityTypeClass).first();

export const getEntityTypesLoadingStatus = (state) => FoundationSelectors.getEntityTypesLoadingStatus(state.get('foundation'));

// Page Selectors

export const getPage = (state, id) => FoundationSelectors.getPage(state.get('foundation'), id);
export const getPages = (state) => FoundationSelectors.getPages(state.get('foundation'));

// Event Selectors

export const getEvent = (state, id) => FoundationSelectors.getEvent(state.get('foundation'), id);
export const getEvents = (state) => FoundationSelectors.getEvents(state.get('foundation'));

// Data Set Selectors

export const getDataSet = (state, id) => FoundationSelectors.getDataSet(state.get('foundation'), id);
export const getDataSets = (state) => FoundationSelectors.getDataSets(state.get('foundation'));
export const getDataSetsLoadingStatus = (state) => FoundationSelectors.getDataSetsLoadingStatus(state.get('foundation'));

// Data Set Response Selectors

export const getDataSetResponse = (state, id) => FoundationSelectors.getDataSetResponse(state.get('foundation'), id);
export const getDataSetResponses = (state) => FoundationSelectors.getDataSetResponses(state.get('foundation'));
