import React from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';

import Button from '../../../button';

import EntityModel from '../../../../models/entity_model';
import EntityTypeModel from '../../../../models/entity_type_model';

import PageMetaStyles from './page_meta_styles';

class PageMetaView extends React.Component {
  static propTypes = {
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    canEditEntity: PropTypes.bool,
    doUpdateCurrentEntity: PropTypes.func.isRequired,
  }

  static defaultProps = {
    canEditEntity: false,
  }

  constructor(props) {
    super(props);

    this.onEntityNameKeyUp = this.onEntityNameKeyUp.bind(this);
    this.renderEntityName = this.renderEntityName.bind(this);

    this.state = { editingEntityName: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editingEntityName } = this.state;
    const prevEditingEntityName = prevState.editingEntityName;

    if (editingEntityName && !prevEditingEntityName && this.nameInputRef) this.nameInputRef.focus();
  }

  onEntityNameKeyUp(event) {
    const { entity, doUpdateCurrentEntity } = this.props;

    if (event.key === 'Escape') {
      this.setState({ editingEntityName: false });
    } else if (event.key === 'Enter') {
      this.setState({ editingEntityName: false });
      doUpdateCurrentEntity(entity.id, this.nameInputRef.value);
    }
  }

  renderEntityName() {
    const { entity, canEditEntity } = this.props;
    const { editingEntityName } = this.state;

    return (
      <div className={`entity-name ${canEditEntity ? 'entity-name-editable' : ''}`}>
        {!editingEntityName && (
          <Button
            custom
            onClick={() => canEditEntity && this.setState({ editingEntityName: true })}
            onKeyUp={(event) => event.key === 'Enter' && canEditEntity && this.setState({ editingEntityName: true })}
          >
            {entity.name}
            {canEditEntity && <i className="fas fa-pencil-alt" />}
          </Button>
        )}
        {editingEntityName && (
          <input
            ref={(ref) => { this.nameInputRef = ref; }}
            type="text"
            defaultValue={entity.name}
            onKeyUp={(event) => this.onEntityNameKeyUp(event)}
            onBlur={() => this.setState({ editingEntityName: false })}
          />
        )}
      </div>
    );
  }

  render() {
    const { entity, entityType } = this.props;

    return Record.isRecord(entity) && (
      <PageMetaStyles>
        <div className="row">
          <div className="col-xs-12 page-meta">
            <div className="entity-icon-container">
              <span className="entity-icon"><i className={`fa fa-4x fa-${entityType.icon}`} /></span>
            </div>
            <div className="entity-name-container">
              {this.renderEntityName()}
            </div>
          </div>
        </div>
      </PageMetaStyles>
    );
  }
}

export default PageMetaView;
