import { connect } from 'react-redux';

import EventShowView from './event_show_view';

import { getEvents } from '../../../../reducer';

const mapStateToProps = (state, { entity }) => ({
  event: getEvents(state).filter((p) => p.referenced_by === entity.id).first(),
});

const EventShowContainer = connect(mapStateToProps)(EventShowView);

export default EventShowContainer;
