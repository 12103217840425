import React from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';
import draftToHtml from 'draftjs-to-html';

import PageMeta from './page_meta';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import PageModel from '../../../models/page_model';

import PageShowStyles, { Profile, Body } from './page_show_styles';

const propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  canEditEntity: PropTypes.bool,
  page: PropTypes.instanceOf(PageModel),
};

const defaultProps = {
  canEditEntity: false,
  page: null,
};

const PageShowView = ({ page, entity, entityType, canEditEntity }) => Record.isRecord(page) && (
  <PageShowStyles>
    {canEditEntity && (
      <Profile>
        <PageMeta entity={entity} entityType={entityType} canEditEntity={canEditEntity} />
      </Profile>
    )}

    <Body>
      {page.content && ( // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: draftToHtml(page.content.toJS()) }} />
      )}
    </Body>
  </PageShowStyles>
);

PageShowView.propTypes = propTypes;
PageShowView.defaultProps = defaultProps;

export default PageShowView;
