import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Record, Map } from 'immutable';
import { reduxForm, Form, Field } from 'redux-form/lib/immutable';

import { EditorField, TextField, MuiCheckbox } from '../../../../common/form';

import UserModel from '../../../../session/models/user_model';
import EventModel from '../../../models/event_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';

import EventEditStyles from './event_edit_styles';

class EventEditView extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.instanceOf(UserModel),
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    event: PropTypes.instanceOf(EventModel),
    eventForm: PropTypes.instanceOf(Map),
    handleSubmit: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
    user: null,
    event: null,
    eventForm: new Map(),
  }

  constructor(props) {
    super(props);

    this.updateEvent = this.updateEvent.bind(this);

    this.state = { submitCompleted: false };
  }

  updateEvent() {
    const { user, id, eventForm, updateEvent } = this.props;

    if (user) {
      updateEvent(user.authToken, id, eventForm.get('values')).then(() => this.setState({ submitCompleted: true }));
    }
  }

  render() {
    const { entity, entityType, event, eventForm, handleSubmit } = this.props;
    const { submitCompleted } = this.state;

    if (submitCompleted) {
      return <Redirect to={entityType.absoluteUrl(entity.id)} />;
    }

    const allDayChecked = eventForm && eventForm.get('values') && eventForm.get('values').get('all_day');

    return Record.isRecord(event) && (
      <EventEditStyles>
        <Form onSubmit={handleSubmit(this.updateEvent)}>
          <div className="row">
            <div className="col-xs-12 event-date-fields">
              <TextField name="start_date" label="Start Date" type="date" required />
              {!allDayChecked && <TextField name="start_time" label="Start Time" type="time" required />}
              <span>to</span>
              <TextField name="end_date" label="End Date" type="date" required />
              {!allDayChecked && <TextField name="end_time" label="End Time" type="time" required />}
            </div>
            <div className="col-xs-12">
              <Field name="all_day" className="event-all-day-field" component={MuiCheckbox} /> All day
            </div>
            <div className="col-xs-12">
              <TextField name="location" label="Location" type="text" required />
            </div>
            <div className="col-xs-12">
              <span className="event-label">Description</span>
              <EditorField name="description" defaultValue={event.description && event.description.toJS()} />
            </div>
          </div>
        </Form>
      </EventEditStyles>
    );
  }
}

export default reduxForm({ validate: EventModel.validate, enableReinitialize: true })(EventEditView);
