import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Map, Record } from 'immutable';
import { reduxForm, Form } from 'redux-form/lib/immutable';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';
import DataSetResponseModel from '../../../models/data_set_response_model';

import FoundationField from '../../foundation_field';

import DataSetResponseEditStyles from './data_set_response_edit_styles';

class DataSetResponseEditView extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.instanceOf(UserModel),
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
    dataSetResponseForm: PropTypes.instanceOf(Map),
    formSettings: PropTypes.instanceOf(Map),
    handleSubmit: PropTypes.func.isRequired,
    fetchCurrentEntitySubject: PropTypes.func.isRequired,
    updateDataSetResponse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: null,
    user: null,
    entity: null,
    entityType: null,
    dataSet: null,
    dataSetResponse: null,
    dataSetResponseForm: new Map(),
    formSettings: new Map(),
  };

  constructor(props) {
    super(props);

    this.updateDataSetResponse = this.updateDataSetResponse.bind(this);
    this.renderField = this.renderField.bind(this);

    this.state = { submitCompleted: false };
  }

  componentDidMount() {
    const { user, entity, fetchCurrentEntitySubject } = this.props;

    fetchCurrentEntitySubject(user.authToken, entity.id);
  }

  updateDataSetResponse() {
    const { user, id, formSettings, dataSetResponseForm, updateDataSetResponse } = this.props;

    if (user) {
      updateDataSetResponse(user.authToken, id, formSettings.get('status'), dataSetResponseForm.get('values').get('fields'))
        .then(() => this.setState({ submitCompleted: true }));
    }
  }

  renderField(field) {
    const { dataSetResponseForm } = this.props;

    const valuesByField = dataSetResponseForm.get('values') && dataSetResponseForm.get('values').get('fields');
    return DataSetModel.displayConditionalField(field, valuesByField) && <FoundationField key={field} field={field} />;
  }

  render() {
    const { entity, entityType, dataSet, dataSetResponse, handleSubmit } = this.props;
    const { submitCompleted } = this.state;

    if (submitCompleted) {
      return <Redirect to={entityType.absoluteUrl(entity.id)} />;
    }

    return Record.isRecord(dataSet) && Record.isRecord(dataSetResponse) && (
      <DataSetResponseEditStyles>
        <Form onSubmit={handleSubmit(this.updateDataSetResponse)}>
          <div className="row">
            {dataSet.get('fields').sortBy((field) => field.get('sort_order')).map((field) => this.renderField(field))}
          </div>
        </Form>
      </DataSetResponseEditStyles>
    );
  }
}

export default reduxForm({ warn: DataSetModel.reduxFormValidate, enableReinitialize: true })(DataSetResponseEditView);
