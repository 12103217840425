import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, List, Map, Record } from 'immutable';
import { reduxForm } from 'redux-form/lib/immutable';

import { SelectField } from '../../../common/form';

import Modal from '../modal';
import Button from '../button';

import UserModel from '../../../session/models/user_model';
import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import AddToModalStyles from './add_to_modal_styles';

class AddToModalView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel).isRequired,
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]).isRequired,
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]).isRequired,
    availableEntities: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    addToForm: PropTypes.instanceOf(Map),
    createCurrentRelationship: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    availableEntities: new OrderedSet(),
    addToForm: new Map(),
    pristine: false,
    invalid: false,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { user, entity, addToForm, closeModal, createCurrentRelationship } = this.props;

    createCurrentRelationship(user.authToken, { permission_type: 'associated', parent: addToForm.get('values').get('parent').value, child: entity.id })
      .then(() => closeModal());
  }

  render() {
    const { entity, entityType, availableEntities, closeModal, handleSubmit, pristine, invalid, submitting } = this.props;

    const options = List(availableEntities.map((e) => ({ label: e.name, value: e.id }))); // eslint-disable-line new-cap

    return Record.isRecord(entity) && Record.isRecord(entityType) && (
      <Modal isOpen closeModal={closeModal} offset="20%">
        <AddToModalStyles>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="modal-header">
              Add To...
            </div>

            <div className="modal-body">
              {availableEntities.size > 0 && (
                <div className="col-xs-12">
                  <SelectField options={options} label={`Add ${entity.name} to...`} name="parent" required>
                    <div className="label-description">This is a private connection, {entity.name} will not be notified</div>
                  </SelectField>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <Button treatment="button" primary="true" disabled={pristine || invalid || submitting}>Add</Button>
            </div>
          </form>
        </AddToModalStyles>
      </Modal>
    );
  }
}


export default reduxForm({ enableReinitialize: true })(AddToModalView);
