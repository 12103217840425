import { connect } from 'react-redux';

import StatusView from './status_view';

const mapStateToProps = (state, { match }) => {
  const { entityAction, childEntityId } = match.params;

  return {
    entityAction,
    childEntityId,
  };
};

const StatusContainer = connect(mapStateToProps)(StatusView);

export default StatusContainer;
