import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet, List, Record, fromJS } from 'immutable';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import { debounce } from 'lodash';

import DataSetResponseStatus from '../data_set_response_status';
import EntitiesListStatus from './entities_list_status';
import EntitiesListFilter from './entities_list_filter';

import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';
import DataSetModel from '../../models/data_set_model';

import Button from '../button';
import StyledLink from '../styled_link';

import EntitiesListStyles, { SearchContainer, Table, IconLink, Name, Icon, Tag, EntityDate, ReferenceValue } from './entities_list_styles';

class EntitiesListView extends Component {
  static initialSortUpdated = [{ id: 'listEntity.updated_at', desc: true }];

  static initialSortCreated = [{ id: 'listEntity.created_at', desc: true }];

  static defaultPageSize = 20;

  static permissionNames = {
    administrator: 'Administrator',
    manager: 'Manager',
    editor: 'Editor',
    viewer: 'Member (Full)',
    member: 'Member (Limited)',
  };

  static propTypes = {
    entity: PropTypes.oneOfType([PropTypes.instanceOf(EntityModel), PropTypes.instanceOf(Map)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(EntityTypeModel), PropTypes.instanceOf(Map)]),
    entityReference: PropTypes.oneOfType([PropTypes.instanceOf(DataSetModel), PropTypes.instanceOf(Map)]),
    statusFormData: PropTypes.instanceOf(Map),
    filterFormData: PropTypes.instanceOf(Map),
    listEntities: PropTypes.instanceOf(OrderedSet),
    listEntitiesCount: PropTypes.number,
    listRelationships: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
    doFetchListEntityParents: PropTypes.func.isRequired,
    doFetchListEntityChildren: PropTypes.func.isRequired,
    doClearListEntities: PropTypes.func.isRequired,
    doClearListRelationships: PropTypes.func.isRequired,
    childEntityTypeClass: PropTypes.string,
    parentEntityTypeClass: PropTypes.string,
    getListEntity: PropTypes.func.isRequired,
    getEntityType: PropTypes.func.isRequired,
    hideHeader: PropTypes.bool,
    hideFields: PropTypes.arrayOf(PropTypes.string),
    initialSort: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool })),
    associated: PropTypes.bool,
    showPermissionTypes: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    entity: null,
    entityType: null,
    entityReference: null,
    statusFormData: fromJS({ values: { status_filters: [] } }),
    filterFormData: fromJS({ values: { advanced_filters: [] } }),
    listEntities: new OrderedSet(),
    listEntitiesCount: 0,
    listRelationships: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    childEntityTypeClass: null,
    parentEntityTypeClass: null,
    hideHeader: false,
    hideFields: [],
    initialSort: [],
    associated: true,
    showPermissionTypes: [],
  };

  static renderIconLink = (url, nameObject, entityType) => (
    <React.Fragment>
      <div className="col-xs-2" style={{ textAlign: 'center' }}>
        <StyledLink treatment="link" to={url}>
          <Icon><i className={`fa fa-${entityType.icon}`} /></Icon>
        </StyledLink>
      </div>
      <Name className="col-xs-10">
        <StyledLink treatment="link" to={url}>{nameObject.name}</StyledLink>
      </Name>
    </React.Fragment>
  );

  static renderCellName = ({ original }) => {
    const entity = new EntityModel(original.entity);
    const entityType = new EntityTypeModel(original.entityType);
    const { entityReference } = original;

    const listEntity = new EntityModel(original.listEntity);
    const listEntityType = new EntityTypeModel(original.listEntityType);

    const url = (entityType.has_secondary_children ? entityType.absoluteUrl(entity.id) : '') + listEntityType.absoluteUrl(listEntity.id);

    return (
      <IconLink className="row" key={url}>
        {EntitiesListView.renderIconLink(url, listEntity, listEntityType)}
        {entityType.entity_class === 'ec_data_set' && entityReference && entityReference.response_status && (
          <DataSetResponseStatus
            variant="entities-list"
            status={listEntity.reference_status}
            statusType={entityReference.response_status.type}
            statuses={List(entityReference.response_status.statuses)} // eslint-disable-line new-cap
          />
        )}
      </IconLink>
    );
  }

  static renderDate = ({ value }) => (
    <EntityDate>
      <span>{moment(value).format('MMM D, YYYY')}</span>
    </EntityDate>
  );

  static renderReferenceValue = (filter) => ({ original: { listEntity, entityReference } }) => {
    const fieldId = filter.get('field').value;
    const referenceDataItem = listEntity.reference_data && listEntity.reference_data.filter((item) => item.id === fieldId);
    const value = referenceDataItem && referenceDataItem.length && referenceDataItem[0].value;

    let result = null;

    if (!value || typeof value !== 'object') {
      result = value;
    } else if (entityReference.fields) {
      const referenceFields = entityReference.fields.filter((field) => field.id === fieldId);
      if (referenceFields.length && referenceFields[0].options) {
        const optionValues = value.filter((item) => item).map((item) => item.value);
        result = referenceFields[0].options.filter((option) => optionValues.includes(option.value)).map((option) => option.label).join(', ');
      }
    }

    return <ReferenceValue>{result}</ReferenceValue>;
  };

  constructor(props) {
    super(props);

    const { initialSort } = props;

    this.state = {
      tableLoading: false,
      pageSize: null,
      sorted: initialSort,
      query: null,
      advancedFilterVisible: false,
      listRelationshipMap: new Map(),
      currentRelationshipMap: new Map(),
    };

    this.tableRef = null;

    this.setPageSize = this.setPageSize.bind(this);
    this.setSorted = this.setSorted.bind(this);
    this.statusFilters = this.statusFilters.bind(this);
    this.fireFetchData = this.fireFetchData.bind(this);
    this.updateListRelationshipMap = this.updateListRelationshipMap.bind(this);
    this.updateCurrentRelationshipMap = this.updateCurrentRelationshipMap.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.filteredEntities = this.filteredEntities.bind(this);
    this.resolveData = this.resolveData.bind(this);
    this.tableEntities = this.tableEntities.bind(this);
    this.renderDataSetResponses = this.renderDataSetResponses.bind(this);
    this.renderTags = this.renderTags.bind(this);
    this.renderPermission = this.renderPermission.bind(this);
  }

  componentDidMount() {
    this.updateCurrentRelationshipMap();
  }

  componentDidUpdate(prevProps, prevState) {
    const { entity, listRelationships, currentRelationships } = this.props;
    const { query, advancedFilterVisible } = this.state;
    const prevListRelationships = prevProps.listRelationships;
    const prevCurrentRelationships = prevProps.currentRelationships;
    const prevEntity = prevProps.entity;
    const prevQuery = prevState.query;
    const prevAdvancedFilterVisible = prevState.advancedFilterVisible;

    const entityChanged = (entity && !prevEntity) || (Record.isRecord(entity) && Record.isRecord(prevEntity) && entity.id !== prevEntity.id);
    const listRelationshipsChanged = ((listRelationships.size > 0 || prevListRelationships.size > 0) &&
                                      listRelationships.hashCode() !== prevListRelationships.hashCode());
    const currentRelationshipsChanged = ((currentRelationships.size > 0 || prevCurrentRelationships.size > 0) &&
                                         currentRelationships.hashCode() !== prevCurrentRelationships.hashCode());
    const queryChanged = (query && !prevQuery) || (!query && prevQuery) || (query && prevQuery && query !== prevQuery);
    const advancedFilterVisibleChanged = (advancedFilterVisible !== prevAdvancedFilterVisible) && !advancedFilterVisible;

    if (entityChanged || queryChanged || advancedFilterVisibleChanged) this.fireFetchData();
    if (listRelationshipsChanged) this.updateListRelationshipMap();
    if (currentRelationshipsChanged) this.updateCurrentRelationshipMap();
  }

  setPageSize(pageSize) {
    this.setState({ pageSize });
  }

  setSorted(sorted) {
    this.setState({ sorted });
  }

  statusFilters() {
    const { statusFormData } = this.props;

    const values = statusFormData.get('values');
    return values && (List.isList(values.get('status_filters')) ? values.get('status_filters').toJS() : values.get('status_filters'));
  }

  fireFetchData() {
    if (!this.debouncedFireFetchData) this.debouncedFireFetchData = debounce(() => { if (this.tableRef) this.tableRef.fireFetchData(); }, 500);

    if (this.debouncedFireFetchData) this.debouncedFireFetchData();
  }

  updateListRelationshipMap() {
    const { listRelationships } = this.props;

    const mutableMap = {};

    listRelationships.forEach((v) => {
      if (!mutableMap[v.child]) { mutableMap[v.child] = { associated: [], nonAssociated: [] }; }
      mutableMap[v.child][v.get('permission_type') === 'associated' ? 'associated' : 'nonAssociated'].push(v);
    });

    this.setState({ listRelationshipMap: Map(mutableMap) }); // eslint-disable-line new-cap
  }

  updateCurrentRelationshipMap() {
    const { currentRelationships } = this.props;

    this.setState({ currentRelationshipMap: currentRelationships.toMap().mapEntries(([_k, v]) => [`${v.parent}:${v.child}`, v]) });
  }

  fetchData(state) {
    const { entity, childEntityTypeClass, parentEntityTypeClass, doClearListEntities, doClearListRelationships, doFetchListEntityParents,
      doFetchListEntityChildren, filterFormData, associated } = this.props;
    const { query } = this.state;

    this.setState({ tableLoading: true });

    doClearListEntities();
    doClearListRelationships();

    const sortSettings = state.sorted.map((item) => `${item.id}=${item.desc}`);
    // server expects 1-based page numbering but ReactTable uses 0-based page numbering
    doFetchListEntityChildren(entity.id, childEntityTypeClass, associated, state.page + 1, state.pageSize, sortSettings,
      query, this.statusFilters(), filterFormData.get('values').get('advanced_filters').toJS())
      .then((response) => {
        this.setState({ tableLoading: false });
        if (parentEntityTypeClass) doFetchListEntityParents(entity.id, parentEntityTypeClass, response.result);
      });
  }

  filteredEntities(data) {
    const { associated, showPermissionTypes } = this.props;
    const { listRelationshipMap } = this.state;

    return data.filter((listEntity) => {
      const relationships = listRelationshipMap.getIn([listEntity.id, (associated ? 'associated' : 'nonAssociated')]);
      return relationships && relationships.map((r) => r.permission_type).filter((type) => showPermissionTypes.includes(type)).length;
    });
  }

  resolveData(data) {
    const { entity, entityType, entityReference, getEntityType } = this.props;

    return this.filteredEntities(data).map((listEntity) => ({
      entity,
      entityType,
      entityReference,
      listEntity,
      listEntityType: getEntityType(listEntity.entity_type),
    })).toJS();
  }

  tableEntities() {
    const { entity, listEntities } = this.props;
    const { sorted } = this.state;

    return listEntities.filter((listEntity) => Record.isRecord(entity) && Record.isRecord(listEntity))
      .sort((a, b) => {
        for (let i = 0; i < sorted.length; i += 1) {
          if (!sorted || !sorted[i] || !sorted[i].id) return 0;

          const field = sorted[i].id.split('.')[1];
          if (a.get(field) > b.get(field)) return (sorted[i].desc ? -1 : 1);
          if (a.get(field) < b.get(field)) return (sorted[i].desc ? 1 : -1);
        }

        return 0;
      });
  }

  renderDataSetResponses({ original }) {
    const { getListEntity, getEntityType } = this.props;
    const { listRelationshipMap } = this.state;

    const relationships = [].concat(listRelationshipMap.getIn([original.listEntity.id, 'nonAssociated']),
      listRelationshipMap.getIn([original.listEntity.id, 'associated']));

    if (!relationships || relationships.length === 0) return null;

    return relationships.map((r) => {
      const listEntity = getListEntity(r.parent);
      const listEntityType = Record.isRecord(listEntity) && getEntityType(listEntity.entity_type);

      if (!Record.isRecord(listEntity) || !Record.isRecord(listEntityType)) return null;

      const url = listEntityType.absoluteUrl(listEntity.id);

      return listEntityType.entity_class === 'ec_data_set_response' && (
        <IconLink className="row" key={url}>
          {EntitiesListView.renderIconLink(url, listEntityType, listEntityType)}
        </IconLink>
      );
    });
  }

  renderTags({ original }) {
    const { getEntityType } = this.props;
    const { currentRelationshipMap } = this.state;

    const tagEntities = new List(original.listEntity.tags).map((tag) => (Record.isRecord(tag) ? tag : new EntityModel(tag)));

    return (
      <div>
        {tagEntities.sortBy((e) => e.name).map((tagEntity) => {
          const currentRelationship = currentRelationshipMap.get(`${tagEntity.id}:${original.entity.id}`);
          const showRelationship = (original.entity.id === tagEntity.id) || (currentRelationship && currentRelationship.relationship_type !== 'associated');
          const tagEntityType = tagEntity && getEntityType(tagEntity.entity_type);

          return showRelationship && Record.isRecord(tagEntity) && Record.isRecord(tagEntityType) && (
            <Tag key={tagEntity}>
              <StyledLink treatment="link" to={tagEntityType.absoluteUrl(tagEntity.id)}>
                {tagEntity.name}
              </StyledLink>
            </Tag>
          );
        })}
      </div>
    );
  }

  renderPermission({ original }) {
    const { associated } = this.props;
    const { listRelationshipMap } = this.state;

    const relationship = listRelationshipMap.getIn([original.listEntity.id, (associated ? 'associated' : 'nonAssociated')]);

    return (relationship && relationship.length > 0) ? EntitiesListView.permissionNames[relationship[0].permission_type] : null;
  }

  render() {
    const { entity, entityType, entityReference, listEntities, listEntitiesCount, hideHeader, hideFields, initialSort, filterFormData } = this.props;
    const { advancedFilterVisible, tableLoading, pageSize, query } = this.state;

    const tableEntities = this.tableEntities();

    const columns = [{ Header: 'Name', accessor: 'listEntity.name', Cell: EntitiesListView.renderCellName }];

    if (!hideFields.includes('Permission')) columns.push({ Header: '', Cell: this.renderPermission, sortable: false });
    if (!hideFields.includes('Tags')) columns.push({ Header: '', Cell: this.renderTags, sortable: false });
    if (!hideFields.includes('DataSetResponses')) columns.push({ Header: '', Cell: this.renderDataSetResponses, sortable: false });

    if (filterFormData && filterFormData.get('values').get('advanced_filters')) {
      filterFormData.get('values').get('advanced_filters').forEach((filter) => {
        if (filter.get('field')) {
          columns.push({
            Header: filter.get('field').label,
            accessor: `reference.${filter.get('field').value}`,
            Cell: EntitiesListView.renderReferenceValue(filter),
            sortable: false,
          });
        }
      });
    }

    if (!hideFields.includes('Updated')) {
      columns.push({ Header: <div style={{ textAlign: 'right' }}>Updated</div>, accessor: 'listEntity.updated_at', Cell: EntitiesListView.renderDate });
    }

    if (!hideFields.includes('Created')) {
      columns.push({ Header: <div style={{ textAlign: 'right' }}>Created</div>, accessor: 'listEntity.created_at', Cell: EntitiesListView.renderDate });
    }

    const pages = parseInt(listEntitiesCount / (pageSize || EntitiesListView.defaultPageSize), 10) + 1;
    const statusFilterDisabled = !entityType || entityType.entity_class !== 'ec_data_set';
    const advancedFilterDisabled = !entityType || entityType.entity_class !== 'ec_data_set' || advancedFilterVisible;

    let containerStyle = null;
    if (tableLoading || listEntitiesCount > 0 || this.filteredEntities(listEntities).size > 0 ||
        query || advancedFilterVisible || (this.statusFilters() && this.statusFilters().length)) containerStyle = { display: 'flex' };

    return (
      <EntitiesListStyles className="row" style={containerStyle}>
        <SearchContainer className="col-xs-12 row">
          <div className="col-md-4 col-xs-12">
            <div className="search-box-container">
              <input
                type="text"
                placeholder={`Searching ${listEntitiesCount} items...`}
                onChange={(event) => this.setState({ tableLoading: true, query: event.target.value })}
                onBlur={(event) => this.setState({ query: event.target.value })}
              />
              <i className="fas fa-search" />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 col-xs-6">
            {!statusFilterDisabled && entityReference && entityReference.response_status && (
              <EntitiesListStatus
                entity={entity}
                entityType={entityType}
                onChangeCallback={this.fireFetchData}
                statuses={entityReference.response_status.get('statuses')}
              />
            )}
          </div>
          <div className="col-md-4 col-xs-6">
            <div className="filter-toggle-container">
              <Button treatment="button" secondary="true" disabled={advancedFilterDisabled} onClick={() => this.setState({ advancedFilterVisible: true })}>
                <i className="fas fa-filter" /> Advanced
              </Button>
            </div>
          </div>
        </SearchContainer>

        {advancedFilterVisible && (
          <EntitiesListFilter
            entity={entity}
            entityType={entityType}
            onChangeCallback={this.fireFetchData}
            onClearCallback={() => this.setState({ advancedFilterVisible: false })}
          />
        )}

        <Table className={`col-xs-12 ${hideHeader ? 'hide-header' : ''}`}>
          <ReactTable
            ref={(ref) => { this.tableRef = ref; }}
            manual
            loading={tableLoading}
            minRows={1}
            pages={pages}
            defaultPageSize={EntitiesListView.defaultPageSize}
            pageSizeOptions={[5, 10, 20, 50]}
            data={tableEntities}
            resolveData={this.resolveData}
            columns={columns}
            defaultSorted={initialSort}
            onFetchData={this.fetchData}
            onPageSizeChange={this.setPageSize}
            onSortedChange={this.setSorted}
          />
        </Table>
      </EntitiesListStyles>
    );
  }
}


export default EntitiesListView;
