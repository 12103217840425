import styled from 'styled-components';

const DataSetResponseStatusStyles = styled.div`
  display: inline-block;
  border: 1px solid transparent;
  padding: 4px;
  position: relative;

  button {
    position: absolute;
    right: 8px;
    top: 10px;
    display: none;
  }

  select {
    padding: 4px;
    width: 130px;
  }

  &.editable.not-editing:hover {
    border: 1px dashed #CCCCCC;

    button {
      display: inline-block;
    }
  }

  &.edit-updating .status-item {
    color: #666666;
    border: 1px solid #666666;
    background-color: #CCCCCC;
  }

  &.entities-list {
    padding: 0;
    border 0 none;

    span {
      font-size: 0.6em;
      width: 80px;
    }
  }
`;

export const Status = styled.span`
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  padding: 4px;
  text-align: center;
  text-transform: uppercase;
  width: 130px;

  &.status-idx-0 {
    color: red;
    border: 1px solid red;
  }

  &.status-idx-1 {
    color: orange;
    border: 1px solid orange;
  }

  &.status-idx-2 {
    color: green;
    border: 1px solid green;
  }

  &.status-idx-3 {
    color: blue;
    border: 1px solid blue;
  }

  &.status-idx-4 {
    color: purple;
    border: 1px solid purple;
  }
`;

export default DataSetResponseStatusStyles;
