import { connect } from 'react-redux';

import InvitationsShowView from './invitations_show_view';

import { getCurrentUser, getInvitations } from '../../../../reducer';

import { listInvitations, deleteInvitation } from '../../../actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  invitations: getInvitations(state),
});

const mapDispatchToProps = {
  listInvitations,
  deleteInvitation,
};

const InvitationsShowContainer = connect(mapStateToProps, mapDispatchToProps)(InvitationsShowView);

export default InvitationsShowContainer;
