import { Record, List } from 'immutable';

export const responseValueFromDataSetResponse = (field, dataSetResponse) => dataSetResponse.getDataSetFieldResponse(field);

export const responseValueFromEntity = (field, entity) => {
  const subjectFieldResponse = List.isList(entity.field_responses) && entity.field_responses.filter((fr) => fr.get('field_id') === field.get('id')).first();
  let subjectFieldResponseValue = null;

  if (subjectFieldResponse) {
    subjectFieldResponseValue = (field.get('options') ? subjectFieldResponse.get('array_response') : subjectFieldResponse.get('scalar_response'));
  }

  return subjectFieldResponseValue;
};

export const responseValue = (field, subject = null, dataSetResponse = null) => {
  if (Record.isRecord(dataSetResponse) && dataSetResponse.hasDataSetFieldResponse(field)) {
    return responseValueFromDataSetResponse(field, dataSetResponse);
  }

  if (Record.isRecord(subject)) {
    return responseValueFromEntity(field, subject);
  }

  return null;
};

const DataSetResponseForm = null;

export default DataSetResponseForm;
