import React from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';

import Button from '../button';

import EntityModel from '../../models/entity_model';
import DataSetModel from '../../models/data_set_model';
import DataSetResponseModel from '../../models/data_set_response_model';

import DataSetResponseStatusStyles, { Status } from './data_set_response_status_styles';

class DataSetResponseStatusView extends React.Component {
  static propTypes = {
    statuses: PropTypes.shape([PropTypes.shape({ value: PropTypes.number, color: PropTypes.string, label: PropTypes.string })]),
    status: PropTypes.number,
    variant: PropTypes.string,
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    entityPermissions: PropTypes.instanceOf(Map),
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
    doUpdateDataSetResponse: PropTypes.func.isRequired,
    hasUserEntityPermissions: PropTypes.func,
  }

  static defaultProps = {
    statuses: null,
    status: null,
    variant: null,
    entity: null,
    entityPermissions: new Map(),
    dataSet: null,
    dataSetResponse: null,
    hasUserEntityPermissions: () => false,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.isEditable = this.isEditable.bind(this);
    this.renderStatus = this.renderStatus.bind(this);

    this.state = { editing: false, editUpdating: false };
  }

  onChange(e) {
    const { doUpdateDataSetResponse } = this.props;

    doUpdateDataSetResponse(e)
      .then(() => this.setState({ editUpdating: false }))
      .catch(() => this.setState({ editUpdating: false }));

    this.setState({ editUpdating: true });
    this.selectRef.blur();
  }

  isEditable() {
    const { entity, entityPermissions, dataSet, dataSetResponse, hasUserEntityPermissions } = this.props;

    if (!Record.isRecord(entity) || !Record.isRecord(dataSet) || !Record.isRecord(dataSetResponse) || !dataSet.response_status) return false;

    const editable = dataSet.response_status.get('editable');
    if (editable && editable.get('response') && entityPermissions.get(editable.get('response'))) return true;
    if (editable && editable.get('owner') && hasUserEntityPermissions(dataSet.owner, editable.get('owner'))) return true;

    return false;
  }

  renderStatus(statusSetting, idx) {
    const { status } = this.props;
    const { editUpdating } = this.state;

    const setting = Map.isMap(statusSetting) ? statusSetting.toJS() : statusSetting;

    // direct comparison + bitwise comparison because we want 0 === 0 (true), not 0 & 0 (false)
    if (!(status === setting.value || status & setting.value)) return null; // eslint-disable-line no-bitwise

    const style = (setting.color && !editUpdating) ? { color: setting.color, border: `1px solid ${setting.color}` } : {};

    return (
      <Status className={`status-item status-idx-${idx}`} style={style} key={setting}>
        {setting.label}
      </Status>
    );
  }

  render() {
    const { statuses, variant, dataSetResponse } = this.props;
    const { editing, editUpdating } = this.state;

    return statuses && (
      <DataSetResponseStatusStyles
        className={`status-container ${variant || ''} ${this.isEditable() ? 'editable' : ''} ` +
                   `${editing ? 'editing' : 'not-editing'} ${editUpdating ? 'edit-updating' : ''}`}
      >
        {this.isEditable() && (
          <Button treatment="link" onClick={() => { this.setState({ editing: true }); setTimeout(() => this.selectRef.focus(), 100); }}>
            <i className="fas fa-pencil-alt" />
          </Button>
        )}

        {!editing && statuses.sortBy((statusSetting) => statusSetting.value).map((statusSetting, idx) => this.renderStatus(statusSetting, idx))}

        <select
          name="status"
          value={dataSetResponse ? dataSetResponse.status : ''}
          style={{ display: editing ? 'inline-block' : 'none' }}
          ref={(ref) => { this.selectRef = ref; }}
          onChange={(e) => this.onChange(e)}
          onBlur={() => this.setState({ editing: false })}
        >
          {statuses.sortBy((statusSetting) => statusSetting.value).map((statusSetting) => {
            const setting = Map.isMap(statusSetting) ? statusSetting.toJS() : statusSetting;
            return <option value={setting.value} key={setting.value}>{setting.label}</option>;
          })}
        </select>
      </DataSetResponseStatusStyles>
    );
  }
}

export default DataSetResponseStatusView;
