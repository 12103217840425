import { schema } from 'normalizr';

export const chicken = new schema.Entity('chickens', {});
export const widget = new schema.Entity('widgets', {});

export const invitation = new schema.Entity('invitations', {});

export const userRelationship = new schema.Entity('user_relationships', {});
export const userEntity = new schema.Entity('user_entities', { relationships: [userRelationship] });

export const currentRelationship = new schema.Entity('current_relationships', {});
export const currentEntity = new schema.Entity('current_entities', { relationships: [currentRelationship] });

export const listRelationship = new schema.Entity('list_relationships', {});
export const listEntity = new schema.Entity('list_entities', { relationships: [listRelationship] });

export const entityType = new schema.Entity('entity_types', {});

export const page = new schema.Entity('pages', { referenced_by: currentEntity });

export const event = new schema.Entity('events', { referenced_by: currentEntity });

export const dataSet = new schema.Entity('data_sets', { referenced_by: currentEntity });

export const dataSetResponse = new schema.Entity('data_set_responses', { referenced_by: currentEntity, data_set: dataSet });

export const currentEntityReference = new schema.Entity('current_entity_references', { page, event, data_set: dataSet, data_set_response: dataSetResponse });

export const currentEntitySubjectOf = new schema.Entity('current_entities', { relationships: [currentRelationship], data_set_response: dataSetResponse });

export const listEntityReference = new schema.Entity('list_entity_references', { page, event, data_set: dataSet, data_set_response: dataSetResponse });

export const listEntitySubjectOf = new schema.Entity('list_entities', { relationships: [listRelationship], data_set_response: dataSetResponse });

export const entityTypeSubjectOf = new schema.Entity('current_entities', { data_set: dataSet, data_set_response: dataSetResponse });
