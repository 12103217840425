import styled from 'styled-components';
import { Link } from 'react-router-dom';

import clickable, { conditionalStyles } from '../common/clickable';

const StyledLink = styled(Link)`
  ${clickable}

  ${(props) => conditionalStyles(props)}
`;

export default StyledLink;
