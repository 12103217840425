import { connect } from 'react-redux';

import ContentView from './content_view';

import { getSettings, getGlobalSetting, getCurrentUser, getSelectedEntityPermissions } from '../../../reducer';

const mapStateToProps = (state, { match }) => {
  const { entityTypeSlug, entityId, entityAction, childEntityTypeSlug, childEntityId } = match.params;

  const modalOn4XX = getGlobalSetting(state, 'MODAL_ON_4XX');
  const entityLoading = getSettings(state).get('entityLoading');
  const user = getCurrentUser(state);
  const subject = getSettings(state).get('subject');
  const entityPermissions = getSelectedEntityPermissions(state);

  return {
    modalOn4XX,
    entityLoading,
    user,
    subject,
    entityTypeSlug,
    entityId,
    entityAction,
    childEntityTypeSlug,
    childEntityId,
    canEditEntity: entityPermissions.get('editor'),
  };
};

const ContentContainer = connect(mapStateToProps)(ContentView);

export default ContentContainer;
