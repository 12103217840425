import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';

import EntitiesList, { initialSortUpdated } from '../../entities_list';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';

import DataSetShowStyles, { Body } from './data_set_show_styles';

const DataSetShowView = ({ entity, entityType, dataSet, currentRelationships }) => (
  <DataSetShowStyles>
    <Body>
      <EntitiesList
        entity={entity}
        entityType={entityType}
        entityReference={dataSet}
        currentRelationships={currentRelationships}
        childEntityTypeClass="ec_data_set_response"
        hideFields={['Permission', 'DataSetResponses', 'Tags', 'Created']}
        initialSort={initialSortUpdated}
        showPermissionTypes={['associated']}
      />
    </Body>
  </DataSetShowStyles>
);

DataSetShowView.propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
  currentRelationships: PropTypes.instanceOf(OrderedSet),
};

DataSetShowView.defaultProps = {
  dataSet: null,
  currentRelationships: new OrderedSet(),
};

export default DataSetShowView;
