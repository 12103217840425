import { connect } from 'react-redux';
import HeaderView from './header_view';
import { getCurrentUser } from '../../../reducer';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

const HeaderContainer = connect(mapStateToProps)(HeaderView);

export default HeaderContainer;
