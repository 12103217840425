import styled from 'styled-components';

const HeaderStyles = styled.header`
  background-color: white;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: ${(props) => props.theme.color.white};
  border-bottom: 20px solid white;
`;

export const TopBar = styled.div`
  grid-area: header;
  padding: 20px;
  background-color: ${(props) => props.theme.color.primary};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const WrapperLogo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 30px;
`;

export const RightMenu = styled.div`
  display: flex;
  height: 100%;
`;

export const MenuItem = styled.button`
  height: 100%;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  text-align: left;
  outline: none;

  a {
    text-decoration: none;
  }
`;

export const Dot = styled.span`
  position: relative;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => (props.hasnotification ? 'red' : 'transparent')};
    position: absolute;
    z-index: 1;
    left: -7px;
    top: -2px;
  }
`;

export const Name = styled.span`
  color: ${(props) => props.theme.color.white};
  margin-left: 10px;
  font-family: Helvetica;
  text-transform: uppercase;
  font-size: 14px;
`;

export const Icon = styled.span`
  color: ${(props) => props.theme.color.white};
  font-size: 20px;
`;

export default HeaderStyles;
