import { connect } from 'react-redux';
import InitializerView from './initializer_view';
import { getCurrentUser, getRouter, getSettings } from '../../../reducer';
import { listGlobalSettings } from '../../../global_settings/actions';
import { refreshToken } from '../../../session/actions';
import { listEntityTypes, setFirstUrl, setFirstParams } from '../../actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  router: getRouter(state),
  settings: getSettings(state),
});

const mapDispatchToProps = {
  listEntityTypes,
  listGlobalSettings,
  refreshToken,
  setFirstUrl,
  setFirstParams,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  refreshToken: () => dispatchProps.refreshToken(stateProps.user),
});

const InitializerContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InitializerView);

export default InitializerContainer;
