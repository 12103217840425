import React from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';
import { reduxForm } from 'redux-form/lib/immutable';

import Modal from '../../modal';
import { TextField } from '../../../../common/form';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';

import Button from '../../button';

import HierarchyCreateStyles from './hierarchy_create_styles';

class HierarchyCreateView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel),
    entity: PropTypes.instanceOf(EntityModel),
    childEntityType: PropTypes.instanceOf(EntityTypeModel),
    newEntityForm: PropTypes.instanceOf(Map),
    createListEntity: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
  }

  static defaultProps = {
    user: null,
    entity: null,
    childEntityType: null,
    newEntityForm: new Map(),
    pristine: false,
    invalid: false,
    submitting: false,
  };

  static validateModal = (values) => {
    const errors = {};

    ['name'].forEach((item) => {
      if (!values || !values.get(item)) errors[item] = 'Required';
    });

    return errors;
  }

  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = { isOpen: false };
  }

  submitForm() {
    const { user, createListEntity, newEntityForm, entity, childEntityType } = this.props;

    createListEntity(user.authToken, { name: newEntityForm.get('values').get('name'), entity_type: childEntityType.id, parents: [entity.id] })
      .then(() => this.setState({ isOpen: false }));
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  render() {
    const { entity, childEntityType, handleSubmit, pristine, invalid, submitting } = this.props;
    const { isOpen } = this.state;

    return Record.isRecord(entity) && Record.isRecord(childEntityType) && (
      <HierarchyCreateStyles>
        <Button treatment="button" primary="true" onClick={() => this.setState({ isOpen: true })}>
          <i className={`fas fa-${childEntityType.icon}`} /> New {childEntityType.name}
        </Button>

        <Modal isOpen={isOpen} closeModal={this.closeModal} offset="25%">
          <form onSubmit={handleSubmit(this.submitForm)}>
            <div className="modal-header">
              New {childEntityType.name}
            </div>

            <div className="modal-body">
              <div className="col-xs-12">
                <TextField label="Name" type="text" name="name" required />
              </div>
            </div>

            <div className="modal-footer">
              <Button treatment="button" primary="true" disabled={pristine || invalid || submitting}>Create {childEntityType.name}</Button>
            </div>
          </form>
        </Modal>
      </HierarchyCreateStyles>
    );
  }
}

export default reduxForm({ validate: HierarchyCreateView.validateModal, enableReinitialize: true })(HierarchyCreateView);
