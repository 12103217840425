import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import RegisterStyles from './register_styles';
import { TextField } from '../../../common/form';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import { OAUTH } from '../../../api';
import Introduction from '../introduction';

const propTypes = {
  openAccountCreation: PropTypes.bool,
  signedIn: PropTypes.bool.isRequired,
  status: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  location: PropTypes.shape({ state: PropTypes.shape({ returnTo: PropTypes.string }) }).isRequired,
};

const defaultProps = {
  openAccountCreation: false,
  token: null,
  pristine: false,
  invalid: false,
};

const oauthButton = (provider, returnTo) => (
  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-5x`} />}
  </a>
);

const DEFAULT_RETURN_TO = '/';

const RegisterView = ({ openAccountCreation, signedIn, status, token, handleSubmit, pristine, invalid, location: { state } }) => {
  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  const oauthRow = (
    <div className="oauth-row">
      <span>Sign in with:</span>
      {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
    </div>
  );

  const errorMessage = () => {
    if (status && status.get('errors')) {
      return <span className="error">{status.get('errors').join(', ')}</span>;
    }

    return <span className="error">Unable to register a new account.</span>;
  };

  return (openAccountCreation || token) && (
    <RegisterStyles className="container">
      <Introduction />
      <div className="row center-xs">
        <div className="col-md-5 col-xs-11">
          <div className="box">
            <form onSubmit={onSubmit}>
              <h3>Sign Up</h3>
              <TextField label="Name" type="text" name="name" fullWidth required />
              <TextField label="Email" type="text" name="email" fullWidth required />
              <TextField label="Password" type="password" name="password" fullWidth required />
              <TextField label="Password Confirmation" type="password" name="password_confirmation" fullWidth required />
              <input type="submit" className="btn" value="Register" disabled={pristine || invalid || submitting} />
              <span className="status">
                {failed && errorMessage()}
                {submitting && <Spinner />}
              </span>
              {OAUTH.canRegister && OAUTH.providers.size > 0 && oauthRow}
              <p>
                <Link to={{ pathname: '/signin', state: { returnTo } }}>Sign in</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </RegisterStyles>
  );
};

RegisterView.propTypes = propTypes;
RegisterView.defaultProps = defaultProps;

export default RegisterView;
