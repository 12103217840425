import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import EntityModel from '../models/entity_model';

const reducer = new ResourcefulReducer('list_entities', EntityModel, {
  fetch: ACTIONS.FETCH_LIST_ENTITY,
  list: ACTIONS.LIST_LIST_ENTITIES,
  create: ACTIONS.CREATE_LIST_ENTITY,
});

const ListEntityReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_LIST_ENTITIES) state = undefined; // eslint-disable-line no-param-reassign

  return reducer(state, action);
};

ListEntityReducer.selectors = reducer.selectors;

export default ListEntityReducer;
