import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  relationship_type: null,
  permission_type: null,
  parent: null,
  child: null,
};

class RelationshipModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::Relationship';
}

export default RelationshipModel;
