import React from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';

import DataSetModel from '../../../models/data_set_model';
import DataSetResponseModel from '../../../models/data_set_response_model';

import DataSetResponseShowStyles from './data_set_response_show_styles';

class DataSetResponseShowView extends React.Component {
  static propTypes = {
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
  }

  static defaultProps = {
    dataSet: null,
    dataSetResponse: null,
  };

  constructor(props) {
    super(props);

    this.renderField = this.renderField.bind(this);
  }

  renderResponse(field) {
    const { dataSetResponse } = this.props;

    const response = dataSetResponse.getDataSetFieldResponse(field);
    return (response && field.get('options')) ? response.map((option) => option.get('label')).join(', ') : response;
  }

  renderField(field) {
    return field.get('field_type') !== 'content' && (
      <React.Fragment key={field}>
        {field.get('line_break_before') && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" />}
        <div
          className={`data-set-response-field col-md-${field.get('numeric_width_factor')} ` +
                     `col-sm-${Math.max(field.get('numeric_width_factor'), 12)} ` +
          `col-xs-${Math.max(field.get('numeric_width_factor'), 12)}`}
        >
          <div className="box">
            <div className="data-set-response-field-name"><div>{field.get('name')}</div></div>
            <div className="data-set-response-field-response">
              {this.renderResponse(field)}
            </div>
          </div>
        </div>
        {field.get('line_break_after') && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" />}
      </React.Fragment>
    );
  }

  render() {
    const { dataSet, dataSetResponse } = this.props;

    return Record.isRecord(dataSet) && Record.isRecord(dataSetResponse) && (
      <DataSetResponseShowStyles>
        <div className="row">
          {dataSet.fields.filter((dataSetField) => dataSet.displayField(dataSetField, dataSetResponse))
            .sortBy((dataSetField) => dataSetField.get('sort_order')).map((dataSetField) => this.renderField(dataSetField))}
        </div>
      </DataSetResponseShowStyles>
    );
  }
}

export default DataSetResponseShowView;
