import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map, List } from 'immutable';
import { reduxForm } from 'redux-form/lib/immutable';

import Modal from '../modal';
import Button from '../button';
import { SelectField } from '../../../common/form';

import EntityModel from '../../models/entity_model';

import SubjectOwnerModalStyles from './subject_owner_modal_styles';

const propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  isOpen: PropTypes.bool,
  subjectOwnerForm: PropTypes.instanceOf(Map),
  potentialSubjects: PropTypes.instanceOf(OrderedSet),
  potentialOwners: PropTypes.instanceOf(OrderedSet),
  submitCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  subjectOwnerForm: new Map(),
  potentialSubjects: new OrderedSet(),
  potentialOwners: new OrderedSet(),
  pristine: false,
  invalid: false,
  submitting: false,
};

const renderOptions = (entities) => new List(entities.map((e) => ({ label: e.get('name'), value: e.get('id') })));

const validateModal = (values) => {
  const errors = {};

  ['subject', 'owner'].forEach((item) => {
    if (!values || !values.get(item) || !values.get(item).value) errors[item] = 'Required';
  });

  return errors;
};

const SubjectOwnerModalView = ({
  entity, isOpen, subjectOwnerForm, potentialSubjects, potentialOwners, submitCallback, closeCallback, handleSubmit, pristine, invalid, submitting,
}) => {
  const submitForm = () => submitCallback(subjectOwnerForm.get('values').get('subject'), subjectOwnerForm.get('values').get('owner'));

  return (potentialSubjects.size + potentialOwners.size) > 0 && (
    <Modal isOpen={isOpen} closeModal={closeCallback} offset="20%">
      <SubjectOwnerModalStyles>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="modal-header">
            New {entity.name}
          </div>

          <div className="modal-body">
            {potentialSubjects.size > 1 && (
              <div className="col-xs-12">
                <SelectField options={renderOptions(potentialSubjects)} label="Subject" name="subject" required>
                  <div className="label-description">I am creating this {entity.name} about...</div>
                </SelectField>
              </div>
            )}

            {potentialOwners.size > 1 && (
              <div className="col-xs-12">
                <SelectField options={renderOptions(potentialOwners)} label="Owner" name="owner" required>
                  <div className="label-description">I am creating this {entity.name} while representing...</div>
                </SelectField>
              </div>
            )}
          </div>

          <div className="modal-footer">
            <Button treatment="button" primary="true" disabled={pristine || invalid || submitting}>Create {entity.name}</Button>
          </div>
        </form>
      </SubjectOwnerModalStyles>
    </Modal>
  );
};

SubjectOwnerModalView.propTypes = propTypes;
SubjectOwnerModalView.defaultProps = defaultProps;

export default reduxForm({ validate: validateModal, enableReinitialize: true })(SubjectOwnerModalView);
