import { connect } from 'react-redux';

import DataSetResponseStatusView from './data_set_response_status_view';

import { getCurrentUser, getUserEntities, getSelectedEntityPermissions, getDataSets, getDataSetResponses, hasUserEntityPermissions } from '../../../reducer';

import { updateDataSetResponse } from '../../actions';

const mapStateToProps = (state, { entity }) => {
  if (!entity) return {};

  const userEntities = getUserEntities(state);
  const entityPermissions = getSelectedEntityPermissions(state);
  const dataSetResponse = getDataSetResponses(state).filter((dsr) => dsr.referenced_by === entity.id).first();
  const dataSet = dataSetResponse && getDataSets(state).filter((ds) => ds.id === dataSetResponse.data_set).first();

  return {
    user: getCurrentUser(state),
    entityPermissions,
    dataSet,
    dataSetResponse,
    statusType: (dataSet && dataSet.get('response_status')) ? dataSet.get('response_status').get('type') : null,
    statuses: (dataSet && dataSet.get('response_status')) ? dataSet.get('response_status').get('statuses') : null,
    status: dataSetResponse ? dataSetResponse.get('status') : null,
    hasUserEntityPermissions: (e, permissionTypes) => hasUserEntityPermissions(state, e, userEntities, permissionTypes),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateDataSetResponse: (token, id, status) => dispatch(updateDataSetResponse(token, id, status)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doUpdateDataSetResponse: (e) => dispatchProps.updateDataSetResponse(stateProps.user.authToken, stateProps.dataSetResponse.get('id'), e.currentTarget.value),
});

const DataSetResponseStatusContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DataSetResponseStatusView);

export default DataSetResponseStatusContainer;
