import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import RelationshipModel from '../models/relationship_model';

const reducer = new ResourcefulReducer('list_relationships', RelationshipModel, {
  create: ACTIONS.CREATE_LIST_RELATIONSHIP,
  update: ACTIONS.UPDATE_LIST_RELATIONSHIP,
  remove: ACTIONS.DELETE_LIST_RELATIONSHIP,
});

const ListRelationshipReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_LIST_RELATIONSHIPS) state = undefined; // eslint-disable-line no-param-reassign

  return reducer(state, action);
};

ListRelationshipReducer.selectors = reducer.selectors;

export default ListRelationshipReducer;
