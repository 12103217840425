import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Map, OrderedSet, List, Record } from 'immutable';
import { reduxForm } from 'redux-form/lib/immutable';

import { TextField, SelectField } from '../../../../common/form';

import Modal from '../../modal';
import Button from '../../button';
import StyledLink from '../../styled_link';

import UserModel from '../../../../session/models/user_model';

import DashboardShowStyles, { Section, CtaBlock } from './dashboard_show_styles';

const validate = (values) => {
  const errors = {};

  if (!values.get('name')) errors.name = 'Required';

  return errors;
};

const DashboardShowView = ({ user, userEntities, entityTypes, newEntityForm, handleSubmit, invalid, submitting, submitSucceeded, createUserEntity }) => {
  const [newEntityType, setNewEntityType] = useState(null);
  const [createdEntity, setCreatedEntity] = useState(null);

  const submitForm = () => createUserEntity(user.authToken, { name: newEntityForm.getIn(['values', 'name']),
    entity_type: newEntityType.id,
    children: [newEntityForm.getIn(['values', 'creator']).value] }).then((response) => setCreatedEntity(response));

  const renderOptions = (targetEntityType) => new List(
    userEntities.filter((e) => (Record.isRecord(e) && Record.isRecord(targetEntityType) && entityTypes.get(e.entity_type).entity_class === 'ec_hierarchy' &&
                                entityTypes.get(e.entity_type).ordinality < targetEntityType.ordinality)).map((e) => ({ label: e.name, value: e.id })),
  );

  if (submitSucceeded && createdEntity) {
    return <Redirect to={newEntityType.absoluteUrl(createdEntity.result)} />;
  }

  return (
    <DashboardShowStyles>
      <Section className="row">
        {userEntities.filter((e) => Record.isRecord(e) && ['v_public', 'v_global'].includes(e.visibility)).map((e) => {
          const entityType = entityTypes.get(e.entity_type);
          return Record.isRecord(entityType) && entityType.entity_class === 'ec_data_set' && (
            <div className="col-md-4 col-xs-12" key={e}>
              <CtaBlock>
                <div className="icon">
                  <span><i className={`fa fa-2x fa-${entityType.icon}`} /></span>
                </div>
                <div className="name">
                  {e.name}
                </div>
                <div className="actions">
                  <StyledLink treatment="button" thin="true" to={entityType.absoluteUrl(e.id)}>
                    Create
                  </StyledLink>
                </div>
              </CtaBlock>
            </div>
          );
        })}
      </Section>

      <Section className="row">
        {entityTypes.filter((et) => (Record.isRecord(et) && ['ec_hierarchy', 'ec_page', 'ec_event'].includes(et.entity_class) &&
                                     (user.entityCreator || et.type_visibility === 'tv_public' || et.indexable)))
          .sortBy((et) => et.ordinality).valueSeq().map((et) => (
            <div className="col-md-4 col-xs-12" key={et}>
              <CtaBlock>
                <div className="icon">
                  <span><i className={`fa fa-2x fa-${et.icon}`} /></span>
                </div>
                <div className="name">
                  {et.name}
                </div>
                <div className="actions">
                  {et.indexable && (
                    <StyledLink treatment="button" thin="true" to={`/${et.slug}`}>
                      Browse
                    </StyledLink>
                  )}
                  {(user.entityCreator || et.type_visibility === 'tv_public') && renderOptions(et).size > 0 && (
                    <Button treatment="button" thin="true" onClick={() => setNewEntityType(et)}>
                      Create
                    </Button>
                  )}
                </div>
              </CtaBlock>
            </div>
          ))}
      </Section>

      <Modal isOpen={newEntityType !== null && userEntities.size > 0} closeModal={() => setNewEntityType(null)} offset="20%">
        {newEntityType && (
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="modal-header">
              New {newEntityType.name}
            </div>

            <div className="modal-body">
              <div className="col-xs-12">
                <TextField label="Name" type="text" name="name" required />
              </div>

              <div className="col-xs-12">
                <SelectField options={renderOptions(newEntityType)} label="Creator" name="creator" required>
                  <div className="label-description">This {newEntityType.name} is being created by...</div>
                </SelectField>
              </div>
            </div>

            <div className="modal-footer">
              <Button treatment="button" primary="true" disabled={invalid || submitting}>Create {newEntityType.name}</Button>
            </div>
          </form>
        )}
      </Modal>
    </DashboardShowStyles>
  );
};

DashboardShowView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  userEntities: PropTypes.instanceOf(OrderedSet),
  entityTypes: PropTypes.instanceOf(Map),
  newEntityForm: PropTypes.instanceOf(Map),
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  createUserEntity: PropTypes.func.isRequired,
};

DashboardShowView.defaultProps = {
  userEntities: new OrderedSet(),
  entityTypes: new Map(),
  newEntityForm: new Map(),
  invalid: false,
  submitting: false,
  submitSucceeded: false,
};

export default reduxForm({ form: 'createEntity', validate, enableReinitialize: true })(DashboardShowView);
