import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ForgotPasswordStyles from './forgot_password_styles';
import { TextField } from '../../../common/form';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';

const propTypes = {
  openAccountCreation: PropTypes.bool,
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
    }),
  }).isRequired,
};

const defaultProps = {
  openAccountCreation: false,
  invalid: false,
};

const DEFAULT_RETURN_TO = '/';

const ForgotPasswordView = ({ openAccountCreation, signedIn, handleSubmit, invalid, location: { state } }) => {
  const [onSubmit, { submitting, failed, success }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  let content;
  if (success) {
    content = <p>Check your inbox for an email with a password reset link.</p>;
  } else {
    content = (
      <Fragment>
        <TextField label="Email" type="text" name="email" fullWidth required />
        <input type="submit" className="btn" value="Request Password Reset" disabled={invalid || submitting} />
        <span className="status">
          {failed && <span className="error">Please try again...</span>}
          {submitting && <Spinner />}
        </span>
      </Fragment>
    );
  }

  return (
    <ForgotPasswordStyles className="container">
      <div className="row center-xs">
        <div className="col-xs-11 col-md-5">
          <div className="box">
            <form onSubmit={onSubmit}>
              <h3>Reset your password</h3>
              {content}
              <p>
                <Link to={{ pathname: '/signin', state: { returnTo } }}>Sign in</Link>
                {openAccountCreation && (
                  <React.Fragment>
                    <span style={{ padding: '0 1em' }}>|</span>
                    <Link to={{ pathname: '/register', state: { returnTo } }}>Create an account</Link>
                  </React.Fragment>
                )}
              </p>
            </form>
          </div>
        </div>
      </div>
    </ForgotPasswordStyles>
  );
};

ForgotPasswordView.propTypes = propTypes;
ForgotPasswordView.defaultProps = defaultProps;

export default ForgotPasswordView;
