import styled from 'styled-components';

const HierarchyShowStyles = styled.div`
`;

export const Body = styled.div`
  .react-tabs {
    ul {
      margin: 0 0 25px 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-bottom: 1px solid ${(props) => props.theme.foundation.tabBar.borderColor};
      box-shadow: 0 5px 5px rgba(0,0,0,0.1);

      li {
        cursor: pointer;
        display: inline-block;
        width: 14%;
        text-align: center;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid ${(props) => props.theme.foundation.tabBar.borderColor};
        }

        &.react-tabs__tab--selected {
          border-bottom: 4px solid ${(props) => props.theme.foundation.tabBar.darkerBorderColor};
        }
      }
    }
  }
`;

export const Profile = styled.div``;

export const TabContent = styled.div`
  padding: 10px;
`;

export default HierarchyShowStyles;
