import React from 'react';
import { Switch, Route } from 'react-router';

import styled from 'styled-components';

import Header from './header';

import SignIn from '../../session/components/sign_in';
import FinishSignIn from '../../session/components/finish_sign_in';
import SignOut from '../../session/components/sign_out';
import MockAuth from '../../session/components/mock_auth';
import Register from '../../session/components/register';
import ForgotPassword from '../../session/components/forgot_password';
import ResetPassword from '../../session/components/reset_password';
import AuthenticatedRoute from '../../util/authenticated_route';
import ConfirmEmail from '../../session/components/confirm_email';

import Initializer from '../../foundation/components/initializer';
import Root from '../../foundation/components/root';

let mockAuthRoute = null;
if (process.env.NODE_ENV !== 'production') {
  mockAuthRoute = <Route path="/mockauth/:provider/:returnTo" component={MockAuth} />;
}

const ContentStyles = styled.div`
  padding-top: 80px;
`;

const RootView = () => (
  <div>
    <Route component={Initializer} />
    <Route component={Header} />
    <ContentStyles>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/finish_signin" component={FinishSignIn} />
        <Route path="/register" component={Register} />
        <Route path="/signout" component={SignOut} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />

        <AuthenticatedRoute path="/confirm_email/:token" component={ConfirmEmail} />
        {mockAuthRoute}

        <Root />
      </Switch>
    </ContentStyles>
  </div>
);

export default RootView;
