import { connect } from 'react-redux';

import DataSetResponseShowView from './data_set_response_show_view';

import { getDataSets, getDataSetResponses } from '../../../../reducer';

const mapStateToProps = (state, { childEntity }) => {
  const dataSetResponse = getDataSetResponses(state).filter((dsr) => dsr.referenced_by === childEntity.id).first();
  const dataSet = dataSetResponse && getDataSets(state).filter((ds) => ds.id === dataSetResponse.data_set).first();

  return {
    dataSet,
    dataSetResponse,
  };
};

const DataSetResponseShowContainer = connect(mapStateToProps)(DataSetResponseShowView);

export default DataSetResponseShowContainer;
