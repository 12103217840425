import styled from 'styled-components';

const ActionPanelStyles = styled.div`
  background-color: white;
  color: ${(props) => props.theme.foundation.actionPanel.textColor};
  border-top: 1px solid transparent;
  height: 80px;
`;

export const Container = styled.div``;

export const Header = styled.div`
  height: 80px;
`;

export const Inset = styled.div`
  height: 40px;
  padding: 20px 10px;
  text-align: center;

  > div:last-of-type {
    text-align: right;
  }

  > div:first-of-type {
    text-align: left;
  }

  .entity-tools a {
    margin-right: 8px;
  }

  .entity-tools a:last-child {
    margin-right: 0;
  }
`;

export const SidebarExtension = styled.div`
  min-height: 50px;
  border-top: 1px solid ${(props) => props.theme.foundation.actionPanel.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.foundation.actionPanel.borderColor};
  background-color: ${(props) => props.theme.foundation.actionPanel.backgroundColor};
  box-shadow: 0px 1px rgba(0, 0, 0, 0.1);
`;

export const SecondaryPanel = styled.div`
  position: relative;
  margin: 15px;
`;

export const SecondaryPanelClose = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;

  a {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.foundation.actionPanel.borderColor};
    color: ${(props) => props.theme.foundation.actionPanel.textColor};
  }
`;

export const SecondaryPanelInset = styled.div`
  background-color: white;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 10px;
`;

export const SecondaryPanelTitle = styled.div`
  font-size: 1.1em;
  margin-bottom: 10px;

  > i {
    margin-right: 10px;
  }

  a {
    margin-left: 10px;
  }
`;

export const Icon = styled.span`
  display: inline-block;
  line-height: 1.5em;
  width: 1.5em;
  text-align: center;
  color: white;
  background-color: ${(props) => props.theme.foundation.actionPanel.iconBackgroundColor};
  border-radius: 3px;
  text-decoration: none;
`;

export const Name = styled.span`
  display: inline-block;
  margin-left: 10px;
`;

export const EntityActions = styled.div``;

export const EntityActionsAddTo = styled.div`
  margin: 10px;
`;

export default ActionPanelStyles;
