import { connect } from 'react-redux';

import EventEditView from './event_edit_view';

import { getForm, getCurrentUser, getEvents } from '../../../../reducer';
import { updateEvent } from '../../../actions';

const mapStateToProps = (state, { entity }) => {
  const user = getCurrentUser(state);
  const event = getEvents(state).filter((p) => p.referenced_by === entity.id).first();
  const id = event && event.id;
  const form = `entity:${entity.id}:edit`;

  return {
    user,
    event,
    id,
    form,
    eventForm: getForm(state).get(form),
    initialValues: event ? event.toJS() : {},
  };
};

const mapDispatchToProps = {
  updateEvent,
};

const EventEditContainer = connect(mapStateToProps, mapDispatchToProps)(EventEditView);

export default EventEditContainer;
