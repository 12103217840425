import { connect } from 'react-redux';

import VisibilityModalView from './visibility_modal_view';

import { getForm, getGlobalSetting, getCurrentUser } from '../../../reducer';

import { updateCurrentEntity } from '../../actions';

const mapStateToProps = (state, { entity }) => {
  const form = `entity:${entity.id}:visibility`;

  return {
    allowGlobalVisibility: getGlobalSetting(state, 'ALLOW_GLOBAL_VISIBILITY'),
    user: getCurrentUser(state),
    form,
    visibilityForm: getForm(state).get(form),
    initialValues: {
      visibility: entity.visibility,
    },
  };
};

const mapDispatchToProps = {
  updateCurrentEntity,
};

const VisibilityModalContainer = connect(mapStateToProps, mapDispatchToProps)(VisibilityModalView);

export default VisibilityModalContainer;
