import { css } from 'styled-components';

const clickable = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.1rem;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
`;

const buttonBase = css`
  font-family: 'Avenir Next', 'Open Sans', sans-serif;
  font-weight: 500;
  border: 1px solid #AAAAAA;
  box-shadow: 1px 1px 2px #888;

  i {
    margin-right: 4px;
    font-size: 0.8em;
  }

  :hover {
    box-shadow: 1px 1px 4px #555;
  }

  :disabled {
    cursor: not-allowed;
    color: #AAA;
    background-color: #EEE;
    text-decoration: none !important;
    border: 1px solid transparent;
    box-shadow: 0 0 0 transparent;
  }
`;

const buttonNormalBase = css`
  border-radius: 4px;
  letter-spacing: 0.5px;
  padding: 0.5em 1.5em;
`;

const buttonThinBase = css`
  border-radius: 5px;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  padding: 0.25em 1em;
`;

const linkBase = css`
  :hover {
    text-decoration: underline;
  }
`;

export const conditionalStyles = (props) => {
  const { treatment, primary, secondary, thin, custom } = props;

  let styles = '';

  if (treatment === 'button') {
    styles += buttonBase;

    if (!custom && thin) styles += buttonThinBase;
    if (!custom && !thin) styles += buttonNormalBase;

    if (primary) {
      styles += `
  background-color: ${props.theme.foundation.button.themeBackgroundColorPrimary};
  color: ${props.theme.foundation.button.themeTextColorPrimary};
`;
    } else if (secondary) {
      styles += `
  background-color: ${props.theme.foundation.button.themeBackgroundColorSecondary};
  color: ${props.theme.foundation.button.themeTextColorSecondary};
`;
    } else {
      styles += `
  color: ${props.theme.foundation.textColor};
`;
    }
  } else if (treatment === 'link') {
    styles += linkBase;

    styles += `
  color: ${props.theme.foundation.linkColor};
`;
  }

  return styles;
};

export default clickable;
