import styled from 'styled-components';

const StatusStyles = styled.div`
  margin: 0 10px;
  padding: 15px;
  background-color: ${(props) => props.theme.foundation.status.backgroundColor};
  border-left: 1px solid ${(props) => props.theme.foundation.status.borderColor};
  border-right: 1px solid ${(props) => props.theme.foundation.status.borderColor};
  border-top: 1px solid ${(props) => props.theme.foundation.status.borderColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export default StatusStyles;
