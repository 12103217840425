import styled from 'styled-components';
// import theme from '../theme';

const RegisterStyles = styled.div`
  .box {
    background-color: ${(props) => props.theme.color.white};
    border: 1px solid ${(props) => props.theme.color.gray60};
    box-shadow: 1px 1px ${(props) => props.theme.color.grayLighter};
    text-align: left;
    padding: 30px;
    margin-top: 20%;

    a {
      color: ${(props) => props.theme.color.text};
    }
  }

  input,
  input[type='text'],
  textarea {
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.color.gray60};
    display: block;
    margin-top: 10px;
    padding: 10px;
  }

  .btn {
    font-size: 14px;
    border-radius: 0;
    background-color: ${(props) => props.theme.color.action};
    border: 1px solid ${(props) => props.theme.color.action};
    color: ${(props) => props.theme.color.textLight};
    padding: 10px;
    text-transform: uppercase;
    &:hover {
      color: ${(props) => props.theme.color.actionHover};
    }
  }

  label {
    text-transform: uppercase;
    font-size: 12px;
  }

  .status {
    margin: 0 1em;
  }
  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='password'] {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  .oauth-row {
    margin: auto;
    width: fit-content;
    padding: 1em;
  }

  .oauth-button {
    margin: 5px;
    color: ${(props) => props.theme.color.action};
    &:hover {
      color: ${(props) => props.theme.color.actionHover};
    }
  }
`;

export default RegisterStyles;
