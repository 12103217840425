import { Map } from 'immutable';
import actions from '../actions';

const DEFAULT_STATE = new Map();

function SettingReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.SET_FIRST_URL:
      return state.merge({ firstUrl: action.url });
    case actions.SET_FIRST_PARAMS:
      return state.merge({ firstParams: action.params });
    case actions.SET_ENTITY_LOADING:
      return state.merge({ entityLoading: action.loading });
    case actions.SET_SUBJECT:
      return state.merge({ subject: action.id });
    case actions.SET_OWNER:
      return state.merge({ owner: action.id });
    default:
      return state;
  }
}

export default SettingReducer;
