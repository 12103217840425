import { connect } from 'react-redux';
import { parse } from 'query-string';
import { hasGlobalSetting, getCurrentUser } from '../../../reducer';
import ForgotPasswordForm from './forgot_password_form';
import { forgotPassword } from '../../actions';

const mapStateToProps = (state, { location: { search } }) => ({
  openAccountCreation: hasGlobalSetting(state, 'OPEN_ACCOUNT_CREATION'),
  signedIn: getCurrentUser(state).signedIn,
  initialValues: {
    email: parse(search).email,
  },
});

const mapDispatchToProps = {
  onSubmit: (args) => forgotPassword(args.get('email')),
};

const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordForm);

export default ForgotPasswordContainer;
