import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map, Record } from 'immutable';
import { Redirect } from 'react-router-dom';

import UserModel from '../../../session/models/user_model';

import Finder from '../finder';
import ActionPanel from '../action_panel';
import Sidebar from '../sidebar';
import Content from '../content';
import Status from '../status';

import NavigatorStyles, { FixedContainer, FixedBottomContainer, StatusContainer, ScrollContainer, FinderContainer,
  ActionPanelContainer, SidebarContainer, ContentContainer } from './navigator_styles';

class NavigatorView extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel),
    match: PropTypes.shape({
      params: PropTypes.shape({
        entityTypeSlug: PropTypes.string,
        entityId: PropTypes.string,
        entityAction: PropTypes.string,
        childEntityTypeSlug: PropTypes.string,
        childEntityId: PropTypes.string,
        childEntityAction: PropTypes.string,
      }),
    }),
    userEntities: PropTypes.instanceOf(OrderedSet),
    currentEntities: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
    entityPermissions: PropTypes.instanceOf(Map),
    childEntityPermissions: PropTypes.instanceOf(Map),
    getEntityType: PropTypes.func.isRequired,
    getUserEntity: PropTypes.func.isRequired,
    getCurrentEntity: PropTypes.func.isRequired,
    getListEntity: PropTypes.func.isRequired,
    doSetEntityLoading: PropTypes.func.isRequired,
    doSetSelectedEntity: PropTypes.func.isRequired,
    doSetSelectedEntityType: PropTypes.func.isRequired,
    doSetSelectedEntityPermissions: PropTypes.func.isRequired,
    doSetSelectedChildEntityPermissions: PropTypes.func.isRequired,
    doClearCurrentEntities: PropTypes.func.isRequired,
    doClearCurrentRelationships: PropTypes.func.isRequired,
    doFetchCurrentEntity: PropTypes.func.isRequired,
    doFetchCurrentEntityParents: PropTypes.func.isRequired,
    doFetchCurrentEntityChildren: PropTypes.func.isRequired,
    doFetchCurrentEntityReference: PropTypes.func.isRequired,
    doFetchCurrentEntitySubjectOf: PropTypes.func.isRequired,
    doFetchEntityTypeSubjectOf: PropTypes.func.isRequired,
    doClearListEntities: PropTypes.func.isRequired,
    doClearListRelationships: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
    match: {
      params: {
        entityTypeSlug: null,
        entityId: null,
        entityAction: null,
        childEntityTypeSlug: null,
        childEntityId: null,
        childEntityAction: null,
      },
    },
    userEntities: new OrderedSet(),
    currentEntities: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    entityPermissions: new Map(),
    childEntityPermissions: new Map(),
  };

  constructor(props) {
    super(props);

    const { user } = props;

    this.getSelectedEntityAndType = this.getSelectedEntityAndType.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.updatePermissions = this.updatePermissions.bind(this);
    this.toggleFinder = this.toggleFinder.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.showPrimaryPanel = this.showPrimaryPanel.bind(this);
    this.showSecondaryPanel = this.showSecondaryPanel.bind(this);
    this.contentPadding = this.contentPadding.bind(this);

    this.state = { isFinderCollapsed: (!user || !user.signedIn), isSidebarCollapsed: true, isEntityLoadingInitiated: false };
  }

  componentDidMount() {
    this.updateEntity();

    this.setState({ isEntityLoadingInitiated: true });
  }

  componentDidUpdate(prevProps) {
    const { user, currentRelationships, match: { params: { entityId, childEntityId } } } = this.props;
    const prevEntityId = prevProps.match.params.entityId;
    const prevChildEntityId = prevProps.match.params.childEntityId;
    const prevUser = prevProps.user;
    const prevCurrentRelationships = prevProps.currentRelationships;

    const userChanged = (!!user !== !!prevUser) || (user && prevUser && user.email !== prevUser.email);
    const entityIdChanged = entityId !== prevEntityId;
    const childEntityIdChanged = childEntityId !== prevChildEntityId;
    const relationshipsChanged = currentRelationships.size > 0 && currentRelationships.hashCode() !== prevCurrentRelationships.hashCode();

    if (userChanged || entityIdChanged) this.updateEntity();
    if (userChanged || entityIdChanged || childEntityIdChanged || relationshipsChanged) this.updatePermissions();
  }

  getSelectedEntityAndType() {
    const { match: { params: { entityId } }, getUserEntity, getEntityType, getCurrentEntity, getListEntity } = this.props;

    const entity = entityId ? (getListEntity(entityId) || getCurrentEntity(entityId) || getUserEntity(entityId) || null) : null;

    return {
      entity,
      entityType: entity ? (getEntityType(entity.get('entity_type')) || null) : null,
    };
  }

  getSelectedChildEntityAndType() {
    const { match: { params: { childEntityId } }, getUserEntity, getEntityType, getCurrentEntity, getListEntity } = this.props;

    const childEntity = childEntityId ? (getListEntity(childEntityId) || getCurrentEntity(childEntityId) || getUserEntity(childEntityId) || null) : null;

    return {
      childEntity,
      childEntityType: childEntity ? (getEntityType(childEntity.get('entity_type')) || null) : null,
    };
  }

  updatePermissions() {
    const { doSetSelectedEntityPermissions, doSetSelectedChildEntityPermissions } = this.props;

    const { entity } = this.getSelectedEntityAndType();
    const { childEntity } = this.getSelectedChildEntityAndType();

    if (doSetSelectedEntityPermissions) doSetSelectedEntityPermissions(entity);
    if (doSetSelectedChildEntityPermissions) doSetSelectedChildEntityPermissions(childEntity);
  }

  updateEntity() {
    const { doSetSelectedEntity, doSetSelectedEntityType, doClearCurrentEntities, doClearCurrentRelationships, doFetchCurrentEntity,
      doFetchCurrentEntityParents, doFetchCurrentEntityChildren, doFetchCurrentEntityReference, doFetchCurrentEntitySubjectOf,
      doFetchEntityTypeSubjectOf, doClearListEntities, doClearListRelationships, doSetEntityLoading, match: { params: { entityId } } } = this.props;

    const { entity, entityType } = this.getSelectedEntityAndType();

    doSetEntityLoading(true);

    doSetSelectedEntity(entity);
    doSetSelectedEntityType(entityType);

    doClearCurrentEntities();
    doClearCurrentRelationships();
    doClearListEntities();
    doClearListRelationships();

    if (entityId) {
      doFetchCurrentEntity(entityId).then(() => {
        doFetchCurrentEntityReference(entityId);
        doFetchCurrentEntityChildren(entityId).then(() => {
          doFetchCurrentEntityParents(entityId, 'ec_tag').then(() => {
            if (entityType) {
              doFetchCurrentEntitySubjectOf(entityId).then(() => doSetEntityLoading(false));
              doFetchEntityTypeSubjectOf(entityType.get('id'));
            }
          });
        });
      }, () => doSetEntityLoading(false));
    } else {
      doSetEntityLoading(false);
    }
  }

  toggleFinder() {
    const { isFinderCollapsed } = this.state;
    this.setState({ isFinderCollapsed: !isFinderCollapsed });
  }

  toggleSidebar() {
    const { isSidebarCollapsed } = this.state;
    this.setState({ isSidebarCollapsed: !isSidebarCollapsed });
  }

  showPrimaryPanel() {
    const { user, match: { params: { entityTypeSlug } } } = this.props;

    return !!(user && user.signedIn && entityTypeSlug);
  }

  showSecondaryPanel() {
    const { match: { params: { entityAction, childEntityTypeSlug, childEntityId } } } = this.props;

    const hasChildEntityReference = childEntityTypeSlug && childEntityId !== 'new';
    const hasSecondaryAction = entityAction && entityAction !== 'edit';

    return Boolean(hasChildEntityReference || hasSecondaryAction);
  }

  contentPadding() {
    return this.showPrimaryPanel() ? 140 : 0;
  }

  render() {
    const { match, userEntities, currentEntities, currentRelationships, entityPermissions, childEntityPermissions } = this.props;
    const { isFinderCollapsed, isSidebarCollapsed, isEntityLoadingInitiated } = this.state;

    const { entity, entityType } = this.getSelectedEntityAndType();
    const { childEntity, childEntityType } = this.getSelectedChildEntityAndType();

    const finderWidth = isFinderCollapsed ? 1 : 3;
    const sidebarWidth = isSidebarCollapsed ? 1 : 2;
    const contentWidth = 12 - finderWidth - sidebarWidth;

    if (!isEntityLoadingInitiated) return null;

    if (Record.isRecord(entity) && Record.isRecord(entityType) && entityType.entity_class === 'ec_redirect' && entity.redirect_id) {
      return <Redirect to={`/entities/${entity.redirect_id}`} />;
    }

    return (
      <NavigatorStyles>
        <FixedContainer className="row">
          <FinderContainer className={`col-xs-${finderWidth}`} style={{ width: `${(100 * finderWidth) / 12}%` }}>
            <Finder match={match} showPrimaryPanel={this.showPrimaryPanel()} toggleFinder={this.toggleFinder} collapsed={isFinderCollapsed} />
          </FinderContainer>

          <ActionPanelContainer className={`col-xs-offset-${finderWidth} col-xs-${contentWidth}`} style={{ width: `${(100 * contentWidth) / 12}%` }}>
            <ActionPanel
              match={match}
              entity={entity}
              entityType={entityType}
              currentEntities={currentEntities}
              currentRelationships={currentRelationships}
              entityPermissions={entityPermissions}
              childEntityPermissions={childEntityPermissions}
              showPrimaryPanel={this.showPrimaryPanel()}
              showSecondaryPanel={this.showSecondaryPanel()}
            />
          </ActionPanelContainer>

          <SidebarContainer className={`col-xs-offset-${finderWidth + contentWidth} col-xs-${sidebarWidth}`} style={{ width: `${(100 * sidebarWidth) / 12}%` }}>
            <Sidebar
              match={match}
              entity={entity}
              entityType={entityType}
              currentEntities={currentEntities}
              childEntityPermissions={childEntityPermissions}
              showPrimaryPanel={this.showPrimaryPanel()}
              toggleSidebar={this.toggleSidebar}
              collapsed={isSidebarCollapsed}
            />
          </SidebarContainer>
        </FixedContainer>

        <FixedBottomContainer className="row">
          <StatusContainer className={`col-xs-offset-${finderWidth} col-xs-${contentWidth}`} style={{ width: `${(100 * contentWidth) / 12}%` }}>
            <Status
              match={match}
              entity={entity}
              entityType={entityType}
              childEntity={childEntity}
              childEntityType={childEntityType}
            />
          </StatusContainer>
        </FixedBottomContainer>

        <ScrollContainer className="row" style={{ paddingTop: this.contentPadding() }}>
          <ContentContainer className={`col-xs-offset-${finderWidth} col-xs-${contentWidth}`} style={{ width: `${(100 * contentWidth) / 12}%` }}>
            <Content
              match={match}
              userEntities={userEntities}
              entity={entity}
              entityType={entityType}
              currentEntities={currentEntities}
              currentRelationships={currentRelationships}
            />
          </ContentContainer>
        </ScrollContainer>
      </NavigatorStyles>
    );
  }
}

export default NavigatorView;
