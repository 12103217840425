// Main entry point for foundation
import { PURGE } from 'redux-persist';
import { combineImmutableReducers } from '../../util';
import ACTIONS from '../../session/actions';

import SettingReducer from './setting_reducer';
import FormSettingReducer from './form_setting_reducer';

import InvitationReducer from './invitation_reducer';

import UserEntityReducer from './user_entity_reducer';
import UserEntitiesPermissionReducer from './user_entities_permission_reducer';
import UserRelationshipReducer from './user_relationship_reducer';

import CurrentEntityReducer from './current_entity_reducer';
import CurrentRelationshipReducer from './current_relationship_reducer';

import ListEntityReducer from './list_entity_reducer';
import ListRelationshipReducer from './list_relationship_reducer';
import ListEntityCountReducer from './list_entity_count_reducer';

import SelectedEntityReducer from './selected_entity_reducer';
import SelectedEntityTypeReducer from './selected_entity_type_reducer';
import SelectedEntityPermissionsReducer from './selected_entity_permissions_reducer';
import SelectedChildEntityPermissionsReducer from './selected_child_entity_permissions_reducer';

import ExportedEntityReducer from './exported_entity_reducer';

import EntityTypeReducer from './entity_type_reducer';

import PageReducer from './page_reducer';
import EventReducer from './event_reducer';
import DataSetReducer from './data_set_reducer';
import DataSetResponseReducer from './data_set_response_reducer';

const foundationReducer = combineImmutableReducers({
  settings: SettingReducer,
  formSettings: FormSettingReducer,
  invitations: InvitationReducer,
  userEntities: UserEntityReducer,
  userEntitiesPermissions: UserEntitiesPermissionReducer,
  userRelationships: UserRelationshipReducer,
  currentEntities: CurrentEntityReducer,
  currentRelationships: CurrentRelationshipReducer,
  listEntities: ListEntityReducer,
  listRelationships: ListRelationshipReducer,
  listEntitiesCount: ListEntityCountReducer,
  selectedEntity: SelectedEntityReducer,
  selectedEntityType: SelectedEntityTypeReducer,
  selectedEntityPermissions: SelectedEntityPermissionsReducer,
  selectedChildEntityPermissions: SelectedChildEntityPermissionsReducer,
  exportedEntities: ExportedEntityReducer,
  entityTypes: EntityTypeReducer,
  pages: PageReducer,
  events: EventReducer,
  dataSets: DataSetReducer,
  dataSetResponses: DataSetResponseReducer,
});

const Reducer = (state, action) => {
  if (action.type === ACTIONS.SIGN_OUT || action.type === PURGE) state = undefined; // eslint-disable-line no-param-reassign

  return foundationReducer(state, action);
};

export default Reducer;

export const getSettings = (state) => state.get('settings');
export const getFormSettings = (state) => state.get('formSettings');

export const getInvitation = (state, id) => InvitationReducer.selectors.getOne(state.get('invitations'), id);
export const getInvitations = (state, list) => InvitationReducer.selectors.getList(state.get('invitations'), list);

export const getUserEntity = (state, id) => UserEntityReducer.selectors.getOne(state.get('userEntities'), id);
export const getUserEntities = (state, list) => UserEntityReducer.selectors.getList(state.get('userEntities'), list);
export const getUserEntitiesLoadingStatus = (state) => UserEntityReducer.selectors.getLoadingStatus(state.get('userEntities'));

export const getUserEntitiesPermissions = (state) => state.get('userEntitiesPermissions');

export const getCurrentEntity = (state, id) => CurrentEntityReducer.selectors.getOne(state.get('currentEntities'), id);
export const getCurrentEntities = (state, list) => CurrentEntityReducer.selectors.getList(state.get('currentEntities'), list);
export const getCurrentEntitiesLoadingStatus = (state) => CurrentEntityReducer.selectors.getLoadingStatus(state.get('currentEntities'));

export const getListEntity = (state, id) => ListEntityReducer.selectors.getOne(state.get('listEntities'), id);
export const getListEntities = (state, list) => ListEntityReducer.selectors.getList(state.get('listEntities'), list);
export const getListEntitiesLoadingStatus = (state) => ListEntityReducer.selectors.getLoadingStatus(state.get('listEntities'));
export const getListEntitiesCount = (state) => state.get('listEntitiesCount');

export const getExportedEntitiesLoadingStatus = (state) => ExportedEntityReducer.selectors.getLoadingStatus(state.get('exportedEntities'));

export const getUserRelationships = (state, list) => UserRelationshipReducer.selectors.getList(state.get('userRelationships'), list);

export const getCurrentRelationships = (state, list) => CurrentRelationshipReducer.selectors.getList(state.get('currentRelationships'), list);

export const getListRelationships = (state, list) => ListRelationshipReducer.selectors.getList(state.get('listRelationships'), list);

export const getSelectedEntity = (state) => state.get('selectedEntity');
export const getSelectedEntityType = (state) => state.get('selectedEntityType');
export const getSelectedEntityPermissions = (state) => state.get('selectedEntityPermissions');
export const getSelectedChildEntityPermissions = (state) => state.get('selectedChildEntityPermissions');

export const getEntityTypes = (state, list) => EntityTypeReducer.selectors.getList(state.get('entityTypes'), list);
export const getEntityType = (state, id) => EntityTypeReducer.selectors.getOne(state.get('entityTypes'), id);
export const getEntityTypesLoadingStatus = (state) => EntityTypeReducer.selectors.getLoadingStatus(state.get('entityTypes'));

export const getPage = (state, id) => PageReducer.selectors.getOne(state.get('pages'), id);
export const getPages = (state, list) => PageReducer.selectors.getList(state.get('pages'), list);

export const getEvent = (state, id) => EventReducer.selectors.getOne(state.get('events'), id);
export const getEvents = (state, list) => EventReducer.selectors.getList(state.get('events'), list);

export const getDataSet = (state, id) => DataSetReducer.selectors.getOne(state.get('dataSets'), id);
export const getDataSets = (state, list) => DataSetReducer.selectors.getList(state.get('dataSets'), list);
export const getDataSetsLoadingStatus = (state) => DataSetReducer.selectors.getLoadingStatus(state.get('dataSets'));

export const getDataSetResponse = (state, id) => DataSetResponseReducer.selectors.getOne(state.get('dataSetResponses'), id);
export const getDataSetResponses = (state, list) => DataSetResponseReducer.selectors.getList(state.get('dataSetResponses'), list);
