import styled from 'styled-components';

const SidebarStyles = styled.div`
  margin-right: 1px;
`;

export const BackgroundContainer = styled.div`
`;

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.foundation.sidebar.backgroundColor};
  border: 1px solid ${(props) => props.theme.foundation.sidebar.borderColor};
  border-radius: 20px;
  height: 600px;
  overflow-y: scroll;
`;

export const Content = styled.div``;

export const Collapse = styled.div`
  div {
    margin-top: 90px;
    padding: 0 15px 15px;
    text-align: left;
  }

  div.sidebar-collapsed {
    text-align: center;
  }

  span {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.foundation.sidebar.borderColor};
    color: ${(props) => props.theme.foundation.sidebar.borderColor};
    display: inline-block;
    line-height: 2em;
    width: 2em;
    text-align: center;
    border-radius: 1em;
  }

  span:hover {
    border: 1px solid ${(props) => props.theme.foundation.sidebar.darkerBorderColor};
    color: ${(props) => props.theme.foundation.sidebar.darkerBorderColor};
  }
`;

export const ReferencedEntities = styled.div``;

export default SidebarStyles;
