import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/lib/immutable';

import Modal from '../modal';
import Button from '../button';
import { TextField } from '../../../common/form';

import InvitationModalStyles from './invitation_modal_styles';

const propTypes = {
  isOpen: PropTypes.bool,
  submitCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  pristine: false,
  invalid: false,
};

const InvitationModalView = ({ isOpen, submitCallback, closeCallback, handleSubmit, pristine, invalid }) => (
  <Modal isOpen={isOpen} closeModal={closeCallback} offset="20%">
    <InvitationModalStyles>
      <form onSubmit={handleSubmit(submitCallback)}>
        <div className="modal-header">
            Invite User
        </div>

        <div className="modal-body">
          <div className="col-xs-12">
            <TextField label="Email Address" type="email" name="email" required />
          </div>
        </div>

        <div className="modal-footer">
          <Button treatment="button" primary="true" disabled={pristine || invalid}>Invite User</Button>
        </div>
      </form>
    </InvitationModalStyles>
  </Modal>
);

InvitationModalView.propTypes = propTypes;
InvitationModalView.defaultProps = defaultProps;

export default reduxForm({ form: 'invitation', enableReinitialize: true })(InvitationModalView);
