import styled from 'styled-components';

const FinderStyles = styled.div`
  color: ${(props) => props.theme.foundation.finder.textColor};
  border: 1px solid ${(props) => props.theme.foundation.finder.borderColor};
  padding: 10px 0;
  margin-left: 1px;
  border-radius: 20px;

  .sidebar-collapsed .current-entity > div {
    width: 100%;
    padding: 0 20px;    
  }

  .sidebar-collapsed .section-header {
    font-size: 0.7em;
  }
`;

export const AvailableEntities = styled.div`
  height: 440px;
  overflow-y: scroll;
`;

export const CurrentEntity = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.foundation.finder.borderColor};
  height: 60px;
  font-size: 1.1em;

  > div {
    width: 90%;
    margin: 0 auto;
    height: 60px;
  }
`;

export const SectionHeader = styled.div`
  padding: 0 10px 10px;
  text-transform: uppercase;
  color: ${(props) => props.theme.foundation.finder.textColorLight};
  font-size: 0.8em;

  i {
    width: 1.2em;
  }
`;

export const EntityList = styled.div`
  padding: 0 20px;
`;

export const FinderBlock = styled.div`
`;

export const FinderTools = styled.div`
`;

export const Search = styled.div`
`;

export const Collapse = styled.div`
  cursor: pointer;
  padding: 15px 0;
  text-align: center;

  span {
    border: 1px solid ${(props) => props.theme.foundation.finder.borderColor};
    color: ${(props) => props.theme.foundation.finder.borderColor};
    display: inline-block;
    line-height: 2em;
    width: 2em;
    text-align: center;
    border-radius: 1em;
  }

  span:hover {
    border: 1px solid ${(props) => props.theme.foundation.finder.darkerBorderColor};
    color: ${(props) => props.theme.foundation.finder.darkerBorderColor};
  }
`;

export const Entity = styled.div`
  padding: 10px 0;
`;

export const EntityIcon = styled.div`
  a {
    display: inline-block;
    line-height: 2.2em;
    width: 2.2em;
    text-align: center;
    color: white;
    background-color: ${(props) => props.theme.foundation.finder.iconBackgroundColor};
    border-radius: 3px;
    text-decoration: none;
  }
`;

export const EntityDescription = styled.div`
`;

export const EntityType = styled.div`
  width: 100%;
  font-size: 0.7em;
  padding-bottom: 2px;
  color: ${(props) => props.theme.foundation.finder.textColorLight};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EntityName = styled.div`
  a {
    display: inline-block;
    width: 100%;
    font-weight: 600;
    color: ${(props) => props.theme.foundation.finder.textColor};
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
`;

export default FinderStyles;
