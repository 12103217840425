import { connect } from 'react-redux';

import SidebarView from './sidebar_view';
import { getCurrentUser, getDataSets, getDataSetResponses, getEntityType, getEntityTypeByEntityClass, getExportedEntitiesLoadingStatus,
  getUserEntitiesPermissions } from '../../../reducer';
import { listExportedEntities, createDataSetResponse } from '../../actions';

const mapStateToProps = (state, { match: { params: { childEntityId } } }) => ({
  childEntityId,
  user: getCurrentUser(state),
  userEntitiesManager: getUserEntitiesPermissions(state).get('manager'),
  dataSets: getDataSets(state),
  dataSetResponses: getDataSetResponses(state),
  exportedEntitiesLoading: getExportedEntitiesLoadingStatus(state),
  getEntityType: (id) => getEntityType(state, id),
  getEntityTypeByEntityClass: (entityTypeClass) => getEntityTypeByEntityClass(state, entityTypeClass),
});

const mapDispatchToProps = {
  listExportedEntities,
  createDataSetResponse,
};

const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(SidebarView);

export default SidebarContainer;
