import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Record, Map } from 'immutable';
import { reduxForm, Form } from 'redux-form/lib/immutable';

import { EditorField } from '../../../../common/form/redux_form';

import UserModel from '../../../../session/models/user_model';
import PageModel from '../../../models/page_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';

import PageEditStyles from './page_edit_styles';

class PageEditView extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.instanceOf(UserModel),
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    page: PropTypes.instanceOf(PageModel),
    pageForm: PropTypes.instanceOf(Map),
    handleSubmit: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
    user: null,
    page: null,
    pageForm: new Map(),
  }

  constructor(props) {
    super(props);

    this.updatePage = this.updatePage.bind(this);

    this.state = { submitCompleted: false };
  }

  updatePage() {
    const { user, id, pageForm, updatePage } = this.props;

    if (user) {
      updatePage(user.authToken, id, pageForm.get('values').get('content')).then(() => this.setState({ submitCompleted: true }));
    }
  }

  render() {
    const { entity, entityType, page, handleSubmit } = this.props;
    const { submitCompleted } = this.state;

    if (submitCompleted) {
      return <Redirect to={entityType.absoluteUrl(entity.id)} />;
    }

    return Record.isRecord(page) && (
      <PageEditStyles>
        <Form onSubmit={handleSubmit(this.updatePage)}>
          <EditorField name="content" defaultValue={page.content && page.content.toJS()} />
        </Form>
      </PageEditStyles>
    );
  }
}

export default reduxForm({ enableReinitialize: true })(PageEditView);
