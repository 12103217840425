import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';

import EntityModel from '../../../models/entity_model';

import PermissionsList from '../../permissions_list';

import PermissionsShowStyles from './permissions_show_styles';

const PermissionsShowView = ({ entity, currentEntities, currentRelationships }) => (
  <PermissionsShowStyles>
    <PermissionsList
      entity={entity}
      currentEntities={currentEntities}
      currentRelationships={currentRelationships}
    />
  </PermissionsShowStyles>
);

PermissionsShowView.propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  currentEntities: PropTypes.instanceOf(OrderedSet).isRequired,
  currentRelationships: PropTypes.instanceOf(OrderedSet).isRequired,
};

export default PermissionsShowView;
