import { connect } from 'react-redux';

import HierarchyProfileView from './hierarchy_profile_view';

import { getSettings, getCurrentUser, getUserEntities, getCurrentEntities, getDataSets, getDataSetResponses, getEntityType } from '../../../../../reducer';
import { fetchUserEntity, updateCurrentEntity } from '../../../../actions';

const mapStateToProps = (state, { entity, entityType }) => {
  const subjectOfEntityIds = getCurrentEntities(state).filter((e) => e.subject_id === entity.id).map((e) => e.id);
  const dataSetId = getDataSets(state).filter((ds) => ds.referenced_by === entityType.profile_data_set).map((ds) => ds.id).first();
  const profileReference = getDataSetResponses(state).filter((dsr) => dsr.data_set === dataSetId && subjectOfEntityIds.includes(dsr.referenced_by)).first();
  const profileEntity = getCurrentEntities(state).filter((e) => profileReference && e.id === profileReference.referenced_by).first();

  return {
    entityLoading: getSettings(state).get('entityLoading'),
    user: getCurrentUser(state),
    userEntityIds: getUserEntities(state).map((e) => e && e.id),
    profileEntity,
    profileReference,
    getEntityType: (id) => getEntityType(state, id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserEntity: (token, id) => dispatch(fetchUserEntity(token, id)),
  updateCurrentEntity: (token, id, entity) => dispatch(updateCurrentEntity(token, id, entity)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doFetchUserEntity: (id) => dispatchProps.fetchUserEntity(stateProps.user.authToken, id),
  doUpdateCurrentEntity: (id, name) => dispatchProps.updateCurrentEntity(stateProps.user.authToken, id, { name }),
});

const HierarchyProfileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(HierarchyProfileView);

export default HierarchyProfileContainer;
