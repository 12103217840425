import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Record } from 'immutable';

import Button from '../../../button';
import StyledLink from '../../../styled_link';

import EntityModel from '../../../../models/entity_model';
import EntityTypeModel from '../../../../models/entity_type_model';
import DataSetResponseModel from '../../../../models/data_set_response_model';

import HierarchyProfileStyles from './hierarchy_profile_styles';

class HierarchyProfileView extends React.Component {
  static propTypes = {
    entityLoading: PropTypes.bool,
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    userEntityIds: PropTypes.instanceOf(OrderedSet),
    canEditEntity: PropTypes.bool,
    profileEntity: PropTypes.instanceOf(EntityModel),
    profileReference: PropTypes.instanceOf(DataSetResponseModel),
    getEntityType: PropTypes.func.isRequired,
    doFetchUserEntity: PropTypes.func.isRequired,
    doUpdateCurrentEntity: PropTypes.func.isRequired,
  }

  static defaultProps = {
    entityLoading: true,
    userEntityIds: new OrderedSet(),
    canEditEntity: false,
    profileEntity: null,
    profileReference: null,
  }

  constructor(props) {
    super(props);

    this.onEntityNameKeyUp = this.onEntityNameKeyUp.bind(this);
    this.renderEntityName = this.renderEntityName.bind(this);

    this.state = { editingEntityName: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editingEntityName } = this.state;
    const prevEditingEntityName = prevState.editingEntityName;

    if (editingEntityName && !prevEditingEntityName && this.nameInputRef) this.nameInputRef.focus();
  }

  onEntityNameKeyUp(event) {
    const { entity, userEntityIds, doUpdateCurrentEntity, doFetchUserEntity } = this.props;

    if (event.key === 'Escape') {
      this.setState({ editingEntityName: false });
    } else if (event.key === 'Enter') {
      this.setState({ editingEntityName: false });
      doUpdateCurrentEntity(entity.id, this.nameInputRef.value).then(() => userEntityIds.includes(entity.id) && doFetchUserEntity(entity.id));
    }
  }

  renderEntityName() {
    const { entity, canEditEntity } = this.props;
    const { editingEntityName } = this.state;

    return (
      <div className={`entity-name ${canEditEntity ? 'entity-name-editable' : ''}`}>
        {!editingEntityName && (
          <Button
            custom
            onClick={() => canEditEntity && this.setState({ editingEntityName: true })}
            onKeyUp={(event) => event.key === 'Enter' && canEditEntity && this.setState({ editingEntityName: true })}
          >
            {entity.name}
            {canEditEntity && <i className="fas fa-pencil-alt" />}
          </Button>
        )}
        {editingEntityName && (
          <input
            ref={(ref) => { this.nameInputRef = ref; }}
            type="text"
            defaultValue={entity.name}
            onKeyUp={(event) => this.onEntityNameKeyUp(event)}
            onBlur={() => this.setState({ editingEntityName: false })}
          />
        )}
      </div>
    );
  }

  render() {
    const { entityLoading, entity, entityType, canEditEntity, profileEntity, profileReference, getEntityType } = this.props;

    const getResponseValue = (slug) => (profileReference ? profileReference.getDataSetFieldResponseBySlug(slug) : null);

    return Record.isRecord(entity) && (
      <HierarchyProfileStyles>
        <div className="row">
          <div className="col-md-8 col-xs-12 profile-left">
            <div className="profile-icon-container">
              <span className="profile-icon"><i className={`fa fa-4x fa-${entityType.icon}`} /></span>
            </div>
            <div className="profile-name-description">
              {this.renderEntityName()}
              <div className="profile-description">{getResponseValue('profile-about')}</div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12 profile-right">
            {canEditEntity && profileEntity && profileReference && (
              <div className="profile-edit">
                <StyledLink treatment="link" to={`${getEntityType(profileEntity.entity_type).absoluteUrl(profileEntity.id)}/edit`}>
                  <i className="fas fa-pencil-alt" />
                </StyledLink>
              </div>
            )}
            {!entityLoading && !profileEntity && !profileReference && entityType.profile_data_set && canEditEntity && (
              <div className="profile-create">
                <StyledLink treatment="button" secondary="true" to={`/entities/${entityType.profile_data_set}`}>
                  Create Profile
                </StyledLink>
              </div>
            )}
            {profileReference && (
              <div className="profile-details">
                <div>
                  <div className="profile-label">Email</div>
                  <div className="profile-value">{getResponseValue('contact-email')}</div>
                </div>
                <div>
                  <div className="profile-label">Phone</div>
                  <div className="profile-value">{getResponseValue('contact-phone')}</div>
                </div>
                <div>
                  <div className="profile-label">Website</div>
                  <div className="profile-value">
                    {getResponseValue('website') && (
                      <a href={getResponseValue('website')} target="_blank" rel="noopener noreferrer">{getResponseValue('website')}</a>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </HierarchyProfileStyles>
    );
  }
}

export default HierarchyProfileView;
