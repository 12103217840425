import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import EntityModel from '../models/entity_model';

const UserEntityReducer = new ResourcefulReducer('user_entities', EntityModel, {
  fetch: ACTIONS.FETCH_USER_ENTITY,
  list: ACTIONS.LIST_USER_ENTITIES,
  create: ACTIONS.CREATE_USER_ENTITY,
});

export default UserEntityReducer;
