import { connect } from 'react-redux';

import EntitiesListView from './entities_list_view';

import { getForm, getCurrentUser, getEntityType, getListEntity, getListEntities, getListEntitiesCount, getListRelationships } from '../../../reducer';

import { fetchListEntityParents, fetchListEntityChildren, clearListEntities, clearListRelationships } from '../../actions';

const mapStateToProps = (state, { entity }) => {
  const user = getCurrentUser(state);

  return {
    user,
    listEntities: getListEntities(state),
    listEntitiesCount: getListEntitiesCount(state),
    listRelationships: getListRelationships(state),
    entityType: entity && getEntityType(state, entity.entity_type),
    statusFormData: entity && getForm(state).get(`entity:${entity.id}:filter_status`),
    filterFormData: entity && getForm(state).get(`entity:${entity.id}:filter_advanced`),
    getListEntity: (id) => getListEntity(state, id),
    getEntityType: (id) => getEntityType(state, id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearListEntities: () => dispatch(clearListEntities()),
  clearListRelationships: () => dispatch(clearListRelationships()),
  fetchListEntityParents: (token, id, entityTypeClass, ids) =>
    dispatch(fetchListEntityParents(token, id, entityTypeClass, ids)),
  fetchListEntityChildren: (token, id, entityTypeClass, associated, page, size, sort, query, statuses, filters) =>
    dispatch(fetchListEntityChildren(token, id, entityTypeClass, associated, page, size, sort, query, statuses, filters)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doClearListEntities: () => dispatchProps.clearListEntities(),
  doClearListRelationships: () => dispatchProps.clearListRelationships(),
  doFetchListEntityParents: (id, entityTypeClass, ids) =>
    dispatchProps.fetchListEntityParents(stateProps.user.authToken, id, entityTypeClass, ids),
  doFetchListEntityChildren: (id, entityTypeClass, associated, page, size, sort, query, statuses, filters) =>
    dispatchProps.fetchListEntityChildren(stateProps.user.authToken, id, entityTypeClass, associated, page, size, sort, query, statuses, filters),
});

const EntitiesListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EntitiesListView);

export default EntitiesListContainer;
