import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import EntityModel from '../models/entity_model';

const reducer = new ResourcefulReducer('current_entities', EntityModel, {
  fetch: ACTIONS.FETCH_CURRENT_ENTITY,
  list: ACTIONS.LIST_CURRENT_ENTITIES,
  create: ACTIONS.CREATE_CURRENT_ENTITY,
});

const CurrentEntityReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_CURRENT_ENTITIES) state = undefined; // eslint-disable-line no-param-reassign

  return reducer(state, action);
};

CurrentEntityReducer.selectors = reducer.selectors;

export default CurrentEntityReducer;
