import { connect } from 'react-redux';

import DataSetShowView from './data_set_show_view';

import { getDataSets } from '../../../../reducer';

const mapStateToProps = (state, { entity }) => ({
  dataSet: getDataSets(state).filter((ds) => ds.referenced_by === entity.id).first(),
});

const DataSetShowContainer = connect(mapStateToProps)(DataSetShowView);

export default DataSetShowContainer;
