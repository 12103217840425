import React from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';

import EventMeta from './event_meta';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import EventModel from '../../../models/event_model';

import EventShowStyles, { Profile } from './event_show_styles';

const propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  canEditEntity: PropTypes.bool,
  event: PropTypes.instanceOf(EventModel),
};

const defaultProps = {
  canEditEntity: false,
  event: null,
};

const EventShowView = ({ event, entity, entityType, canEditEntity }) => Record.isRecord(event) && Record.isRecord(entity) && (
  <EventShowStyles>
    <Profile>
      <EventMeta event={event} entity={entity} entityType={entityType} canEditEntity={canEditEntity} />
    </Profile>
  </EventShowStyles>
);

EventShowView.propTypes = propTypes;
EventShowView.defaultProps = defaultProps;

export default EventShowView;
