import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet, List, Record } from 'immutable';
import ReactTable from 'react-table-v6';

import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import Modal from '../modal';
import Button from '../button';
import StyledLink from '../styled_link';

import PermissionsListStyles, { AddContainer, Table, Name, Icon, Tools } from './permissions_list_styles';

import { memberPermissionTypes, editorPermissionTypes, managerPermissionTypes } from '../../../reducer';

class PermissionsListView extends Component {
  static permissionNames = {
    administrator: 'Administrator',
    manager: 'Manager',
    editor: 'Editor',
    viewer: 'Member (Full)',
    member: 'Member (Limited)',
  };

  static propTypes = {
    entity: PropTypes.oneOfType([PropTypes.instanceOf(EntityModel), PropTypes.instanceOf(Map)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(EntityTypeModel), PropTypes.instanceOf(Map)]),
    currentEntities: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
    userEntitiesManager: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    userEntitiesAdmin: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    getEntityType: PropTypes.func.isRequired,
    hasCurrentEntityPermissionsManager: PropTypes.func.isRequired,
    hasCurrentEntityPermissionsAdmin: PropTypes.func.isRequired,
    hideFields: PropTypes.arrayOf(PropTypes.string),
    doCreateCurrentRelationship: PropTypes.func.isRequired,
    doUpdateCurrentRelationship: PropTypes.func.isRequired,
    doDeleteCurrentRelationship: PropTypes.func.isRequired,
    doFetchCurrentEntityChildren: PropTypes.func.isRequired,
    canManageEntity: PropTypes.bool,
  };

  static defaultProps = {
    entity: null,
    entityType: null,
    currentEntities: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    userEntitiesManager: new OrderedSet(),
    userEntitiesAdmin: new OrderedSet(),
    hideFields: [],
    canManageEntity: false,
  };

  static renderCellName = ({ original }) => {
    const entity = new EntityModel(original.entity);
    const entityType = new EntityTypeModel(original.entityType);

    const tableEntity = new EntityModel(original.tableEntity);
    const tableEntityType = new EntityTypeModel(original.tableEntityType);

    const url = (entityType.has_secondary_children ? entityType.absoluteUrl(entity.id) : '') + tableEntityType.absoluteUrl(tableEntity.id);

    return (
      <Name className="row">
        <div className="col-xs-2" style={{ textAlign: 'center' }}>
          <StyledLink treatment="link" to={url}>
            <Icon><i className={`fa fa-${tableEntityType.icon}`} /></Icon>
          </StyledLink>
        </div>
        <div className="col-xs-10">
          <StyledLink treatment="link" to={url}>{tableEntity.name}</StyledLink>
        </div>
      </Name>
    );
  }

  constructor(props) {
    super(props);

    this.state = { addEntityId: null, memberModalOpen: false, relationshipUpdating: false, relationshipMap: new Map() };

    this.updateRelationshipMap = this.updateRelationshipMap.bind(this);
    this.closeMemberModal = this.closeMemberModal.bind(this);
    this.createRelationship = this.createRelationship.bind(this);
    this.updateRelationship = this.updateRelationship.bind(this);
    this.tableEntities = this.tableEntities.bind(this);
    this.renderTools = this.renderTools.bind(this);
    this.renderPermissions = this.renderPermissions.bind(this);
    this.resolveData = this.resolveData.bind(this);
  }

  componentDidMount() {
    this.updateRelationshipMap();
  }

  componentDidUpdate(prevProps) {
    const { currentRelationships } = this.props;
    const prevCurrentRelationships = prevProps.currentRelationships;

    const relationshipsChanged = currentRelationships.size > 0 && currentRelationships.hashCode() !== prevCurrentRelationships.hashCode();
    if (relationshipsChanged) this.updateRelationshipMap();
  }

  updateRelationshipMap() {
    const { currentRelationships } = this.props;

    this.setState({ relationshipUpdating: false, relationshipMap: currentRelationships.toMap().mapEntries(([_k, v]) => [`${v.parent}:${v.child}`, v]) });
  }

  closeMemberModal() {
    this.setState({ memberModalOpen: false });
  }

  resolveData(data) {
    const { entity, userEntitiesAdmin, getEntityType, hideFields, hasCurrentEntityPermissionsManager, hasCurrentEntityPermissionsAdmin } = this.props;
    const { relationshipMap } = this.state;

    const includePermissions = !hideFields.includes('Permissions');
    const entityType = getEntityType(entity.entity_type);
    const hasEntityAdminPermissions = includePermissions && hasCurrentEntityPermissionsAdmin(entity);
    const hasEntityManagerPermissions = includePermissions && hasCurrentEntityPermissionsManager(entity);

    return data.map((tableEntity) => {
      const relationship = relationshipMap.get(`${entity.id}:${tableEntity.id}`);
      const tableEntityType = getEntityType(tableEntity.entity_type);

      const canManage = hasEntityManagerPermissions && relationship && !managerPermissionTypes.includes(relationship.permission_type);
      const canAdmin = hasEntityAdminPermissions;
      const isMyRelationship = includePermissions && userEntitiesAdmin.filter((searchEntity) => relationship &&
                                                                              (searchEntity.get('id') === relationship.child)).size > 0;

      return ({ entity, entityType, relationship, tableEntity, tableEntityType, canAdmin, canManage, isMyRelationship });
    }).filter((item) => item).toJS();
  }

  createRelationship() {
    const { entity, doCreateCurrentRelationship, doFetchCurrentEntityChildren } = this.props;
    const { addEntityId } = this.state;

    return doCreateCurrentRelationship({ permission_type: 'member', parent: entity.id, child: addEntityId })
      .then(() => (this.closeMemberModal() || doFetchCurrentEntityChildren(entity.id)));
  }

  updateRelationship(e, original) {
    const { doUpdateCurrentRelationship } = this.props;

    if (original.isMyRelationship && original.relationship.permission_type === 'administrator' &&
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm('Changing this permission level may result in loss of access. Continue?')) {
      return Promise.reject();
    }

    this.setState({ relationshipUpdating: true });

    return doUpdateCurrentRelationship(original.relationship.id, { permission_type: e.currentTarget.value });
  }

  tableEntities() {
    const { entity, currentEntities, getEntityType } = this.props;
    const { relationshipMap } = this.state;

    return currentEntities.filter((currentEntity) => {
      const relationship = relationshipMap.get(`${entity.id}:${currentEntity.id}`);
      return relationship && editorPermissionTypes.includes(relationship.permission_type) &&
        Record.isRecord(entity) && Record.isRecord(currentEntity) &&
        entity.id !== currentEntity.id && getEntityType(currentEntity.entity_type).entity_class === 'ec_hierarchy';
    });
  }

  renderTools({ original }) {
    const { doDeleteCurrentRelationship } = this.props;

    // eslint-disable-next-line no-restricted-globals,no-alert
    const handleDelete = () => confirm('Delete this relationship, are you sure?') && original.relationship &&
          doDeleteCurrentRelationship(original.relationship.id);

    if (original.canAdmin || original.canManage) {
      return <Tools><Button treatment="link" onClick={handleDelete}><i className="fas fa-trash" /></Button></Tools>;
    }

    return null;
  }

  renderPermissions({ original }) {
    const { relationshipUpdating } = this.state;

    if (original.relationship && !original.canAdmin && !original.canManage) {
      return <Tools>{original.relationship.permission_type}</Tools>;
    }

    let permissionTypes = memberPermissionTypes;
    if (!original.canAdmin) permissionTypes = memberPermissionTypes.subtract(managerPermissionTypes);

    return original.relationship && (
      <Tools>
        <select onChange={(e) => this.updateRelationship(e, original)} disabled={relationshipUpdating} className={relationshipUpdating ? 'updating' : ''}>
          {permissionTypes.reverse().map((type) => (
            <option key={type} value={type} selected={original.relationship.permission_type === type}>
              {PermissionsListView.permissionNames[type]}
            </option>
          ))}
        </select>
      </Tools>
    );
  }

  render() {
    const { entity, entityType, getEntityType, userEntitiesManager, hideFields, canManageEntity } = this.props;
    const { addEntityId, memberModalOpen } = this.state;

    const tableEntities = this.tableEntities();
    const tableEntitiesIds = tableEntities.map((e) => e.id);

    if (tableEntities.size === 0) return null;

    const columns = [
      { Header: 'Name', accessor: 'tableEntity.name', Cell: PermissionsListView.renderCellName },
    ];

    if (!hideFields.includes('Permissions')) {
      columns.push({ Header: 'Permissions', Cell: this.renderPermissions });
    }

    // only show tools if there's something to be edited
    if (!hideFields.includes('Permissions')) columns.push({ Header: '', minWidth: 25, Cell: this.renderTools });

    const defaultPageSize = 20;
    const showPagination = tableEntities.size > defaultPageSize;

    const filteredAddOptions = userEntitiesManager.filter((userEntityManager) => {
      const userEntityType = getEntityType(userEntityManager.get('entity_type'));
      return Record.isRecord(userEntityType) && userEntityType.entity_class === 'ec_hierarchy' &&
        userEntityType.ordinality < entityType.ordinality && userEntityManager.get('id') !== entity.id &&
        !tableEntitiesIds.includes(userEntityManager.get('id'));
    });

    return (
      <PermissionsListStyles className="row">
        <Table className="col-xs-12">
          <ReactTable
            minRows={1}
            defaultPageSize={defaultPageSize}
            showPagination={showPagination}
            data={tableEntities}
            resolveData={this.resolveData}
            columns={columns}
            defaultSorted={[{ id: 'tableEntity.name' }]}
            TheadComponent={() => null}
          />
        </Table>
        {canManageEntity && (
          <AddContainer>
            <Button
              treatment="button"
              primary="true"
              thin="true"
              onClick={() => this.setState({ memberModalOpen: true })}
              disabled={filteredAddOptions.size === 0}
            >
              Add Member
            </Button>

            <Modal isOpen={memberModalOpen} closeModal={this.closeMemberModal} offset="25%">
              <div className="modal-header">
                Add Member
              </div>
              <div className="modal-body">
                <div className="col-xs-12">
                  Member
                </div>
                <div className="col-xs-12">
                  <select onChange={(e) => this.setState({ addEntityId: e.currentTarget.value })}>
                    <option value="">Select one...</option>
                    {filteredAddOptions.sortBy((userEntity) => userEntity.get('name')).map((userEntity) => (
                      <option key={userEntity.get('id')} value={userEntity.get('id')}>
                        {userEntity.get('name')}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xs-12">
                  <Button treatment="button" primary="true" thin="true" disabled={!addEntityId} onClick={this.createRelationship}>Add Member</Button>
                </div>
              </div>
            </Modal>
          </AddContainer>
        )}
      </PermissionsListStyles>
    );
  }
}


export default PermissionsListView;
