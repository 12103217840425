import React from 'react';
import PropTypes from 'prop-types';
import { Record } from 'immutable';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';

import Button from '../../../button';

import EntityModel from '../../../../models/entity_model';
import EntityTypeModel from '../../../../models/entity_type_model';
import EventModel from '../../../../models/event_model';

import EventMetaStyles from './event_meta_styles';

class EventMetaView extends React.Component {
  static propTypes = {
    event: PropTypes.instanceOf(EventModel).isRequired,
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    canEditEntity: PropTypes.bool,
    doUpdateCurrentEntity: PropTypes.func.isRequired,
  }

  static defaultProps = {
    canEditEntity: false,
  }

  constructor(props) {
    super(props);

    this.onEntityNameKeyUp = this.onEntityNameKeyUp.bind(this);
    this.showMoreDescription = this.showMoreDescription.bind(this);
    this.renderEntityName = this.renderEntityName.bind(this);

    this.state = { editingEntityName: false, showMoreDescription: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editingEntityName } = this.state;
    const prevEditingEntityName = prevState.editingEntityName;

    if (editingEntityName && !prevEditingEntityName && this.nameInputRef) this.nameInputRef.focus();
  }

  onEntityNameKeyUp(event) {
    const { entity, doUpdateCurrentEntity } = this.props;

    if (event.key === 'Escape') {
      this.setState({ editingEntityName: false });
    } else if (event.key === 'Enter') {
      this.setState({ editingEntityName: false });
      doUpdateCurrentEntity(entity.id, this.nameInputRef.value);
    }
  }

  showMoreDescription() {
    this.setState({ showMoreDescription: true });
  }

  renderEntityName() {
    const { entity, canEditEntity } = this.props;
    const { editingEntityName } = this.state;

    return (
      <div className={`entity-name ${canEditEntity ? 'entity-name-editable' : ''}`}>
        {!editingEntityName && (
          <Button
            custom
            onClick={() => canEditEntity && this.setState({ editingEntityName: true })}
            onKeyUp={(event) => event.key === 'Enter' && canEditEntity && this.setState({ editingEntityName: true })}
          >
            {entity.name}
            {canEditEntity && <i className="fas fa-pencil-alt" />}
          </Button>
        )}
        {editingEntityName && (
          <input
            ref={(ref) => { this.nameInputRef = ref; }}
            type="text"
            defaultValue={entity.name}
            onKeyUp={(event) => this.onEntityNameKeyUp(event)}
            onBlur={() => this.setState({ editingEntityName: false })}
          />
        )}
      </div>
    );
  }

  render() {
    const { event, entity, entityType } = this.props;
    const { showMoreDescription } = this.state;

    if (!Record.isRecord(event) || !Record.isRecord(entity)) return null;

    return (
      <EventMetaStyles>
        <div className="row">
          <div className="col-md-6 col-xs-12 event-header-container">
            <div className="entity-icon-container">
              <span className="entity-icon"><i className={`fa fa-4x fa-${entityType.icon}`} /></span>
            </div>
            <div className="event-details-container">
              <div className="entity-name-container">
                {this.renderEntityName()}
              </div>
              {event.start_date && (event.start_date === event.end_date) && (
                <div className="event-datetime-container">
                  <div>
                    <p>{moment(event.start_date).format('dddd MMMM Do, YYYY')}</p>
                    {!event.all_day && (
                      <p>
                        {moment(`${event.start_date} ${event.start_time}`).format('h:mma')}
                        &nbsp;&ndash;&nbsp;
                        {moment(`${event.end_date} ${event.end_time}`).format('h:mma')}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {event.start_date && (event.start_date !== event.end_date) && (
                <div className="event-datetime-container">
                  <div>
                    <p>{moment(event.start_date).format('MMM Do, YYYY')}</p>
                    {!event.all_day && <p>{moment(`${event.start_date} ${event.start_time}`).format('h:mma')}</p>}
                  </div>
                  <div className="event-datetime-dash">&ndash;</div>
                  <div>
                    <p>{moment(event.end_date).format('MMM Do, YYYY')}</p>
                    {!event.all_day && <p>{moment(`${event.end_date} ${event.end_time}`).format('h:mma')}</p>}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-xs-12 event-location-container">
            <p>Location</p>
            <a href={`https://www.google.com/maps?q=${encodeURIComponent(event.location)}`} target="_blank" rel="noopener noreferrer">{event.location}</a>
          </div>
          {event.description && (
            <div className="col-xs-12 event-description-container">
              <div
                className={`event-description-content ${showMoreDescription ? 'showing-more' : ''}`}
                dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                  __html: draftToHtml(event.description.toJS()),
                }}
              />
              <div className={`event-show-more-container ${showMoreDescription ? 'showing-more' : ''}`}>
                <Button treatment="button" thin onClick={this.showMoreDescription}>Show More</Button>
              </div>
            </div>
          )}
        </div>
      </EventMetaStyles>
    );
  }
}

export default EventMetaView;
