import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { reduxForm } from 'redux-form/lib/immutable';

import { SelectField } from '../../../../common/form';

import EntitiesListStatusStyles from './entities_list_status_styles';

class EntitiesListStatusView extends React.Component {
  static propTypes = {
    statuses: PropTypes.instanceOf(List),
    handleSubmit: PropTypes.func.isRequired,
    onChangeCallback: PropTypes.func.isRequired,
  }

  static defaultProps = {
    statuses: new List(),
  }

  render() {
    const { statuses, handleSubmit, onChangeCallback } = this.props;

    return (
      <EntitiesListStatusStyles>
        <form onSubmit={handleSubmit(() => null)}>
          <SelectField isMulti label="" placeholder="Filter by status..." name="status_filters" options={statuses} onChange={onChangeCallback} />
        </form>
      </EntitiesListStatusStyles>
    );
  }
}
export default reduxForm({ enableReinitialize: true })(EntitiesListStatusView);
