import React from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';
import logo from '../assets/images/error-logo.png';
import theme from '../common/theme';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    persistor: PropTypes.shape({ purge: PropTypes.func.isRequired, flush: PropTypes.func.isRequired }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      rollbar: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN && new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        codeVersion: process.env.REACT_APP_GIT_VERSION,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.NODE_ENV,
        },
      }),
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: `${error}` };
  }

  componentDidCatch(error, info) {
    const { rollbar } = this.state;

    try {
      // log to Rolllbar if we can...
      if (rollbar) rollbar.error('Exception rendering React component', error, info);
    } catch (e) {
      // if rollbar fails, log to console
      console.log('Error Boundary', e); // eslint-disable-line no-console
      console.log('Error Boundary', error); // eslint-disable-line no-console
    }
  }

  render() {
    const { children, persistor } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // purge the persistor in production to reset the user's state
      if (process.env.NODE_ENV === 'production') {
        persistor.purge().then(() => persistor.flush());
      }

      // You can render any custom fallback UI
      return (
        <div>
          <div style={{ borderBottom: theme.color.grayLighter, backgroundColor: theme.color.primary, padding: 15 }}>
            <img src={logo} alt="logo" />
          </div>
          <div style={{ margin: '20px auto', width: '90%' }}>
            We are sorry, but we seem to be having technical difficulties. Our developers have been notified, but you might also want to try:
            <ul>
              <li><a href="/">Refreshing the page</a></li>
              <li>Clearing your web browser&apos;s cache (instructions vary by web browser)</li>
              <li>Trying a different web browser (i.e. Chrome, Safari, Firefox, Edge)</li>
            </ul>
          </div>
          <pre style={{ display: 'none' }}>{hasError}</pre>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
