import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import moment from 'moment';
import ReactTable from 'react-table-v6';

import Button from '../button';

import InvitationsListStyles, { Table, InvitationEmail, InvitationStatus, InvitationDate, Tools } from './invitations_list_styles';

class InvitationsListView extends React.Component {
  static propTypes = {
    invitations: PropTypes.instanceOf(OrderedSet),
    deleteInvitation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    invitations: new OrderedSet(),
  };

  static renderEmail = ({ value, original: { invitation } }) => (
    <InvitationEmail className={invitation.is_expired ? 'invitation-expired' : ''}>
      {value}
    </InvitationEmail>
  );

  static renderStatus = ({ original: { invitation } }) => (
    <InvitationStatus className={invitation.is_expired ? 'invitation-expired' : ''}>
      {invitation.invitation_accepted_at && (
        <span title={`Accepted On: ${moment(invitation.invitation_accepted_at).format('MMM D, YYYY')}`}>
          Accepted
        </span>
      )}
      {invitation.is_expired && !invitation.invitation_accepted_at && (
        <span title={`Expired On: ${moment(invitation.invitation_expires_at).format('MMM D, YYYY')}`}>
          Expired
        </span>
      )}
      {!invitation.is_expired && !invitation.invitation_accepted_at && (
        <span title={`Expires On: ${moment(invitation.invitation_expires_at).format('MMM D, YYYY')}`}>
          Pending
        </span>
      )}
    </InvitationStatus>
  );

  static renderDate = ({ value, original: { invitation } }) => (
    <InvitationDate className={invitation.is_expired ? 'invitation-expired' : ''}>
      <span>{moment(value).format('MMM D, YYYY')}</span>
    </InvitationDate>
  );

  constructor(props) {
    super(props);

    this.renderTools = this.renderTools.bind(this);
  }

  renderTools({ original }) {
    const { deleteInvitation } = this.props;

    // eslint-disable-next-line no-restricted-globals,no-alert
    const handleDelete = () => confirm('Delete this invitation, are you sure?') && original.invitation &&
          deleteInvitation(original.invitation.id);

    return <Tools><Button treatment="link" onClick={handleDelete}><i className="fas fa-trash" /></Button></Tools>;
  }

  render() {
    const { invitations } = this.props;

    const columns = [
      { Header: 'Email', accessor: 'invitation.email', Cell: InvitationsListView.renderEmail },
      { Header: 'Status', Cell: InvitationsListView.renderStatus },
      { Header: <div style={{ textAlign: 'right' }}>Invited</div>, accessor: 'invitation.created_at', Cell: InvitationsListView.renderDate },
      { Header: '', minWidth: 25, Cell: this.renderTools },
    ];

    return (
      <InvitationsListStyles className="row">
        <Table className="col-xs-12">
          <ReactTable
            minRows={1}
            defaultPageSize={20}
            data={invitations}
            resolveData={(data) => data.map((invitation) => ({ invitation })).toJS()}
            columns={columns}
            defaultSorted={[{ id: 'invitation.created_at', desc: true }]}
          />
        </Table>
      </InvitationsListStyles>
    );
  }
}

export default InvitationsListView;
