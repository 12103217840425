import React from 'react';
import { Link } from 'react-router-dom';
import IntroductionStyles from './introduction_styles';

const IntroductionView = () => (
  <IntroductionStyles className="row center-xs">
    <div className="col-xs-11 col-md-8">
      <div className="box">
        <h3>JOIN OUR COMMUNITY</h3>

        <div>
          By <Link to="/register">Signing Up</Link> and completing your <Link to="/data_sets/fQhFe3gvwZCeYOYo">Founder Profile</Link>,
          you will be eligible for a variety of
          Capital Opportunities which we create and curate across North America. This includes our
          annual $20K Venture Challenge in NY & DC along with other programs, events and private
          network engagements with corporate partners and investors.
        </div>
      </div>
    </div>
  </IntroductionStyles>
);

export default IntroductionView;
