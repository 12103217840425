import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import DataSetModel from '../models/data_set_model';

const DataSetReducer = new ResourcefulReducer('data_sets', DataSetModel, {
  fetch: ACTIONS.FETCH_DATA_SET,
  list: ACTIONS.LIST_DATA_SETS,
});

export default DataSetReducer;
