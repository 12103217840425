import { connect } from 'react-redux';

import { getForm } from '../../../reducer';

import SubjectOwnerModalView from './subject_owner_modal_view';

const mapStateToProps = (state) => ({
  form: 'subjectOwnerForm',
  subjectOwnerForm: getForm(state).get('subjectOwnerForm'),
});

const SubjectOwnerModalContainer = connect(mapStateToProps)(SubjectOwnerModalView);

export default SubjectOwnerModalContainer;
