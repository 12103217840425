import { connect } from 'react-redux';

import ActionPanelView from './action_panel_view';

import { getCurrentEntity, getListEntity, getUserEntitiesPermissions, getEntityType } from '../../../reducer';

const mapStateToProps = (state, { entityPermissions, childEntityPermissions,
  match: { params: { entityTypeSlug, entityAction, childEntityId, childEntityAction } } }) => {
  const childEntity = childEntityId !== 'new' && (getCurrentEntity(state, childEntityId) || getListEntity(state, childEntityId));
  const childEntityType = childEntity && getEntityType(state, childEntity.entity_type);

  return {
    entityTypeSlug,
    entityAction,
    childEntityId,
    childEntity,
    childEntityType,
    childEntityAction,
    userEntitiesManager: getUserEntitiesPermissions(state).get('manager'),
    isEntityMember: entityPermissions.get('member'),
    canViewEntity: entityPermissions.get('viewer'),
    canEditEntity: entityPermissions.get('editor'),
    canManageEntity: entityPermissions.get('manager'),
    canViewChildEntity: childEntityPermissions.get('viewer'),
    canEditChildEntity: childEntityPermissions.get('editor'),
    canManageChildEntity: childEntityPermissions.get('manager'),
    getEntityType: (id) => getEntityType(state, id),
  };
};

const ActionPanelContainer = connect(mapStateToProps)(ActionPanelView);

export default ActionPanelContainer;
