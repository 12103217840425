import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CollapseableSectionStyles, { Title, Body } from './collapseable_section_styles';

const CollapseableSectionView = ({ collapsed, toggleSidebar, title, children }) => {
  const [sectionExpanded, setSectionExpanded] = useState(false);

  return (
    <CollapseableSectionStyles>
      <Title onClick={() => { setSectionExpanded(!sectionExpanded); if (collapsed) toggleSidebar(); }}>
        <i className={`fa ${sectionExpanded ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
        <span>{title}</span>
      </Title>
      <Body className={sectionExpanded && !collapsed ? 'body-expanded' : ''}>
        {children}
      </Body>
    </CollapseableSectionStyles>
  );
};

CollapseableSectionView.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

CollapseableSectionView.defaultProps = {
  title: '',
  children: undefined,
};


export default CollapseableSectionView;
