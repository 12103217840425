import { connect } from 'react-redux';

import DataSetResponseCreateView from './data_set_response_create_view';

import { getCurrentUser, getUserEntitiesPermissions, getUserEntities, getCurrentEntity, getEntityType, getDataSets,
  getDataSetResponse } from '../../../../reducer';

import { createDataSetResponse } from '../../../actions';

const mapStateToProps = (state, { entity }) => ({
  user: getCurrentUser(state),
  userEntities: getUserEntities(state),
  userEntitiesManager: getUserEntitiesPermissions(state).get('manager'),
  dataSet: getDataSets(state).filter((dataSet) => dataSet.referenced_by === entity.id).first(),
  getDataSetResponse: (id) => getDataSetResponse(state, id),
  getCurrentEntity: (id) => getCurrentEntity(state, id),
  getEntityType: (id) => getEntityType(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  createDataSetResponse: (token, dataSetId, subject, owner, status) => dispatch(createDataSetResponse(token, dataSetId, subject, owner, status)),
});

const DataSetResponseCreateContainer = connect(mapStateToProps, mapDispatchToProps)(DataSetResponseCreateView);

export default DataSetResponseCreateContainer;
