import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { FieldArray, reduxForm } from 'redux-form/lib/immutable';

import EntityModel from '../../../models/entity_model';
import DataSetModel from '../../../models/data_set_model';

import { SelectField, TextField } from '../../../../common/form';

import Button from '../../button';

import EntitiesListFilterStyles from './entities_list_filter_styles';

class EntitiesListFilterView extends React.Component {
  static propTypes = {
    fieldsObject: PropTypes.oneOfType([PropTypes.instanceOf(EntityModel), PropTypes.instanceOf(DataSetModel), PropTypes.instanceOf(Map)]),
    formData: PropTypes.instanceOf(Map),
    onChangeCallback: PropTypes.func.isRequired,
    onClearCallback: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    fieldsObject: new Map(),
    formData: new Map(),
  }

  // eslint-disable-next-line new-cap
  static scalarOptions = List([{ value: 'contains', label: 'Contains' },
    { value: 'starts', label: 'Starts With' },
    { value: 'ends', label: 'Ends With' },
    { value: 'exactly', label: 'Is Exactly' },
    { value: 'greater', label: 'Greater Than' },
    { value: 'less', label: 'Less Than' }]);

  // eslint-disable-next-line new-cap
  static arrayOptions = List([{ value: 'includes_all', label: 'Includes All' },
    { value: 'includes_any', label: 'Includes Any' },
    { value: 'excludes_all', label: 'Excludes All' },
    { value: 'excludes_any', label: 'Excludes Any' }]);

  constructor(props) {
    super(props);

    this.getFieldBySelectionIndex = this.getFieldBySelectionIndex.bind(this);
    this.getValue = this.getValue.bind(this);
    this.fieldOptions = this.fieldOptions.bind(this);
    this.renderFilters = this.renderFilters.bind(this);

    this.state = { addedInitialField: false };
  }

  getFieldBySelectionIndex(idx) {
    const { fieldsObject } = this.props;
    const id = this.getValue(idx, 'field') && this.getValue(idx, 'field').value;
    return fieldsObject.get('fields').filter((field) => field.get('id') === id).first();
  }

  getValue(idx, key) {
    const { formData } = this.props;

    return formData.get('values').get('advanced_filters').get(idx).get(key);
  }

  fieldOptions() {
    const { fieldsObject } = this.props;
    return fieldsObject.get('fields').filter((field) => field.get('field_type') !== 'content')
      .sortBy((field) => field.get('sort_order')).map((item) => ({ value: item.get('id'), label: item.get('name') }));
  }

  renderFilters({ fields }) {
    const { onChangeCallback, onClearCallback } = this.props;
    const { addedInitialField } = this.state;

    if (fields.length === 0) {
      if (addedInitialField) {
        onClearCallback();
      } else {
        fields.push(new Map());
        this.setState({ addedInitialField: true });
      }
    }

    return (
      <React.Fragment>
        {fields.map((item, index) => {
          const field = this.getFieldBySelectionIndex(index);

          return (
            <div className="filter-item col-xs-12 row" key={item}>
              <div className="filter-field col-xs-3">
                <SelectField
                  manageValue
                  options={this.fieldOptions()}
                  label=""
                  name={`${item}.field`}
                  value={this.getValue(index, 'field')}
                />
              </div>
              <div className="filter-comparison col-xs-3">
                {/* these are separate to force a re-mount of the component to reset the options selection */}

                {field && field.get('options') && (
                  <SelectField
                    manageValue
                    options={EntitiesListFilterView.arrayOptions}
                    label=""
                    name={`${item}.comparison`}
                    value={this.getValue(index, 'comparison')}
                  />
                )}
                {field && !field.get('options') && (
                  <SelectField
                    manageValue
                    options={EntitiesListFilterView.scalarOptions}
                    label=""
                    name={`${item}.comparison`}
                    value={this.getValue(index, 'comparison')}
                  />
                )}
              </div>
              <div className="filter-search col-xs-4">
                {field && !field.get('options') && (
                  <TextField type="text" label="" name={`${item}.scalarSearch`} required={false} onChange={onChangeCallback} />
                )}
                {field && field.get('options') && (
                  <SelectField
                    manageValue
                    options={field.get('options')}
                    isMulti
                    label=""
                    name={`${item}.arraySearch`}
                    value={this.getValue(index, 'arraySearch')}
                    onChange={onChangeCallback}
                  />
                )}
              </div>
              <div className="filter-trash col-xs-2">
                <Button className="trash" treatment="link" onClick={() => { fields.remove(index); onChangeCallback(); }}>
                  <i className="fa fa-trash" />
                </Button>
                <Button className="add" treatment="link" onClick={() => fields.push(new Map())}>
                  <i className="fa fa-plus" />
                </Button>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <EntitiesListFilterStyles className="col-xs-12 row">
        <form onSubmit={handleSubmit(() => null)} className="col-xs-12 row">
          <FieldArray name="advanced_filters" component={this.renderFilters} />
        </form>
      </EntitiesListFilterStyles>
    );
  }
}

export default reduxForm({ enableReinitialize: true })(EntitiesListFilterView);
