import styled from 'styled-components';

const EventMetaStyles = styled.div`
  color: ${(props) => props.theme.foundation.content.textColor};
  margin-bottom: 10px;

  .entity-name {
    min-height: 20px;
    margin-bottom: 10px;

    button {
      font-size: 1.3rem;
      text-align: left;
      padding: 5px 0;
      border: 1px dashed transparent;
      width: 90%;
      color: ${(props) => props.theme.foundation.content.textColor};
    }

    input {
      font-size: 1.3rem;
      width: 90%;
      color: ${(props) => props.theme.foundation.content.textColor};
      border: 1px solid ${(props) => props.theme.foundation.content.borderColor};
    }

    &.entity-name-editable {
      button {
        i {
          display: none;
          position: absolute;
          right: 2px;
          bottom: 5px;
          color: ${(props) => props.theme.foundation.content.textColorLight};
          font-size: 0.8em;
          margin-left: 12px;
        }

        &:hover {
          border: 1px dashed ${(props) => props.theme.foundation.content.borderColor};
          text-decoration: none;

          i {
            display: inline-block;
          }
        }
      }
    }
  }

  .event-header-container {
    padding: 10px;
    text-align: left;

    .entity-icon-container {
      float: left;

      span.entity-icon {
        display: inline-block;
        background-color: ${(props) => props.theme.foundation.content.iconBackgroundColor};
        color: white;
        border-radius: 10px;
        padding: 10px;
        margin-right: 15px;
      }
    }

    .event-details-container {
      padding: 0 0 0 100px;
    }

    .event-datetime-container {
      display: flex;
      justify-content: start;

      .event-datetime-dash {
        padding: 0 10px;
      }

      p {
        margin: 0;
        padding: 0 0 5px 0;
      }
    }
  }

  .event-location-container {
    padding: 10px;

    p {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      margin: 0;
      padding: 0 0 5px 0;
    }
  }

  .event-description-container {
    position: relative;
    padding: 10px;

    .event-show-more-container {
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      text-align: center;

      button {
        background-color: white;
      }

      .user-signedout &, &.showing-more {
        display: none;
      }
    }

    .event-description-content {
      max-height: 3.5em;
      padding: 0 5px;
      overflow: hidden;
      border-top: 1px solid #EEE;
      border-bottom: 1px solid #CCC;
      box-shadow: inset 0px -5px 5px -5px #AAA;

      .user-signedout &, &.showing-more {
        max-height: none;
        overflow: visible;
        border-bottom: 0 none;
        box-shadow: none;
      }

      & *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export default EventMetaStyles;
