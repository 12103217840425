import styled from 'styled-components';

const NavigatorStyles = styled.div``;

export const FixedContainer = styled.div`
  width: 100%;
`;

export const FixedBottomContainer = styled.div`
  width: 100%;
`;

export const StatusContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 500;
`;

export const ScrollContainer = styled.div`
  width: 100%;
`;

export const FinderContainer = styled.div`
  position: fixed;
  z-index: 500;
`;

export const ActionPanelContainer = styled.div`
  position: fixed;
  z-index: 500;
`;

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 500;
`;

export const ContentContainer = styled.div``;

export default NavigatorStyles;
