import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map, List } from 'immutable';
import logo from '../../../assets/images/logo-nav.svg';
import HeaderStyles, { TopBar, WrapperLogo, Logo, RightMenu, MenuItem, Name, Icon } from './header_styles';
import UserModel from '../../../session/models/user_model';

const HeaderView = ({ user }) => {
  const entityUids = new List(user.get('entityUids'));

  return (
    <HeaderStyles>
      <TopBar>
        <WrapperLogo>
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>
        </WrapperLogo>
        {user && user.signedIn && (
          <RightMenu>
            <MenuItem>
              <Name>{user.email}</Name>
            </MenuItem>
            {entityUids.size > 0 && (
              <MenuItem>
                <Link to={`/individuals/${entityUids.first()}`}><Icon><i className="fas fa-user" /></Icon></Link>
              </MenuItem>
            )}
            <MenuItem>
              <Link to="/signout"><Icon><i className="fas fa-sign-out-alt" /></Icon></Link>
            </MenuItem>
          </RightMenu>
        )}
        {(!user || !user.signedIn) && (
          <RightMenu>
            <MenuItem>
              <Link to="/signin"><Name>Sign In</Name></Link>
            </MenuItem>
          </RightMenu>
        )}
      </TopBar>
    </HeaderStyles>
  );
};

HeaderView.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(UserModel), PropTypes.instanceOf(Map)]),
};

HeaderView.defaultProps = {
  user: null,
};

export default HeaderView;
