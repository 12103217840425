import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import SignInStyles from './sign_in_styles';
import { TextField } from '../../../common/form';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import { OAUTH } from '../../../api';
import Introduction from '../introduction';

const propTypes = {
  openAccountCreation: PropTypes.bool,
  standalone: PropTypes.bool,
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
    }),
  }).isRequired,
};

const defaultProps = {
  openAccountCreation: false,
  standalone: true,
  pristine: false,
  invalid: false,
};

const oauthButton = (provider, returnTo) => (
  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-2x`} />}
  </a>
);

const DEFAULT_RETURN_TO = '/';

const SignInView = ({ openAccountCreation, standalone, signedIn, handleSubmit, pristine, invalid, location: { state } }) => {
  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  const oauthRow = (
    <div className="oauth-row">
      <div>Sign in with:</div>
      {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
    </div>
  );

  return (
    <SignInStyles className={standalone ? 'container' : ''}>
      {standalone && <Introduction />}
      <div className="row center-xs">
        <div className={`col-xs-11 ${standalone ? 'col-md-5' : ''}`}>
          <div className="box">
            <form onSubmit={onSubmit}>
              <h3>Sign In</h3>
              <TextField label="Email" type="text" name="email" fullWidth required />
              <TextField label="Password" type="password" name="password" fullWidth required />
              <input type="submit" className="btn" value="Login" disabled={pristine || invalid || submitting} />
              <span className="status">
                {failed && <span className="error">Invalid credentials</span>}
                {submitting && <Spinner />}
              </span>
              {OAUTH.providers.size > 0 && openAccountCreation && oauthRow}
              <p>
                <Link to={{ pathname: '/forgot', state: { returnTo } }}>Reset your password</Link>
                {openAccountCreation && (
                  <React.Fragment>
                    <span style={{ padding: '0 1em' }}>|</span>
                    <Link to={{ pathname: '/register', state: { returnTo } }}>Create an account</Link>
                  </React.Fragment>
                )}
              </p>
            </form>
          </div>
        </div>
      </div>
    </SignInStyles>
  );
};

SignInView.propTypes = propTypes;
SignInView.defaultProps = defaultProps;

export default SignInView;
