import { connect } from 'react-redux';
import { Record, merge } from 'immutable';

import DataSetResponseEditView from './data_set_response_edit_view';

import { getForm, getCurrentUser, getDataSets, getDataSetResponses } from '../../../../reducer';

import { updateDataSetResponse } from '../../../actions';

const mapStateToProps = (state, { childEntity }) => {
  const dataSetResponse = getDataSetResponses(state).filter((dsr) => dsr.referenced_by === childEntity.id).first();
  const dataSet = dataSetResponse && getDataSets(state).filter((ds) => ds.id === dataSetResponse.data_set).first();
  const id = dataSetResponse && dataSetResponse.id;
  const fields = dataSet && merge({}, ...dataSet.fields
    .map((field) => new Map([[field.get('id'), (Record.isRecord(dataSetResponse) && dataSetResponse.getDataSetFieldResponse(field))]])));
  const form = `entity:${childEntity.id}`;

  return {
    user: getCurrentUser(state),
    dataSet,
    dataSetResponse,
    id,
    form,
    dataSetResponseForm: getForm(state).get(form),
    initialValues: { fields },
  };
};

const mapDispatchToProps = (dispatch) => ({
  // TODO: similar to the full form, we should be able to save drafts and complete vs. update here as well, so this status
  // shouldn't actually be hardcoded
  updateDataSetResponse: (token, id, values) => dispatch(updateDataSetResponse(token, id, 0, values)),
});

const DataSetResponseEditContainer = connect(mapStateToProps, mapDispatchToProps)(DataSetResponseEditView);

export default DataSetResponseEditContainer;
