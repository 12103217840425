import Axios from 'axios';
import { fromJS } from 'immutable';

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000';

const headers = { 'Content-Type': 'application/json', Accept: 'application/json' };
if (document.head.querySelector('meta[name="csrf-token"]')) {
  headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').content;
}

const axios = Axios.create({
  baseURL: ENDPOINT,
  responseType: 'json',
  headers,
});

const headersBlob = { 'Content-Type': 'application/octet-stream', Accept: 'application/octet-stream' };
if (document.head.querySelector('meta[name="csrf-token"]')) {
  headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').content;
}

const axiosBlob = Axios.create({
  baseURL: ENDPOINT,
  responseType: 'blob',
  headersBlob,
});

const tokenAuth = (token) => (token ? `Bearer ${token}` : '');

const oauthOptions = []; // ['linkedin', 'facebook', 'google', 'twitter'];
if (process.env.NODE_ENV !== 'production') {
  oauthOptions.push('developer');
}

const buildOauthOrigin = (returnTo) => {
  const endpoint = window.location.href
    .split('/')
    .slice(0, 3)
    .join('/');
  const returnToPath = returnTo || window.location.pathname;
  const origin = `${endpoint}/finish_signin?returnTo=${encodeURIComponent(returnToPath)}`;
  return encodeURIComponent(origin);
};

export const OAUTH = {
  providers: fromJS(oauthOptions),
  getUrl: (provider, returnTo) => `${ENDPOINT}/auth/${provider}?origin=${buildOauthOrigin(returnTo)}`,
};

export const signIn = (email, password) => axios.post('/v1/auth', { email, password }, { withCredentials: true });
export const finishSignIn = () => axios.post('/v1/auth', undefined, { withCredentials: true });
export const refreshToken = (user) => axios.patch('/v1/auth', undefined, { headers: { Authorization: tokenAuth(user.authToken) } });
export const register = (user) => axios.post('/v1/user', { user }, { withCredentials: true });
export const resetPassword = (token, password) => axios.post('/v1/user/reset_password', { token, password });
export const confirmEmail = (token) => axios.post('/v1/user/confirm_email', { token });
export const forgotPassword = (email) => axios.post('/v1/user/forgot_password', { email });

export const updateCurrentUser = (token, user) => axios.patch('/v1/user', { user }, { headers: { Authorization: tokenAuth(token) } });

export const listWidgets = () => axios.get('/widgets');
export const updateWidget = (uid, props) => axios.patch(`/widgets/${uid}`, { widget: props });

export const listGlobalSettings = () => axios.get('/v1/global_settings');

export const listInvitations = (token) => axios.get('/v1/invitations', { headers: { Authorization: tokenAuth(token) } });
export const createInvitation = (token, invitation) => axios.post('/v1/invitations', { invitation }, { headers: { Authorization: tokenAuth(token) } });
export const deleteInvitation = (token, id) => axios.delete(`/v1/invitations/${id}`, { headers: { Authorization: tokenAuth(token) } });

export const fetchEntity = (token, id) => axios.get(`/v1/entities/${id}`, { headers: { Authorization: tokenAuth(token) } });
export const fetchEntityAncestors = (token, id) => axios.get(`/v1/entities/${id}/ancestors`, { headers: { Authorization: tokenAuth(token) } });
export const fetchEntityParents = (token, id, entityTypeClass, ids) =>
  axios.get(`/v1/entities/${id}/parents`, { params: { entity_class: entityTypeClass, ids }, headers: { Authorization: tokenAuth(token) } });
export const fetchEntityChildren = (token, id, entityTypeClass, associated, page, size, sort, query, statuses, filters) =>
  axios.get(`/v1/entities/${id}/children`, { params: { entity_class: entityTypeClass, associated, page, size, sort, query, statuses, filters },
    headers: { Authorization: tokenAuth(token) } });
export const fetchEntityReference = (token, id) => axios.get(`/v1/entities/${id}/reference`, { headers: { Authorization: tokenAuth(token) } });
export const fetchEntitySubject = (token, id) => axios.get(`/v1/entities/${id}/subject`, { headers: { Authorization: tokenAuth(token) } });
export const fetchEntitySubjectOf = (token, id) => axios.get(`/v1/entities/${id}/subject_of`, { headers: { Authorization: tokenAuth(token) } });
export const listEntities = (token, entityTypeSlug, page, size) =>
  axios.get('/v1/entities', { params: { entity_type: entityTypeSlug, page, size }, headers: { Authorization: tokenAuth(token) } });
export const createEntity = (token, entity) => axios.post('/v1/entities', { entity }, { headers: { Authorization: tokenAuth(token) } });
export const updateEntity = (token, id, entity) => axios.patch(`/v1/entities/${id}`, { entity },
  { headers: { Authorization: tokenAuth(token) } });

export const listExportedEntities = (token, id, entityTypeClass) =>
  axiosBlob.get(`/v1/entities/${id}/children`, { params: { entity_class: entityTypeClass, associated: true, export: 'csv' },
    headers: { Authorization: tokenAuth(token) } });

export const createRelationship = (token, relationship) => axios.post('/v1/relationships', { relationship }, { headers: { Authorization: tokenAuth(token) } });
export const updateRelationship = (token, id, relationship) => axios.patch(`/v1/relationships/${id}`,
  { relationship }, { headers: { Authorization: tokenAuth(token) } });
export const deleteRelationship = (token, id) => axios.delete(`/v1/relationships/${id}`, { headers: { Authorization: tokenAuth(token) } });

export const listEntityTypes = (token) => axios.get('/v1/entity_types', { headers: { Authorization: tokenAuth(token) } });
export const fetchEntityTypeSubjectOf = (token, id) => axios.get(`/v1/entity_types/${id}/subject_of`, { headers: { Authorization: tokenAuth(token) } });

export const updatePage = (token, id, content) => axios.patch(`/v1/pages/${id}`, { page: { content } }, { headers: { Authorization: tokenAuth(token) } });

export const updateEvent = (token, id, event) => axios.patch(`/v1/events/${id}`, { event }, { headers: { Authorization: tokenAuth(token) } });

export const createDataSetResponse = (token, dataSetId, subject, owner, status, dataSetFieldResponses) => axios.post('/v1/data_set_responses',
  { data_set_response: { data_set: dataSetId, subject, owner, status, data_set_field_responses: dataSetFieldResponses } },
  { headers: { Authorization: tokenAuth(token) } });
export const updateDataSetResponse = (token, id, status, dataSetFieldResponses) => axios.patch(`/v1/data_set_responses/${id}`,
  { data_set_response: { status, data_set_field_responses: dataSetFieldResponses } },
  { headers: { Authorization: tokenAuth(token) } });
