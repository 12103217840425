import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet, Record } from 'immutable';
import { Redirect } from 'react-router-dom';

import Modal from '../modal';
import SignIn from '../../../session/components/sign_in';

import InvitationsShow from './invitations_show';

import DirectoryShow from './directory_show';
import DashboardShow from './dashboard_show';

import PageShow from './page_show';
import PageEdit from './page_edit';
import EventShow from './event_show';
import EventEdit from './event_edit';
import TagShow from './tag_show';
import HierarchyShow from './hierarchy_show';
import DataSetShow from './data_set_show';
import DataSetResponseNew from './data_set_response_new';
import DataSetResponseShow from './data_set_response_show';
import DataSetResponseEdit from './data_set_response_edit';

import UserModel from '../../../session/models/user_model';
import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import ContentStyles, { DirectoryStyles, DashboardStyles, ModalStyles } from './content_styles';

class ContentView extends React.Component {
  static propTypes = {
    entityLoading: PropTypes.bool,
    modalOn4XX: PropTypes.bool,
    user: PropTypes.instanceOf(UserModel),
    subject: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })]),
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    userEntities: PropTypes.instanceOf(OrderedSet),
    currentEntities: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
    entityTypeSlug: PropTypes.string,
    entityId: PropTypes.string,
    entityAction: PropTypes.string,
    childEntityTypeSlug: PropTypes.string,
    childEntityId: PropTypes.string,
    canEditEntity: PropTypes.bool,
  }

  static defaultProps = {
    entityLoading: true,
    modalOn4XX: false,
    user: null,
    subject: {},
    entity: null,
    entityType: null,
    userEntities: new OrderedSet(),
    currentEntities: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    entityTypeSlug: null,
    entityId: null,
    entityAction: null,
    childEntityTypeSlug: null,
    childEntityId: null,
    canEditEntity: false,
  }

  static specialEntityTypeSlugs = {
    invitations: InvitationsShow,
  };

  constructor(props) {
    super(props);

    this.state = { isOpen: true };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { entityLoading, modalOn4XX, user, userEntities, entity, entityTypeSlug, entityId, entityType, currentEntities, currentRelationships, entityAction,
      childEntityTypeSlug, childEntityId, canEditEntity, subject } = this.props;
    const { isOpen } = this.state;

    const isStatelessForm = entityAction === 'new' || childEntityId === 'new';
    const isSpecialtySlug = Object.prototype.hasOwnProperty.call(ContentView.specialEntityTypeSlugs, entityTypeSlug);
    const subjectId = subject && (Map.isMap(subject) ? subject.get('value') : subject.value);

    if (Record.isRecord(entity) && Record.isRecord(entityType)) {
      if ((!user || !user.signedIn) && entity.visibility === 'v_global' && entityType.entity_class === 'ec_data_set' && !isStatelessForm) {
        return <Redirect to={`${entityType.absoluteUrl(entity.id)}/responses/new`} />;
      }

      return (
        <ContentStyles>
          {entityType.entity_class === 'ec_tag' && (
            <TagShow entity={entity} entityType={entityType} currentRelationships={currentRelationships} />
          )}
          {entityType.entity_class === 'ec_page' && entityAction !== 'edit' && (
            <PageShow entity={entity} entityType={entityType} canEditEntity={canEditEntity} />
          )}
          {entityType.entity_class === 'ec_page' && entityAction === 'edit' && canEditEntity && (
            <PageEdit entity={entity} entityType={entityType} />
          )}
          {entityType.entity_class === 'ec_event' && entityAction !== 'edit' && (
            <EventShow entity={entity} entityType={entityType} canEditEntity={canEditEntity} />
          )}
          {entityType.entity_class === 'ec_event' && entityAction === 'edit' && canEditEntity && (
            <EventEdit entity={entity} entityType={entityType} />
          )}
          {entityType.entity_class === 'ec_hierarchy' && (
            <HierarchyShow entity={entity} entityType={entityType} canEditEntity={canEditEntity} currentRelationships={currentRelationships} />
          )}
          {entityType.entity_class === 'ec_data_set' && !childEntityTypeSlug && (
            <DataSetShow entity={entity} entityType={entityType} currentRelationships={currentRelationships} />
          )}
          {entityType.entity_class === 'ec_data_set' && isStatelessForm && (
            <DataSetResponseNew entity={entity} entityType={entityType} subjectId={subjectId} />
          )}
          {entityType.entity_class === 'ec_data_set_response' && entityAction !== 'edit' && (
            <DataSetResponseShow entity={entity} entityType={entityType} />
          )}
          {entityType.entity_class === 'ec_data_set_response' && entityAction === 'edit' && canEditEntity && (
            <DataSetResponseEdit entity={entity} entityType={entityType} />
          )}
        </ContentStyles>
      );
    }

    if (entityTypeSlug && !entityId && user && user.signedIn && isSpecialtySlug) {
      const Component = ContentView.specialEntityTypeSlugs[entityTypeSlug];
      return <Component currentEntities={currentEntities} />;
    }

    if (entityTypeSlug && !entityId && !isSpecialtySlug) {
      return (
        <DirectoryStyles>
          <DirectoryShow entityTypeSlug={entityTypeSlug} currentEntities={currentEntities} />
        </DirectoryStyles>
      );
    }

    if (user && user.signedIn && !entityTypeSlug && !entityId) {
      return (
        <DashboardStyles>
          <DashboardShow userEntities={userEntities} />
        </DashboardStyles>
      );
    }

    if ((!user || !user.signedIn) && isOpen && modalOn4XX) {
      return (
        <Modal isOpen={isOpen} closeModal={this.handleClose} offset="20%">
          <ModalStyles>
            <SignIn standalone={false} location={{}} />
          </ModalStyles>
        </Modal>
      );
    }

    if ((!user || !user.signedIn) && (!isOpen || !modalOn4XX) && !entityLoading) {
      return <Redirect to="/signout" />;
    }

    return null;
  }
}

export default ContentView;
