import actions from '../actions';

const DEFAULT_STATE = null;

function SelectedEntityReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.SET_SELECTED_ENTITY:
      return action.entity;
    default:
      return state;
  }
}

export default SelectedEntityReducer;
