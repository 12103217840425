import * as API from '../api';
import { genericAPIActionCreator, genericAPIActionTypes } from '../util';

const ACTIONS = {
  SET_GLOBAL_SETTINGS: 'SET_GLOBAL_SETTINGS',
  LIST_GLOBAL_SETTINGS: genericAPIActionTypes('ListGlobalSettings'),
};

export function setGlobalSettings(settings = {}) {
  return {
    type: ACTIONS.SET_GLOBAL_SETTINGS,
    settings,
  };
}

export const listGlobalSettings = () => genericAPIActionCreator(() => API.listGlobalSettings(),
  ACTIONS.LIST_GLOBAL_SETTINGS, 'Unable to list global settings');

export default ACTIONS;
