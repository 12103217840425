import { Record, Map } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  referenced_by: null,
  status: null,
  created_at: null,
  updated_at: null,
  data_set: null,
  field_responses: [],
  responses: [],
};

class DataSetResponseModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::DataSetResponse';

  static filterOptions(options, selected) {
    const responseValues = selected.filter((item) => item).map((item) => item.get('value'));
    return options.filter((option) => option && responseValues.includes(option.get('value')));
  }

  getResponseBySlug(slug) {
    const response = this.responses.find((r) => r.get('field_slug') === slug);

    return response && response.get('scalar_response');
  }

  getFieldResponseBySlug(slug) {
    const fieldResponse = this.field_responses.find((fr) => fr.get('field_slug') === slug);

    return fieldResponse && fieldResponse.get('scalar_response');
  }

  getDataSetFieldResponseBySlug(slug) {
    return this.getResponseBySlug(slug) || this.getFieldResponseBySlug(slug);
  }

  getResponse(field) {
    const response = this.responses.find((r) => r.get('field_id') === field.get('id'));

    if (response && field.get('options')) {
      return response.get('array_response') ? DataSetResponseModel.filterOptions(field.get('options'), response.get('array_response')) : [];
    }

    return response && response.get('scalar_response');
  }

  getFieldResponse(field) {
    const fieldResponse = this.field_responses.find((fr) => fr.get('field_id') === field.get('id'));

    if (fieldResponse && field.get('options')) {
      return fieldResponse.get('array_response') ? DataSetResponseModel.filterOptions(field.get('options'), fieldResponse.get('array_response')) : [];
    }

    return fieldResponse && fieldResponse.get('scalar_response');
  }

  getDataSetFieldResponse(field) {
    return this.getResponse(field) || this.getFieldResponse(field);
  }

  hasDataSetFieldResponse(field) {
    return this.responses.find((r) => r.get('field_id') === field.get('id')) ||
      this.field_responses.find((r) => r.get('field_id') === field.get('id'));
  }

  getResponseAttribute(field, attr) {
    const response = this.responses.find((r) => r.get('field_id') === field.get('id'));
    return response && response.get(attr);
  }

  getFieldResponseAttribute(field, attr) {
    const fieldResponse = this.field_responses.find((fr) => fr.get('field_id') === field.get('id'));
    return fieldResponse && fieldResponse.get(attr);
  }

  getDataSetFieldResponseAttribute(field, attr) {
    return this.getResponseAttribute(field, attr) || this.getFieldResponseAttribute(field, attr);
  }

  asValues(fields) {
    return new Map(fields.map((field) => [field.get('id'), this.getDataSetFieldResponse(field)]));
  }
}

export default DataSetResponseModel;
