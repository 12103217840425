import { Map } from 'immutable';
import actions from '../actions';

const DEFAULT_STATE = new Map();

function FormSettingReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.SAVE_WITH_STATUS:
      return state.merge({ status: action.status });
    case '@@redux-form/DESTROY':
    case '@@router/LOCATION_CHANGE':
      return DEFAULT_STATE;
    default:
      return state;
  }
}

export default FormSettingReducer;
