const textColor = '#16325C';
const textColorLight = '#54698D';
const textColorLighter = '#7489AD';
const linkColor = '#16325C';
const backgroundColor = '#F4F6F9';
const borderColor = '#D8D8D8';
const darkerBorderColor = '#B0B0B0';

const themeBackgroundColorPrimary = 'rgb(255, 112, 98)';
const themeBackgroundColorSecondary = 'white';
const themeTextColorPrimary = 'white';
const themeTextColorSecondary = textColorLight;

const iconBackgroundColor = '#979797';

const theme = {
  foundation: {
    backgroundColor: 'white',
    themeBackgroundColorPrimary,
    themeBackgroundColorSecondary,
    textColor,
    textColorLight,
    textColorLighter,
    linkColor,

    modal: {
      textColor,
      textColorLight,
      borderColor,
    },
    button: {
      themeBackgroundColorPrimary,
      themeBackgroundColorSecondary,
      themeTextColorPrimary,
      themeTextColorSecondary,
    },
    finder: {
      textColor,
      textColorLight,
      borderColor,
      darkerBorderColor,
      iconBackgroundColor,
    },
    actionPanel: {
      textColor,
      borderColor,
      darkerBorderColor,
      backgroundColor,
      iconBackgroundColor,
    },
    sidebar: {
      textColor,
      textColorLight,
      backgroundColor,
      borderColor,
      darkerBorderColor,
    },
    content: {
      textColor,
      textColorLight,
      backgroundColor,
      borderColor,
      iconBackgroundColor,
    },
    status: {
      textColor,
      textColorLight,
      backgroundColor,
      borderColor,
    },
    tabBar: {
      borderColor,
      darkerBorderColor,
    },
    entitiesList: {
      textColor,
      textColorLight,
      backgroundColor,
      borderColor,
      iconBackgroundColor,
    },
    permissionsList: {
      textColor,
      textColorLight,
      backgroundColor,
      borderColor,
      iconBackgroundColor,
    },
  },
};

export default theme;
