import { connect } from 'react-redux';

import EntitiesListStatusView from './entities_list_status_view';

const mapStateToProps = (state, { entity }) => {
  const form = `entity:${entity.id}:filter_status`;

  return {
    form,
  };
};

const mapDispatchToProps = () => ({
});

const EntitiesListStatusContainer = connect(mapStateToProps, mapDispatchToProps)(EntitiesListStatusView);

export default EntitiesListStatusContainer;
