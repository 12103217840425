import { Record } from 'immutable';

const DEFAULT_STATE = {
  id: undefined,
  email: undefined,
  authToken: undefined,
  entityUids: undefined,
  entityCreator: undefined,
  inviter: undefined,
};

class UserModel extends Record(DEFAULT_STATE) {
  static _name = 'Session::UserModel';

  get signedIn() {
    return !!this.authToken;
  }
}

export default UserModel;
