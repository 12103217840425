import { connect } from 'react-redux';

import PermissionsListView from './permissions_list_view';

import { getCurrentUser, getEntityType, getSelectedEntityPermissions, hasCurrentEntityPermissions, managerPermissionTypes, administratorPermissionTypes,
  getUserEntitiesPermissions } from '../../../reducer';
import { createCurrentRelationship, updateCurrentRelationship, deleteCurrentRelationship, fetchCurrentEntityChildren } from '../../actions';

const mapStateToProps = (state, { entity }) => {
  const user = getCurrentUser(state);
  const userEntitiesPermissions = getUserEntitiesPermissions(state);
  const entityPermissions = getSelectedEntityPermissions(state);
  const userEntitiesManager = userEntitiesPermissions.get('manager');
  const userEntitiesAdmin = userEntitiesPermissions.get('administrator');

  return {
    user,
    userEntitiesManager,
    userEntitiesAdmin,
    entityType: entity && getEntityType(state, entity.entity_type),
    canManageEntity: entityPermissions.get('manager'),
    getEntityType: (id) => getEntityType(state, id),
    hasCurrentEntityPermissionsManager: (target) =>
      userEntitiesManager && hasCurrentEntityPermissions(state, target, userEntitiesManager, managerPermissionTypes),
    hasCurrentEntityPermissionsAdmin: (target) =>
      userEntitiesAdmin && hasCurrentEntityPermissions(state, target, userEntitiesAdmin, administratorPermissionTypes),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCurrentRelationship: (token, relationship) => dispatch(createCurrentRelationship(token, relationship)),
  updateCurrentRelationship: (token, id, relationship) => dispatch(updateCurrentRelationship(token, id, relationship)),
  deleteCurrentRelationship: (token, id) => dispatch(deleteCurrentRelationship(token, id)),
  fetchCurrentEntityChildren: (token, id) => dispatch(fetchCurrentEntityChildren(token, id)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doCreateCurrentRelationship: (relationship) => dispatchProps.createCurrentRelationship(stateProps.user.authToken, relationship),
  doUpdateCurrentRelationship: (id, relationship) => dispatchProps.updateCurrentRelationship(stateProps.user.authToken, id, relationship),
  doDeleteCurrentRelationship: (id) => dispatchProps.deleteCurrentRelationship(stateProps.user.authToken, id),
  doFetchCurrentEntityChildren: (id) => dispatchProps.fetchCurrentEntityChildren(stateProps.user.authToken, id),
});

const PermissionsListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PermissionsListView);

export default PermissionsListContainer;
