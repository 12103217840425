import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { List, Map, OrderedSet, Record } from 'immutable';

import Button from '../button';
import StyledLink from '../styled_link';
import VisibilityModal from '../visibility_modal';
import AddToModal from '../add_to_modal';

import DataSetResponseCreate from './data_set_response_create';
import DataSetResponseShow from './data_set_response_show';
import DataSetResponseEdit from './data_set_response_edit';
import DataSetResponseStatus from '../data_set_response_status';

import PermissionsShow from './permissions_show';

import HierarchyCreate from './hierarchy_create';
import HierarchyShow from './hierarchy_show';

import InvitationCreate from './invitation_create';

import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import ActionPanelStyles, { Container, Header, Inset, SidebarExtension, SecondaryPanel, SecondaryPanelClose, SecondaryPanelInset,
  SecondaryPanelTitle, Icon, Name, EntityActions, EntityActionsAddTo } from './action_panel_styles';

class ActionPanelView extends React.Component {
  static propTypes = {
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    entityTypeSlug: PropTypes.string,
    entityAction: PropTypes.string,
    childEntityId: PropTypes.string,
    childEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    childEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    childEntityAction: PropTypes.string,
    currentEntities: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
    userEntitiesManager: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.instanceOf(OrderedSet)]),
    showPrimaryPanel: PropTypes.bool,
    showSecondaryPanel: PropTypes.bool,
    isEntityMember: PropTypes.bool,
    canViewEntity: PropTypes.bool,
    canEditEntity: PropTypes.bool,
    canManageEntity: PropTypes.bool,
    canViewChildEntity: PropTypes.bool,
    canEditChildEntity: PropTypes.bool,
    canManageChildEntity: PropTypes.bool,
    getEntityType: PropTypes.func.isRequired,
  };

  static defaultProps = {
    entity: null,
    entityType: null,
    entityTypeSlug: null,
    entityAction: null,
    childEntityId: null,
    childEntity: null,
    childEntityType: null,
    childEntityAction: null,
    currentEntities: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    userEntitiesManager: new OrderedSet(),
    showPrimaryPanel: false,
    showSecondaryPanel: false,
    isEntityMember: false,
    canViewEntity: false,
    canEditEntity: false,
    canManageEntity: false,
    canViewChildEntity: false,
    canEditChildEntity: false,
    canManageChildEntity: false,
  };

  constructor(props) {
    super(props);

    this.closeVisibilityModal = this.closeVisibilityModal.bind(this);
    this.closeAddToModal = this.closeAddToModal.bind(this);
    this.members = this.members.bind(this);
    this.renderEntityHeader = this.renderEntityHeader.bind(this);
    this.renderEntityActions = this.renderEntityActions.bind(this);
    this.renderSecondaryPanel = this.renderSecondaryPanel.bind(this);

    this.state = { isVisibilityModalOpen: false, isAddToModalOpen: false };
  }

  closeVisibilityModal() {
    this.setState({ isVisibilityModalOpen: false });
  }

  closeAddToModal() {
    this.setState({ isAddToModalOpen: false });
  }

  members() {
    const { entity, currentEntities, currentRelationships } = this.props;

    const memberRelationships = currentRelationships.filter((r) => Record.isRecord(entity) && r.parent === entity.id && r.permission_type !== 'associated');

    return currentEntities.map((currentEntity) => {
      const relationships = memberRelationships.filter((r) => Record.isRecord(entity) && r.parent === entity.id && r.child === currentEntity.id);
      return relationships.size > 0 ? currentEntity : null;
    }).filter((item) => item);
  }

  renderEntityHeader() {
    const { entity, entityType, entityTypeSlug, entityAction, childEntityId, isEntityMember, canViewEntity, canEditEntity, canManageEntity } = this.props;
    const { isVisibilityModalOpen } = this.state;

    const members = this.members();
    const isStatelessForm = entityAction === 'new' || childEntityId === 'new';

    if (Record.isRecord(entity) && Record.isRecord(entityType)) {
      return (
        <Inset className="row">
          <div className="col-md-6 col-xs-12 entity-create">
            {entityType.entity_class === 'ec_data_set' && childEntityId !== 'new' && <DataSetResponseCreate entity={entity} entityType={entityType} />}

            {entityType.entity_class === 'ec_data_set_response' && <DataSetResponseStatus entity={entity} />}

            {entityType.entity_class === 'ec_hierarchy' && canManageEntity && entityType.child_entity_type && (
              <HierarchyCreate entity={entity} entityType={entityType} />
            )}

            {entityType.entity_class === 'ec_hierarchy' && !isEntityMember && entity.join_data_set_id && (
              <StyledLink treatment="button" primary="true" to={`/entities/${entity.join_data_set_id}/responses/new`}>
                Join {entity.name}
              </StyledLink>
            )}
          </div>

          <div className="col-md-6 col-xs-12 entity-tools">
            {['ec_data_set_response', 'ec_page', 'ec_event'].includes(entityType.entity_class) && entityAction !== 'edit' && canEditEntity && (
              <StyledLink treatment="button" secondary="true" thin="true" to={`${entityType.absoluteUrl(entity.id)}/edit`}>
                <i className="fas fa-edit" /> Edit
              </StyledLink>
            )}

            {!isStatelessForm && canViewEntity && members.size > 0 && (
              <StyledLink treatment="button" secondary="true" thin="true" to={`${entityType.absoluteUrl(entity.id)}/permissions`}>
                <i className={`fas fa-user${members.size > 1 ? 's' : ''}-cog`} /> Permissions
              </StyledLink>
            )}

            {!isStatelessForm && canViewEntity && (
              <Button treatment="button" secondary="true" thin="true" onClick={() => canManageEntity && this.setState({ isVisibilityModalOpen: true })}>
                <i className={`fas fa-${entity.visibilityIcon()}`} title={entity.visibilityName()} /> Visibility
              </Button>
            )}

            {canManageEntity && isVisibilityModalOpen && <VisibilityModal entity={entity} entityType={entityType} closeModal={this.closeVisibilityModal} />}
          </div>
        </Inset>
      );
    }

    if (entityTypeSlug && !Record.isRecord(entity)) {
      return (
        <Inset className="row">
          <div className="col-md-6 col-xs-12">
            {entityTypeSlug === 'invitations' && <InvitationCreate />}
          </div>
        </Inset>
      );
    }

    return null;
  }

  renderSecondaryPanelDataSetResponse() {
    const { entity, entityType, childEntity, childEntityType, childEntityAction, canViewChildEntity, canEditChildEntity, canManageChildEntity } = this.props;

    const editUrl = `${entityType.absoluteUrl(entity.id) + childEntityType.absoluteUrl(childEntity.id)}/edit`;
    const permissionsUrl = `${childEntityType.absoluteUrl(childEntity.id)}/permissions`;

    return (
      <React.Fragment>
        <SecondaryPanelTitle>
          <i className={`fa fa-${childEntityType.icon}`} />
          <span>{childEntity.name}</span>
          {childEntityAction !== 'edit' && canEditChildEntity && (<StyledLink treatment="link" to={editUrl}><i className="fas fa-edit" /></StyledLink>)}
          {canManageChildEntity && (<StyledLink treatment="link" to={permissionsUrl}><i className="fas fa-users-cog" /></StyledLink>)}
        </SecondaryPanelTitle>

        {childEntityAction === 'edit' && canEditChildEntity && (
          <DataSetResponseEdit
            entity={entity}
            entityType={entityType}
            childEntity={childEntity}
            childEntityType={childEntityType}
          />
        )}
        {childEntityAction !== 'edit' && canViewChildEntity && <DataSetResponseShow childEntity={childEntity} childEntityType={childEntityType} />}
      </React.Fragment>
    );
  }

  renderEntityActions() {
    const { entity, entityType, userEntitiesManager, getEntityType } = this.props;
    const { isAddToModalOpen } = this.state;

    const availableEntities = userEntitiesManager
      .filter((e) => (Record.isRecord(e) && Record.isRecord(entityType) &&
                          getEntityType(e.entity_type).entity_class === 'ec_hierarchy' &&
                          getEntityType(e.entity_type).ordinality > entityType.ordinality))
      .sortBy((e) => Record.isRecord(e) && Record.isRecord(entityType) && `${getEntityType(e.entity_type).sortKey()}::${e.name}`);

    return (
      <EntityActions className="row">
        <div className="col-xs-6" />
        <div className="col-xs-6" style={{ textAlign: 'right' }}>
          {Record.isRecord(entity) && Record.isRecord(entityType) && entityType.entity_class === 'ec_hierarchy' && availableEntities.size > 0 && (
            <EntityActionsAddTo>
              <Button treatment="button" secondary="true" thin="true" onClick={() => this.setState({ isAddToModalOpen: true })}>
                <i className="fas fa-plus-square" /> Add To...
              </Button>

              {isAddToModalOpen && (
                <AddToModal entity={entity} entityType={entityType} availableEntities={availableEntities} closeModal={this.closeAddToModal} />
              )}
            </EntityActionsAddTo>
          )}
        </div>
      </EntityActions>
    );
  }

  renderSecondaryPanel() {
    const { entity, entityType, currentEntities, currentRelationships, entityAction, childEntity, childEntityType, canViewEntity,
      canManageEntity } = this.props;

    const members = this.members();

    return (
      <SecondaryPanelInset>
        {entityAction === 'permissions' && canViewEntity && members.size > 0 && (
          <React.Fragment>
            <SecondaryPanelTitle>
              <i className={`fas fa-user${members.size > 1 ? 's' : ''}-cog`} />
              <span>Permissions</span>
            </SecondaryPanelTitle>
            <PermissionsShow entity={entity} currentEntities={currentEntities} currentRelationships={currentRelationships} />
          </React.Fragment>
        )}
        {Record.isRecord(childEntity) && childEntityType.entity_class === 'ec_hierarchy' && (
          <React.Fragment>
            <div className="row">
              <div className="col-xs-8">
                <SecondaryPanelTitle>
                  <StyledLink treatment="link" to={childEntityType.absoluteUrl(childEntity.id)}>
                    <Icon><i className={`fa fa-${childEntityType.icon}`} /></Icon>
                  </StyledLink>
                  <Name>
                    <StyledLink treatment="link" to={childEntityType.absoluteUrl(childEntity.id)}>
                      {childEntity.name}
                    </StyledLink>
                  </Name>
                </SecondaryPanelTitle>
              </div>
              <div className="col-xs-4" style={{ textAlign: 'right' }}>
                <span title={moment(childEntity.created_at).format('MMM Do, YYYY h:mm a ZZ')}>
                  {moment(childEntity.created_at).format('MMM Do, YYYY')}
                </span>
              </div>
            </div>

            <HierarchyShow
              entity={entity}
              entityType={entityType}
              childEntity={childEntity}
              childEntityType={childEntityType}
              currentEntities={currentEntities}
              currentRelationships={currentRelationships}
              canManageEntity={canManageEntity}
            />
          </React.Fragment>
        )}
        {Record.isRecord(childEntity) && childEntityType.entity_class === 'ec_data_set_response' && this.renderSecondaryPanelDataSetResponse()}
      </SecondaryPanelInset>
    );
  }

  render() {
    const { entity, entityType, showPrimaryPanel, showSecondaryPanel } = this.props;

    return showPrimaryPanel && (
      <ActionPanelStyles>
        <Container className={showSecondaryPanel && 'secondary-panel-visible'}>
          <Header>
            {this.renderEntityHeader()}
          </Header>

          <SidebarExtension>
            {!showSecondaryPanel && Record.isRecord(entity) && this.renderEntityActions()}

            {showSecondaryPanel && Record.isRecord(entity) && Record.isRecord(entityType) && (
              <SecondaryPanel>
                <SecondaryPanelClose>
                  <StyledLink treatment="link" to={entityType.absoluteUrl(entity.id)}><i className="fas fa-times-circle" /></StyledLink>
                </SecondaryPanelClose>

                {this.renderSecondaryPanel()}
              </SecondaryPanel>
            )}
          </SidebarExtension>
        </Container>
      </ActionPanelStyles>
    );
  }
}

export default ActionPanelView;
