import styled from 'styled-components';

const EntitiesListFilterStyles = styled.div`
  border-top: 1px solid ${(props) => props.theme.foundation.entitiesList.borderColor};
  padding-top: 10px;
  margin: 20px 0;

  .filter-item {
    > div {
      padding-right: 15px;
    }

    .filter-trash {
      text-align: right;

      button {
        color: ${(props) => props.theme.foundation.entitiesList.textColorLight};
        font-size: 16px;
        margin: 20px 10px 0;

        &.add {
          visibility: hidden;
        }
      }
    }

    &:last-of-type {
      .filter-trash button.add {
        visibility: visible;
      }
    }
  }
`;

export default EntitiesListFilterStyles;
