import { connect } from 'react-redux';

import AddToModalView from './add_to_modal_view';

import { getForm, getCurrentUser } from '../../../reducer';

import { createCurrentRelationship } from '../../actions';

const mapStateToProps = (state, { entity }) => {
  const form = `entity:${entity.id}:add_to`;

  return {
    user: getCurrentUser(state),
    form,
    addToForm: getForm(state).get(form),
  };
};

const mapDispatchToProps = {
  createCurrentRelationship,
};

const AddToModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddToModalView);

export default AddToModalContainer;
