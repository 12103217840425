import { connect } from 'react-redux';
import { Map, merge } from 'immutable';
import { reset as reduxFormReset } from 'redux-form';

import { responseValue } from '../data_set_response_form';

import DataSetResponseNewView from './data_set_response_new_view';

import { getForm, getSettings, getFormSettings, getCurrentUser, getUserEntity, getUserEntities, getUserEntitiesPermissions, getEntityType,
  getDataSets } from '../../../../reducer';
import { setSubject, setOwner, createDataSetResponse } from '../../../actions';

const mapStateToProps = (state, { entity, subjectId }) => {
  const user = getCurrentUser(state);
  const subject = getUserEntity(state, subjectId);
  const dataSet = getDataSets(state).filter((ds) => ds.referenced_by === entity.id).first();
  const responseEntityType = dataSet && getEntityType(state, dataSet.response_entity_type);
  // eslint-disable-next-line new-cap
  const fields = dataSet && merge({}, ...dataSet.fields.map((field) => Map([[field.get('id'), responseValue(field, subject)]])));
  const form = `entity:${entity.id}:responses:new`;

  return {
    user,
    userEntities: getUserEntities(state),
    userEntitiesManager: getUserEntitiesPermissions(state).get('manager'),
    entity,
    responseEntityType,
    dataSet,
    form,
    dataSetResponseForm: getForm(state).get(form),
    settings: getSettings(state),
    formSettings: getFormSettings(state),
    initialValues: { fields },
  };
};

const mapDispatchToProps = {
  createDataSetResponse,
  setSubject,
  setOwner,
  reduxFormReset,
};

const DataSetResponseNewContainer = connect(mapStateToProps, mapDispatchToProps)(DataSetResponseNewView);

export default DataSetResponseNewContainer;
