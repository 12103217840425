import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../button';
import InvitationModal from '../../invitation_modal';

import UserModel from '../../../../session/models/user_model';

import InvitationCreateStyles from './invitation_create_styles';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  createInvitation: PropTypes.func.isRequired,
};

const InvitationCreateView = ({ user, createInvitation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const submitForm = (values) => {
    setCreating(true);
    setIsOpen(false);

    createInvitation(user.authToken, { email: values.get('email') })
      .then(() => setCreating(false))
      .catch(() => setCreating(false));
  };

  return (
    <InvitationCreateStyles>
      <Button treatment="button" primary="true" onClick={() => setIsOpen(true)}>
        <i className="fas fa-user-plus" /> Invite User
      </Button>

      {creating && <i className="fas fa-spinner fa-spin" />}

      {isOpen && <InvitationModal isOpen submitCallback={submitForm} closeCallback={() => setIsOpen(false)} />}
    </InvitationCreateStyles>
  );
};

InvitationCreateView.propTypes = propTypes;

export default InvitationCreateView;
