import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import DataSetResponseModel from '../models/data_set_response_model';

const reducer = new ResourcefulReducer('data_set_responses', DataSetResponseModel, {
  fetch: ACTIONS.FETCH_DATA_SET_RESPONSES,
  list: ACTIONS.LIST_DATA_SETS_RESPONSES,
  create: ACTIONS.CREATE_DATA_SETS_RESPONSE,
});

const DataSetResponseReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_CURRENT_ENTITIES) state = undefined; // eslint-disable-line no-param-reassign

  return reducer(state, action);
};

DataSetResponseReducer.selectors = reducer.selectors;

export default DataSetResponseReducer;
