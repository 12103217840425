import React from 'react';
import PropTypes from 'prop-types';
import { Map, Record, OrderedSet } from 'immutable';

import StyledLink from '../styled_link';

import UserModel from '../../../session/models/user_model';
import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import FinderStyles, { CurrentEntity, FinderBlock, FinderTools, Search, Collapse, SectionHeader, EntityList, Entity, EntityDescription,
  EntityIcon, EntityType, EntityName, AvailableEntities } from './finder_styles';

class FinderView extends React.Component {
  static propTypes = {
    showPrimaryPanel: PropTypes.bool,
    toggleFinder: PropTypes.func.isRequired,
    collapsed: PropTypes.bool,
    user: PropTypes.instanceOf(UserModel),
    userEntities: PropTypes.instanceOf(OrderedSet),
    entityTypeBySlug: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    getEntityType: PropTypes.func.isRequired,
    selectedEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    selectedEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
    currentEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
    currentEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  };

  static defaultProps = {
    showPrimaryPanel: false,
    collapsed: false,
    user: new UserModel(),
    userEntities: new OrderedSet(),
    entityTypeBySlug: null,
    selectedEntity: null,
    selectedEntityType: null,
    currentEntity: null,
    currentEntityType: null,
  };

  constructor(props) {
    super(props);

    this.selectEntityToUse = this.selectEntityToUse.bind(this);
    this.entityBlock = this.entityBlock.bind(this);
  }

  selectEntityToUse() {
    const { selectedEntity, selectedEntityType, currentEntity, currentEntityType } = this.props;

    if (Record.isRecord(currentEntity) && Record.isRecord(currentEntityType)) {
      return { entity: currentEntity, entityType: currentEntityType };
    }

    if (Record.isRecord(selectedEntity) && Record.isRecord(selectedEntityType)) {
      return { entity: selectedEntity, entityType: selectedEntityType };
    }

    return { entity: null, entityType: null };
  }

  entityBlock(entity, entityType) {
    const { collapsed } = this.props;

    return (
      <Entity className="row" key={entity}>
        <EntityIcon className={collapsed ? 'col-xs-6 col-xs-offset-3' : 'col-md-2 col-xs-3'}>
          <StyledLink treatment="link" to={entity && entityType.absoluteUrl(entity.id)}>
            {!collapsed && entityType.icon && <i className={`fa fa-${entityType.icon}`} />}
            {!collapsed && !entityType.icon && entity && entity.initials()}
            {collapsed && entity && entity.initials()}
          </StyledLink>
        </EntityIcon>
        {!collapsed && (
          <EntityDescription className="col-md-10 col-xs-9">
            <EntityType>{entityType.name}</EntityType>
            {entity && (
              <EntityName>
                <StyledLink treatment="link" to={entityType.absoluteUrl(entity.id)} title={entity.name}>
                  {entity.name}
                </StyledLink>
              </EntityName>
            )}
          </EntityDescription>
        )}
      </Entity>
    );
  }

  render() {
    const { toggleFinder, showPrimaryPanel, collapsed, user, userEntities, entityTypeBySlug, getEntityType } = this.props;

    const entityToUse = this.selectEntityToUse();

    const filteredEntities = userEntities.filter((entity) => {
      const entityType = getEntityType(entity.entity_type);

      return (Record.isRecord(entity) && Record.isRecord(entityType)) &&
        (!user.entityUids || !user.entityUids.includes(entity.id)) &&
        (!entityToUse.entity || entity.id !== entityToUse.entity.id) &&
        (['ec_hierarchy', 'ec_data_set'].includes(entityType.entity_class));
    }).sortBy((entity) => `${getEntityType(entity.entity_type).sortKey()}::${entity.name}`);

    return userEntities && userEntities.size > 0 && (
      <FinderStyles style={!showPrimaryPanel ? { borderTopRightRadius: 20 } : {}}>
        <FinderBlock className={collapsed ? 'sidebar-collapsed' : null}>
          {showPrimaryPanel && entityToUse.entity && (
            <CurrentEntity className="current-entity">
              {this.entityBlock(entityToUse.entity, entityToUse.entityType)}
            </CurrentEntity>
          )}

          <FinderTools className="row">
            {!collapsed && <Search className="col-xs-9" />}

            <Collapse onClick={toggleFinder} className={collapsed ? 'col-xs-12' : 'col-xs-3'}>
              <span>
                {!collapsed && <React.Fragment>&larr;</React.Fragment>}
                {collapsed && <React.Fragment>&rarr;</React.Fragment>}
              </span>
            </Collapse>
          </FinderTools>

          <AvailableEntities>
            {filteredEntities.size > 0 && (
              <SectionHeader className="section-header">
                <i className="fa fa-chevron-down" /> Network
              </SectionHeader>
            )}

            <EntityList>
              {filteredEntities.map((entity) => this.entityBlock(entity, getEntityType(entity.entity_type)))}
            </EntityList>
          </AvailableEntities>
        </FinderBlock>
      </FinderStyles>
    );
  }
}


export default FinderView;
