const translations = {
  en: {
    translation: {
      home: {
        header: 'Translated Home View Header',
      },
    },
  },
};

export default translations;
