import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';

import Button from '../../button';
import StyledLink from '../../styled_link';

import GenericStatusStyles from './generic_status_styles';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import PageModel from '../../../models/page_model';
import EventModel from '../../../models/event_model';

const GenericStatusView = ({ entity, entityType, model, submit, formData }) => {
  const [submitting, setSubmitting] = useState(false);
  const valid = model && Object.keys(model.validate(formData.get('values'))).length === 0;

  if (Record.isRecord(entity) && Record.isRecord(entityType)) {
    return (
      <GenericStatusStyles className="row">
        <div className="col-xs-3" style={{ textAlign: 'left' }}>
          <StyledLink treatment="button" secondary="true" to={entityType.absoluteUrl(entity.id)}>
            Cancel
          </StyledLink>
        </div>
        <div className="col-xs-3 col-xs-offset-6" style={{ textAlign: 'right' }}>
          <Button treatment="button" primary="true" onClick={() => submit(`entity:${entity.id}:edit`) || setSubmitting(true)} disabled={!valid || submitting}>
            Save Changes
          </Button>
        </div>
      </GenericStatusStyles>
    );
  }

  return null;
};

GenericStatusView.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
  entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  model: PropTypes.oneOfType([PropTypes.instanceOf(PageModel), PropTypes.instanceOf(EventModel)]),
  submit: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Map),
};

GenericStatusView.defaultProps = {
  entity: null,
  entityType: null,
  model: null,
  formData: new Map(),
};

export default GenericStatusView;
