import React from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';

import DataSetResponseStatus from './data_set_response_status';
import GenericStatus from './generic_status';

import StatusStyles from './status_styles';

import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';
import PageModel from '../../models/page_model';
import EventModel from '../../models/event_model';

const modelClasses = { ec_page: PageModel, ec_event: EventModel };

const StatusView = ({ entity, entityType, childEntity, childEntityType, entityAction, childEntityId }) => {
  if (Record.isRecord(entity) && Record.isRecord(entityType)) {
    const showGenericStatus = (['ec_page', 'ec_event'].includes(entityType.entity_class) && entityAction === 'edit');
    const showDataSetResponseStatus = ((entityType.entity_class === 'ec_data_set_response' && entityAction === 'edit') ||
                                       (entityType.entity_class === 'ec_data_set' && childEntityId === 'new'));

    return (showGenericStatus || showDataSetResponseStatus) && (
      <StatusStyles>
        {showDataSetResponseStatus && (
          <DataSetResponseStatus entity={entity} entityType={entityType} childEntity={childEntity} childEntityType={childEntityType} />
        )}
        {showGenericStatus && (
          <GenericStatus entity={entity} entityType={entityType} model={modelClasses[entityType.entity_class]} />
        )}
      </StatusStyles>
    );
  }

  return null;
};

StatusView.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
  entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  childEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
  childEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  entityAction: PropTypes.string,
  childEntityId: PropTypes.string,
};

StatusView.defaultProps = {
  entity: null,
  entityType: null,
  childEntity: null,
  childEntityType: null,
  entityAction: null,
  childEntityId: null,
};

export default StatusView;
