import { Record, OrderedSet, List } from 'immutable';
import { flattenArray } from '../../util';

const DEFAULT_ARGS = {
  id: null,
  referenced_by: null,
  response_status: null,
  response_entity_type: null,
  response_owner_entity_type: null,
  response_subject_entity_type: null,
  response_subject_force_new: null,
  response_immediate_draft_disabled: null,
  max_responses: null,
  owner: null,
  conditional_relationships: null,
  fields: [],
};

class DataSetModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::DataSet';

  static reduxFormValidate(values, props) {
    return Record.isRecord(props.dataSet) && props.dataSet.validate(values);
  }

  static displayConditionalField(dataSetField, valuesByField) {
    if (dataSetField.get('conditional_data_set_field_dependencies')) {
      const dependencies = dataSetField.get('conditional_data_set_field_dependencies');

      if (!valuesByField) return false;

      const results = dependencies.get('fields').map((field, key) => {
        const keyFieldValues = List.isList(valuesByField.get(key)) ? valuesByField.get(key).toJS() : valuesByField.get(key);
        return keyFieldValues && new OrderedSet(field).intersect(flattenArray([keyFieldValues]).map((item) => item && item.value)).size > 0;
      });

      return dependencies.get('settings').get('all') ? results.toList().filter((v) => v).size === results.size : results.toList().filter((v) => v).size > 0;
    }

    return true;
  }

  validate(values) {
    const errors = {};

    this.fields.forEach((dataSetField) => {
      const includeField = dataSetField.get('required') && values && DataSetModel.displayConditionalField(dataSetField, values.get('fields'));

      if (includeField && (!values.get('fields') || !values.get('fields').get(dataSetField.get('id')))) {
        errors.fields = { ...errors.fields };
        errors.fields[dataSetField.get('id')] = 'Required';
      }
    });

    return errors;
  }

  displayField(dataSetField, dataSetResponse) {
    return DataSetModel.displayConditionalField(dataSetField, dataSetResponse.asValues(this.fields));
  }

  conditionalRelationships() {
    return this.conditional_relationships && this.conditional_relationships.length > 0 ? this.conditional_relationships : null;
  }

  canSaveDraft() {
    return !!this.response_owner_entity_type;
  }

  filterEntities(entitiesToFilter, attribute) {
    return entitiesToFilter.filter((e) => e.get('entity_type') === this.get(attribute));
  }
}

export default DataSetModel;
