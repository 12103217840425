import { connect } from 'react-redux';

import InvitationCreateView from './invitation_create_view';

import { getCurrentUser } from '../../../../reducer';

import { createInvitation } from '../../../actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = {
  createInvitation,
};

const InvitationCreateContainer = connect(mapStateToProps, mapDispatchToProps)(InvitationCreateView);

export default InvitationCreateContainer;
