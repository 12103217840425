import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';

import Button from '../../button';
import StyledLink from '../../styled_link';

import DataSetResponseStatusStyles from './data_set_response_status_styles';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';
import DataSetResponseModel from '../../../models/data_set_response_model';

const renderLink = (text, entity, entityType, childEntity, childEntityType) => {
  let url = '';

  if (Record.isRecord(entity) && Record.isRecord(entityType)) {
    url = entityType.absoluteUrl(entity.id);

    if (Record.isRecord(childEntity) && Record.isRecord(childEntityType)) {
      url += childEntityType.absoluteUrl(childEntity.id);
    }
  }

  return url ? <StyledLink treatment="button" secondary="true" to={url}>{text}</StyledLink> : null;
};

const submitButtonText = (entity, entityType, dataSetResponse, responseEntityType, complete) => {
  // if the entity has a redirect id, assume that the name of the entity should also be the submit button label
  if (Record.isRecord(entity) && entity.redirect_id) return entity.name;

  if (Record.isRecord(dataSetResponse) && dataSetResponse.status >= complete) return `Update ${responseEntityType.name}`;

  return `Complete ${responseEntityType.name}`;
};

const DataSetResponseStatusView = ({ user, entity, entityType, childEntity, childEntityType, dataSet, dataSetResponse, responseEntityType,
  formData, doSubmitForm }) => {
  const [submitting, setSubmitting] = useState(false);

  if (Record.isRecord(entityType) && Record.isRecord(dataSet) && formData && formData.size) {
    const validation = dataSet.validate(formData.get('values'));
    const complete = dataSet.response_status ? dataSet.response_status.get('complete') : 1024; // 1024 is the default shareFlag

    return (
      <DataSetResponseStatusStyles className="row">
        <div className="col-xs-3" style={{ textAlign: 'left' }}>
          {user.signedIn && dataSet.canSaveDraft() && (!Record.isRecord(dataSetResponse) || dataSetResponse.status === 0) && (
            <Button treatment="button" secondary="true" onClick={() => doSubmitForm(0) || setSubmitting(true)} disabled={submitting}>Save Draft</Button>
          )}
          {(!dataSet.canSaveDraft() || (user.signedIn && Record.isRecord(dataSetResponse) && dataSetResponse.status > 0)) && (
            renderLink('Cancel', entity, entityType, childEntity, childEntityType)
          )}
        </div>
        <div className="col-xs-6" style={{ textAlign: 'center' }}>
          {validation && validation.fields && !submitting && <span style={{ marginRight: 10 }}>One or more required fields are missing</span>}
          {submitting && <i className="fas fa-spinner fa-spin" />}
        </div>
        <div className="col-xs-3" style={{ textAlign: 'right' }}>
          <Button
            treatment="button"
            primary="true"
            onClick={() => doSubmitForm(complete) || setSubmitting(true)}
            disabled={submitting || (validation && validation.fields)}
          >
            {submitButtonText(entity, entityType, dataSetResponse, responseEntityType, complete)}
          </Button>
        </div>
      </DataSetResponseStatusStyles>
    );
  }

  return null;
};

DataSetResponseStatusView.propTypes = {
  user: PropTypes.instanceOf(UserModel),
  entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
  entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  childEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]),
  childEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
  dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
  responseEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]),
  formData: PropTypes.instanceOf(Map),
  doSubmitForm: PropTypes.func.isRequired,
};

DataSetResponseStatusView.defaultProps = {
  user: null,
  entity: null,
  entityType: null,
  childEntity: null,
  childEntityType: null,
  dataSet: null,
  dataSetResponse: null,
  responseEntityType: null,
  formData: new Map(),
};

export default DataSetResponseStatusView;
