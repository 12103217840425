import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';

import HierarchyShowView from './hierarchy_show_view';

import { getCurrentChildEntities } from '../../../../reducer';

const mapStateToProps = (state, { entity }) => ({
  currentChildEntities: getCurrentChildEntities(state, OrderedSet([entity])), // eslint-disable-line new-cap
});

const HierarchyShowContainer = connect(mapStateToProps)(HierarchyShowView);

export default HierarchyShowContainer;
