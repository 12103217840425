import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  referenced_by: null,
  created_at: null,
  updated_at: null,
  content: null,
};

class PageModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::Page';

  static validate() {
    return {};
  }
}

export default PageModel;
