import React from 'react';
import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/lib/immutable';

import Modal from '../modal';
import Button from '../button';

import UserModel from '../../../session/models/user_model';
import EntityModel from '../../models/entity_model';
import EntityTypeModel from '../../models/entity_type_model';

import VisibilityModalStyles from './visibility_modal_styles';

class VisibilityModalView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel).isRequired,
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]).isRequired,
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]).isRequired,
    visibilityForm: PropTypes.instanceOf(Map),
    allowGlobalVisibility: PropTypes.bool,
    updateCurrentEntity: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    visibilityForm: new Map(),
    allowGlobalVisibility: false,
    pristine: false,
    invalid: false,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { user, entity, visibilityForm, closeModal, updateCurrentEntity } = this.props;

    updateCurrentEntity(user.authToken, entity.id, { visibility: visibilityForm.get('values').get('visibility') }).then(() => closeModal());
  }

  render() {
    const { entity, entityType, closeModal, allowGlobalVisibility, handleSubmit, pristine, invalid, submitting } = this.props;

    return Record.isRecord(entity) && Record.isRecord(entityType) && (
      <Modal isOpen closeModal={closeModal} offset="20%">
        <VisibilityModalStyles>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="modal-header">
              {entity.name} Visibility
            </div>

            <div className="modal-body">
              <label htmlFor="visibility-restricted">
                <div className="visibility-option row col-xs-offset-1 col-xs-10">
                  <div className="col-xs-2 visibility-icon">
                    <Field id="visibility-restricted" component="input" type="radio" name="visibility" value="v_restricted" />
                    <i className="fa fa-lock" />
                  </div>
                  <div className="col-xs-10">
                    <div className="header">Restricted</div>
                    <div className="description">Only users with permission may access this {entityType.name}</div>
                  </div>
                </div>
              </label>
              <label htmlFor="visibility-unlisted">
                <div className="visibility-option row col-xs-offset-1 col-xs-10">
                  <div className="col-xs-2 visibility-icon">
                    <Field id="visibility-unlisted" component="input" type="radio" name="visibility" value="v_unlisted" />
                    <i className="fa fa-eye-slash" />
                  </div>
                  <div className="col-xs-10">
                    <div className="header">Unlisted</div>
                    <div className="description">Any user with the link may access this {entityType.name}</div>
                  </div>
                </div>
              </label>
              {entity.visibility === 'v_contextual' && (
                <label htmlFor="visibility-contextual">
                  <div className="visibility-option row col-xs-offset-1 col-xs-10">
                    <div className="col-xs-2 visibility-icon">
                      <Field id="visibility-contextual" component="input" type="radio" name="visibility" value="v_contextual" />
                      <i className="fa fa-stream" />
                    </div>
                    <div className="col-xs-10">
                      <div className="header">Contextual</div>
                      <div className="description">This {entityType.name} is shown in context with other items</div>
                    </div>
                  </div>
                </label>
              )}
              <label htmlFor="visibility-public">
                <div className="visibility-option row col-xs-offset-1 col-xs-10">
                  <div className="col-xs-2 visibility-icon">
                    <Field id="visibility-public" component="input" type="radio" name="visibility" value="v_public" />
                    <i className="fa fa-lock-open" />
                  </div>
                  <div className="col-xs-10">
                    <div className="header">Public</div>
                    <div className="description">Any user can search for or browse to this {entityType.name}</div>
                  </div>
                </div>
              </label>
              {allowGlobalVisibility && (
                <label htmlFor="visibility-global">
                  <div className="visibility-option row col-xs-offset-1 col-xs-10">
                    <div className="col-xs-2 visibility-icon">
                      <Field id="visibility-global" component="input" type="radio" name="visibility" value="v_global" />
                      <i className="fa fa-globe-americas" />
                    </div>
                    <div className="col-xs-10">
                      <div className="header">Global</div>
                      <div className="description">This {entityType.name} can be accessed by anyone, even if they do not have an account</div>
                    </div>
                  </div>
                </label>
              )}
            </div>

            <div className="modal-footer">
              <Button treatment="button" primary="true" disabled={pristine || invalid || submitting}>Update Visibility</Button>
            </div>
          </form>
        </VisibilityModalStyles>
      </Modal>
    );
  }
}


export default reduxForm({ enableReinitialize: true })(VisibilityModalView);
