import actions from '../actions';

const DEFAULT_STATE = 0;

function ListEntityCountReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.FETCH_LIST_ENTITY_CHILDREN.success:
      return parseInt(action.response.count, 10);
    default:
      return state;
  }
}

export default ListEntityCountReducer;
