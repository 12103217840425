import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as ParamsContext from './params_context';
import * as GenericAPIAction from './generic_api_action';
import { CRUDResourcefulReducer } from './resourceful_reducer';

const DEFAULT_STATE = new Map();

export function combineImmutableReducers(reducers, initialState = DEFAULT_STATE) {
  // redux-immutable is lame and changed the second param type to a generator instead of the actual default...
  return combineReducers(reducers, () => initialState);
}

// Simple little helper that wraps setTimeout in a Promise
export const sleep = (duration, ...args) => new Promise((resolve) => setTimeout(() => resolve(...args), duration));

// Array.prototype.flat doesn't exist in certain older versions of Safari, so use a simple polyfill instead
export const flattenArray = (arr) => arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flattenArray(val) : val), []);

export const { withParams, exportingParams } = ParamsContext;

export const ResourcefulReducer = CRUDResourcefulReducer;

export const { genericAPIActionTypes, genericAPIActionCreator } = GenericAPIAction;
