import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import EntitiesList, { initialSortCreated } from '../../entities_list';
import HierarchyProfile from './hierarchy_profile';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';

import { memberPermissionTypes, editorPermissionTypes } from '../../../../reducer';

import HierarchyShowStyles, { Body, Profile, TabContent } from './hierarchy_show_styles';

class HierarchyShowView extends React.Component {
  static propTypes = {
    entity: PropTypes.instanceOf(EntityModel).isRequired,
    entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
    canEditEntity: PropTypes.bool,
    currentChildEntities: PropTypes.instanceOf(OrderedSet),
    currentRelationships: PropTypes.instanceOf(OrderedSet),
  }

  static defaultProps = {
    currentChildEntities: new OrderedSet(),
    currentRelationships: new OrderedSet(),
    canEditEntity: false,
  }

  render() {
    const { entity, entityType, canEditEntity, currentChildEntities, currentRelationships } = this.props;

    return (
      <HierarchyShowStyles>
        <Body>
          <Profile>
            <HierarchyProfile entity={entity} entityType={entityType} canEditEntity={canEditEntity} />
          </Profile>

          {currentChildEntities.size > 0 && (
            <Tabs>
              <TabList>
                <Tab>Contacts</Tab>
                <Tab>Members</Tab>
              </TabList>
              <TabPanel>
                <TabContent>
                  <EntitiesList
                    entity={entity}
                    entityType={entityType}
                    currentRelationships={currentRelationships}
                    childEntityTypeClass="ec_hierarchy"
                    parentEntityTypeClass="ec_data_set_response"
                    hideFields={['Permission', 'Updated']}
                    initialSort={initialSortCreated}
                    showPermissionTypes={['associated']}
                  />
                </TabContent>
              </TabPanel>
              <TabPanel>
                <TabContent>
                  <EntitiesList
                    entity={entity}
                    entityType={entityType}
                    currentRelationships={currentRelationships}
                    childEntityTypeClass="ec_hierarchy"
                    parentEntityTypeClass="ec_data_set_response"
                    hideFields={['Updated']}
                    initialSort={initialSortCreated}
                    associated={null}
                    showPermissionTypes={(memberPermissionTypes.subtract(editorPermissionTypes)).toArray()}
                  />
                </TabContent>
              </TabPanel>
            </Tabs>
          )}
        </Body>
      </HierarchyShowStyles>
    );
  }
}

export default HierarchyShowView;
