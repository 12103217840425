import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import InvitationModel from '../models/invitation_model';

const InvitationReducer = new ResourcefulReducer('invitations', InvitationModel, {
  list: ACTIONS.LIST_INVITATIONS,
  create: ACTIONS.CREATE_INVITATION,
  remove: ACTIONS.DELETE_INVITATION,
});

export default InvitationReducer;
