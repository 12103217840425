import { connect } from 'react-redux';
import DashboardShowView from './dashboard_show_view';

import { getForm, getCurrentUser, getEntityTypesById } from '../../../../reducer';
import { createUserEntity } from '../../../actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  entityTypes: getEntityTypesById(state),
  newEntityForm: getForm(state).get('createEntity'),
});

const mapDispatchToProps = {
  createUserEntity,
};

const DashboardShowContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardShowView);

export default DashboardShowContainer;
