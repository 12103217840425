import { connect } from 'react-redux';
import { hasGlobalSetting, getCurrentUser } from '../../../reducer';
import SignInForm from './sign_in_form';
import { signIn } from '../../actions';

const mapStateToProps = (state) => ({
  openAccountCreation: hasGlobalSetting(state, 'OPEN_ACCOUNT_CREATION'),
  signedIn: getCurrentUser(state).signedIn,
});

const mapDispatchToProps = {
  onSubmit: (args) => signIn(args.get('email'), args.get('password')),
};

const SignInContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInForm);

export default SignInContainer;
