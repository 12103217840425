import styled from 'styled-components';

const EventShowStyles = styled.div`
`;

export const Profile = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.foundation.tabBar.borderColor};
  box-shadow: 0 5px 5px rgba(0,0,0,0.1);

  .user-signedout & {
    border-bottom: 0 none;
    box-shadow: none;
  }
`;

export default EventShowStyles;
