import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: null,
  slug: null,
  name: 'New Entity Type',
  icon: null,
  entity_class: null,
  ordinality: null,
  has_secondary_children: null,
  indexable: null,
  profile_data_set: null,
  child_entity_type: null,
  type_visibility: null,
  entity_visibility: null,
};

class EntityTypeModel extends Record(DEFAULT_ARGS) {
  static _name = 'Foundation::EntityType';

  sortKey() {
    return this.ordinality.toString().padStart(10);
  }

  absoluteUrl(entityId) {
    return `/${this.slug}/${entityId}`;
  }

  typeVisibilityName() {
    switch (this.type_visibility) {
      case 'v_restricted': return 'Restricted';
      case 'v_unlisted': return 'Unlisted';
      case 'v_public': return 'Public';
      default: return '';
    }
  }

  entityVisibilityName() {
    switch (this.entity_visibility) {
      case 'v_restricted': return 'Restricted';
      case 'v_unlisted': return 'Unlisted';
      case 'v_contextual': return 'Contextual';
      case 'v_public': return 'Public';
      case 'v_global': return 'Global';
      default: return '';
    }
  }

  entityVisibilityIcon() {
    switch (this.entity_visibility) {
      case 'v_restricted': return 'lock';
      case 'v_unlisted': return 'eye-slash';
      case 'v_contextual': return 'stream';
      case 'v_public': return 'lock-open';
      case 'v_global': return 'globe-americas';
      default: return '';
    }
  }
}

export default EntityTypeModel;
