import { mockResourcefulAPI } from '../../util/mock_api';
import MOCK_WIDGETS from './mocks/widgets';
import MOCK_GLOBAL_SETTINGS from './mocks/global_settings';
import MOCK_ENTITIES from './mocks/entities';
import MOCK_RELATIONSHIPS from './mocks/relationships';
import MOCK_DATA_SET_RESPONSES from './mocks/data_set_responses';
import * as User from './user';

export const { signIn, refreshToken, OAUTH, finishSignIn, register, confirmEmail, forgotPassword, resetPassword, updateCurrentUser } = User;

const WIDGET_API = mockResourcefulAPI('widget', MOCK_WIDGETS);

const GLOBAL_SETTING_API = mockResourcefulAPI('global_setting', MOCK_GLOBAL_SETTINGS);

const ENTITY_API = mockResourcefulAPI('entity', MOCK_ENTITIES);
const RELATIONSHIP_API = mockResourcefulAPI('relationship', MOCK_RELATIONSHIPS);
const DATA_SET_RESPONSE_API = mockResourcefulAPI('data_set_response', MOCK_DATA_SET_RESPONSES);

export const listWidgets = WIDGET_API.list;
export const updateWidget = WIDGET_API.update;

export const listGlobalSettings = GLOBAL_SETTING_API.list;

export const fetchEntity = ENTITY_API.get;
export const fetchEntityParents = ENTITY_API.list;
export const fetchEntityChildren = ENTITY_API.list;
export const listEntities = ENTITY_API.list;
export const createEntity = ENTITY_API.create;
export const updateEntity = ENTITY_API.update;

export const listExportedEntities = null;

export const createRelationship = RELATIONSHIP_API.create;
export const updateRelationship = RELATIONSHIP_API.update;
export const deleteRelationship = RELATIONSHIP_API.delete;

export const createDataSetResponse = DATA_SET_RESPONSE_API.create;
export const updateDataSetResponse = DATA_SET_RESPONSE_API.update;
