import styled from 'styled-components';

const PageEditStyles = styled.div`
  margin: 0 10px;

  .wrapper {
    border: 1px solid #EEE;

    .editor {
      margin: 0 5px;

      .public-DraftEditor-content {
        height: 300px;
      }
    }
  }
`;

export default PageEditStyles;
