import { PURGE } from 'redux-persist';
import { Map } from 'immutable';
import actions from '../actions';

const DEFAULT_STATE = new Map();

function StatusReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions.REGISTER.failure:
      return state.merge({ message: action.message, errors: action.errors });
    case '@@redux-form/DESTROY':
    case '@@router/LOCATION_CHANGE':
    case actions.SIGN_OUT:
    case PURGE:
      return DEFAULT_STATE;
    default:
      return state;
  }
}

export default StatusReducer;
