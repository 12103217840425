import styled from 'styled-components';

const DashboardShowStyles = styled.div`
  color: ${(props) => props.theme.foundation.content.textColor};
`;

export const Section = styled.div`
  padding: 10px;
`;

export const CtaBlock = styled.div`
  padding: 20px;
  margin: 20px;
  text-align: center;

  div.icon {
    span {
      display: inline-block;
      background-color: ${(props) => props.theme.foundation.content.iconBackgroundColor};
      color: white;
      padding: 15px;
      border-radius: 10px;
      margin-bottom: 8px;
    }
  }

  div.actions {
    margin-top: 8px;

    & > * {
      margin: 0 10px;
    }
  }
`;

export default DashboardShowStyles;
