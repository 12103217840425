import styled from 'styled-components';

const DataSetResponseShowStyles = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  
  .data-set-name {
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 10px;
  }
  
  .data-set-response-field {
    margin-top: 15px;
  }
  
  .data-set-response-field-name {
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .data-set-response-field-response {
    word-wrap: break-word;
  }
`;

export default DataSetResponseShowStyles;
