import styled from 'styled-components';

const DataSetResponseShowStyles = styled.div`
  margin-bottom: 15px;
  padding: 10px;

  .data-set-response-field {
    .data-set-response-field-name {
      margin: 5px 10px;
      font-size: 1.1em;
      font-weight: 500;
    }

    .box {
      background-color: #FCFCFC;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      margin: 5px 10px;
      padding: 10px;
      position: relative;
  
      .data-set-response-field-updated-at {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        color: rgba(0, 0, 0, 0.08);
        cursor: help;
      }

      .data-set-response-field-response {
        margin-top: 6px;
        word-wrap: break-word;
      }
    }

    .data-set-response-field-description {
      visibility: hidden;
      height: 40px;
      overflow: hidden;
      margin: 5px 10px;
      font-size: 0.8em;
      color: #666;
    }

    &:hover .data-set-response-field-description {
      visibility: visible;
    }

    &:hover .data-set-response-field-updated-at {
      display: block;
    }
  }
`;

export default DataSetResponseShowStyles;
