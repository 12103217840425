import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Record } from 'immutable';

import DataSetModel from '../../../models/data_set_model';
import DataSetResponseModel from '../../../models/data_set_response_model';

import DataSetResponseShowStyles from './data_set_response_show_styles';

class DataSetResponseShowView extends React.Component {
  static propTypes = {
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
  };

  static defaultProps = {
    dataSet: null,
    dataSetResponse: null,
  };

  constructor(props) {
    super(props);

    this.renderResponse = this.renderResponse.bind(this);
    this.renderResponseAttribute = this.renderResponseAttribute.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  renderResponse(field) {
    const { dataSetResponse } = this.props;

    const response = dataSetResponse.getDataSetFieldResponse(field);
    return (response && field.get('options')) ? response.map((option) => option.get('label')).join(', ') : response;
  }

  renderResponseAttribute(field, attr) {
    const { dataSetResponse } = this.props;

    return dataSetResponse.getDataSetFieldResponseAttribute(field, attr);
  }

  renderField(field) {
    return field.get('field_type') !== 'content' && (
      <React.Fragment key={field}>
        <div className="col-md-6 col-xs-12 data-set-response-field">
          <div className="data-set-response-field-name">{field.get('name')}</div>
          <div className="box">
            <div className="data-set-response-field-updated-at">
              <i
                title={`Updated: ${moment(this.renderResponseAttribute(field, 'updated_at')).format('MMM Do, YYYY h:mm a ZZ')}`}
                className="fas fa-history"
              />
            </div>
            <div className="data-set-response-field-response">{this.renderResponse(field)}</div>
          </div>
          <div className="data-set-response-field-description">{field.get('description')}</div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { dataSet, dataSetResponse } = this.props;

    return Record.isRecord(dataSet) && Record.isRecord(dataSetResponse) && (
      <DataSetResponseShowStyles>
        <div className="row">
          {dataSet.fields.filter((dataSetField) => dataSet.displayField(dataSetField, dataSetResponse))
            .sortBy((dataSetField) => dataSetField.get('sort_order')).map((dataSetField) => this.renderField(dataSetField))}
        </div>
      </DataSetResponseShowStyles>
    );
  }
}

export default DataSetResponseShowView;
