import { connect } from 'react-redux';
import { hasGlobalSetting, getCurrentUser, getSessionStatus, getSettings } from '../../../reducer';
import RegisterForm from './register_form';
import { register } from '../../actions';

const mapStateToProps = (state, { match: { params: { token } } }) => ({
  openAccountCreation: hasGlobalSetting(state, 'OPEN_ACCOUNT_CREATION'),
  signedIn: getCurrentUser(state).signedIn,
  status: getSessionStatus(state),
  settings: getSettings(state),
  token,
});

const mapDispatchToProps = {
  register,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: (info) => dispatchProps.register(info.merge({
    first_url: stateProps.settings.get('firstUrl'),
    first_params: stateProps.settings.get('firstParams'),
    invitation_token: stateProps.token,
  }).toJS()),
});

const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(RegisterForm);

export default RegisterContainer;
