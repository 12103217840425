// List of foundation actions.  All scoped with 'FOUNDATION:'
import * as API from '../../api';
import { genericAPIActionCreator, genericAPIActionTypes } from '../../util';

const ACTIONS = {
  SET_FIRST_URL: 'Foundation:SetFirstUrl',
  SET_FIRST_PARAMS: 'Foundation:SetFirstParams',
  SET_ENTITY_LOADING: 'Foundation:SetEntityLoading',
  SET_SUBJECT: 'Foundation:SetSubject',
  SET_OWNER: 'Foundation:SetOwner',
  SAVE_WITH_STATUS: 'Foundation:SaveWithStatus',
  LIST_INVITATIONS: genericAPIActionTypes('Foundation:ListInvitations'),
  CREATE_INVITATION: genericAPIActionTypes('Foundation:CreateInvitation'),
  DELETE_INVITATION: genericAPIActionTypes('Foundation:DeleteInvitation'),
  LIST_ENTITY_TYPES: genericAPIActionTypes('Foundation:ListEntityTypes'),
  FETCH_ENTITY_TYPE_SUBJECT_OF: genericAPIActionTypes('Foundation:FetchEntityTypeSubjectOf'),
  FETCH_USER_ENTITY: genericAPIActionTypes('Foundation:FetchUserEntity'),
  FETCH_USER_ENTITY_ANCESTORS: genericAPIActionTypes('Foundation:FetchUserEntityAncestors'),
  SET_USER_ENTITIES_PERMISSIONS: 'Foundation:SetUserEntitiesPermissions',
  CREATE_USER_ENTITY: genericAPIActionTypes('Foundation:CreateUserEntity'),
  CLEAR_CURRENT_ENTITIES: 'Foundation:ClearCurrentEntities',
  FETCH_CURRENT_ENTITY: genericAPIActionTypes('Foundation:FetchCurrentEntity'),
  FETCH_CURRENT_ENTITY_PARENTS: genericAPIActionTypes('Foundation:FetchCurrentEntityParents'),
  FETCH_CURRENT_ENTITY_CHILDREN: genericAPIActionTypes('Foundation:FetchCurrentEntityChildren'),
  FETCH_CURRENT_ENTITY_REFERENCE: genericAPIActionTypes('Foundation:FetchCurrentEntityReference'),
  FETCH_CURRENT_ENTITY_SUBJECT: genericAPIActionTypes('Foundation:FetchCurrentEntitySubject'),
  FETCH_CURRENT_ENTITY_SUBJECT_OF: genericAPIActionTypes('Foundation:FetchCurrentEntitySubjectOf'),
  LIST_CURRENT_ENTITIES: genericAPIActionTypes('Foundation:ListCurrentEntities'),
  CREATE_CURRENT_ENTITY: genericAPIActionTypes('Foundation:CreateCurrentEntity'),
  UPDATE_CURRENT_ENTITY: genericAPIActionTypes('Foundation:UpdateCurrentEntity'),
  CLEAR_LIST_ENTITIES: 'Foundation:ClearListEntities',
  FETCH_LIST_ENTITY: genericAPIActionTypes('Foundation:FetchListEntity'),
  FETCH_LIST_ENTITY_PARENTS: genericAPIActionTypes('Foundation:FetchListEntityParents'),
  FETCH_LIST_ENTITY_CHILDREN: genericAPIActionTypes('Foundation:FetchListEntityChildren'),
  FETCH_LIST_ENTITY_REFERENCE: genericAPIActionTypes('Foundation:FetchListEntityReference'),
  FETCH_LIST_ENTITY_SUBJECT: genericAPIActionTypes('Foundation:FetchListEntitySubject'),
  FETCH_LIST_ENTITY_SUBJECT_OF: genericAPIActionTypes('Foundation:FetchListEntitySubjectOf'),
  CREATE_LIST_ENTITY: genericAPIActionTypes('Foundation:CreateListEntity'),
  UPDATE_LIST_ENTITY: genericAPIActionTypes('Foundation:UpdateListEntity'),
  LIST_EXPORTED_ENTITIES: genericAPIActionTypes('Foundation:ListExportedEntities'),
  SET_SELECTED_ENTITY: 'Foundation:SetSelectedEntity',
  SET_SELECTED_ENTITY_TYPE: 'Foundation:SetSelectedEntityType',
  SET_SELECTED_ENTITY_PERMISSIONS: 'Foundation:SetSelectedEntityPermissions',
  SET_SELECTED_CHILD_ENTITY_PERMISSIONS: 'Foundation:SetSelectedChildEntityPermissions',
  CLEAR_CURRENT_RELATIONSHIPS: 'Foundation:ClearCurrentRelationships',
  CREATE_CURRENT_RELATIONSHIP: genericAPIActionTypes('Foundation:CreateCurrentRelationship'),
  UPDATE_CURRENT_RELATIONSHIP: genericAPIActionTypes('Foundation:UpdateCurrentRelationship'),
  DELETE_CURRENT_RELATIONSHIP: genericAPIActionTypes('Foundation:DeleteCurrentRelationship'),
  CLEAR_LIST_RELATIONSHIPS: 'Foundation:ClearListRelationships',
  CREATE_LIST_RELATIONSHIP: genericAPIActionTypes('Foundation:CreateListRelationship'),
  UPDATE_LIST_RELATIONSHIP: genericAPIActionTypes('Foundation:UpdateListRelationship'),
  DELETE_LIST_RELATIONSHIP: genericAPIActionTypes('Foundation:DeleteListRelationship'),
  UPDATE_PAGE: genericAPIActionTypes('Foundation:UpdatePage'),
  UPDATE_EVENT: genericAPIActionTypes('Foundation:UpdateEvent'),
  CREATE_DATA_SET_RESPONSE: genericAPIActionTypes('Foundation:CreateDataSetResponse'),
  UPDATE_DATA_SET_RESPONSE: genericAPIActionTypes('Foundation:UpdateDataSetResponse'),
};

export const setFirstUrl = (url) => ({ type: ACTIONS.SET_FIRST_URL, url });
export const setFirstParams = (params) => ({ type: ACTIONS.SET_FIRST_PARAMS, params });
export const setEntityLoading = (loading) => ({ type: ACTIONS.SET_ENTITY_LOADING, loading });
export const setSubject = (id) => ({ type: ACTIONS.SET_SUBJECT, id });
export const setOwner = (id) => ({ type: ACTIONS.SET_OWNER, id });

export const saveWithStatus = (status) => ({ type: ACTIONS.SAVE_WITH_STATUS, status });

export const listInvitations = (token) => genericAPIActionCreator(() => API.listInvitations(token),
  ACTIONS.LIST_INVITATIONS, 'Unable to list invitations');

export const createInvitation = (token, invitation) => genericAPIActionCreator(() => API.createInvitation(token, invitation),
  ACTIONS.CREATE_INVITATION, 'Unable to create invitation');

export const deleteInvitation = (token, id) => genericAPIActionCreator(() => API.deleteInvitation(token, id),
  ACTIONS.DELETE_INVITATION, `Unable to delete invitation ${id}`);

export const listEntityTypes = (token) => genericAPIActionCreator(() => API.listEntityTypes(token),
  ACTIONS.LIST_ENTITY_TYPES, 'Unable to list entity types');

export const fetchEntityTypeSubjectOf = (token, id) => genericAPIActionCreator(() => API.fetchEntityTypeSubjectOf(token, id),
  ACTIONS.FETCH_ENTITY_TYPE_SUBJECT_OF, `Unable to fetch entity type subject of for ${id}`);

export const fetchUserEntity = (token, id) => genericAPIActionCreator(() => API.fetchUserEntity(token, id),
  ACTIONS.FETCH_USER_ENTITY, `Unable to fetch user entity ${id}`);

export const fetchUserEntityAncestors = (token, id) => genericAPIActionCreator(() => API.fetchUserEntityAncestors(token, id),
  ACTIONS.FETCH_USER_ENTITY_ANCESTORS, `Unable to fetch user entity ancestors for ${id}`);

export const setUserEntitiesPermissions = (permissions) => ({ type: ACTIONS.SET_USER_ENTITIES_PERMISSIONS, permissions });

export const createUserEntity = (token, entity) => genericAPIActionCreator(() => API.createUserEntity(token, entity),
  ACTIONS.CREATE_USER_ENTITY, 'Unable to create user entity');

export const clearCurrentEntities = () => ({ type: ACTIONS.CLEAR_CURRENT_ENTITIES });

export const fetchCurrentEntity = (token, id) => genericAPIActionCreator(() => API.fetchCurrentEntity(token, id),
  ACTIONS.FETCH_CURRENT_ENTITY, `Unable to fetch current entity ${id}`);

export const fetchCurrentEntityParents = (token, id, entityTypeClass = undefined, ids = []) =>
  genericAPIActionCreator(() => API.fetchCurrentEntityParents(token, id, entityTypeClass, ids),
    ACTIONS.FETCH_CURRENT_ENTITY_PARENTS, `Unable to fetch current entity parents for ${id}`);

export const fetchCurrentEntityChildren = (token, id, entityTypeClass = undefined, associated = undefined) =>
  genericAPIActionCreator(() => API.fetchCurrentEntityChildren(token, id, entityTypeClass, associated),
    ACTIONS.FETCH_CURRENT_ENTITY_CHILDREN, `Unable to fetch current entity children for ${id}`);

export const fetchCurrentEntityReference = (token, id) => genericAPIActionCreator(() => API.fetchCurrentEntityReference(token, id),
  ACTIONS.FETCH_CURRENT_ENTITY_REFERENCE, `Unable to fetch current entity reference for ${id}`);

export const fetchCurrentEntitySubject = (token, id) => genericAPIActionCreator(() => API.fetchCurrentEntitySubject(token, id),
  ACTIONS.FETCH_CURRENT_ENTITY_SUBJECT, `Unable to fetch current entity subject for ${id}`);

export const fetchCurrentEntitySubjectOf = (token, id) => genericAPIActionCreator(() => API.fetchCurrentEntitySubjectOf(token, id),
  ACTIONS.FETCH_CURRENT_ENTITY_SUBJECT_OF, `Unable to fetch current entity subject of for ${id}`);

export const listCurrentEntities = (token, entityTypeSlug, page, size) =>
  genericAPIActionCreator(() => API.listCurrentEntities(token, entityTypeSlug, page, size),
    ACTIONS.LIST_CURRENT_ENTITIES, 'Unable to list current entities');

export const createCurrentEntity = (token, entity) => genericAPIActionCreator(() => API.createCurrentEntity(token, entity),
  ACTIONS.CREATE_CURRENT_ENTITY, 'Unable to create current entity');

export const updateCurrentEntity = (token, id, params) => genericAPIActionCreator(() => API.updateCurrentEntity(token, id, params),
  ACTIONS.UPDATE_CURRENT_ENTITY, `Unable to update current entity ${id}`);

export const clearListEntities = () => ({ type: ACTIONS.CLEAR_LIST_ENTITIES });

export const fetchListEntity = (token, id) => genericAPIActionCreator(() => API.fetchListEntity(token, id),
  ACTIONS.FETCH_LIST_ENTITY, `Unable to fetch list entity ${id}`);

export const fetchListEntityParents = (token, id, entityTypeClass, ids = []) =>
  genericAPIActionCreator(() => API.fetchListEntityParents(token, id, entityTypeClass, ids),
    ACTIONS.FETCH_LIST_ENTITY_PARENTS, `Unable to fetch list entity parents for ${id}`);

export const fetchListEntityChildren = (token, id, entityTypeClass = undefined, associated = true, page = null, size = null, sort = [], query = null,
  statuses = null, filters = null) =>
  genericAPIActionCreator(() => API.fetchListEntityChildren(token, id, entityTypeClass, associated, page, size, sort, query, statuses, filters),
    ACTIONS.FETCH_LIST_ENTITY_CHILDREN, `Unable to fetch list entity children for ${id}`);

export const fetchListEntityReference = (token, id) => genericAPIActionCreator(() => API.fetchListEntityReference(token, id),
  ACTIONS.FETCH_LIST_ENTITY_REFERENCE, `Unable to fetch list entity reference for ${id}`);

export const fetchListEntitySubject = (token, id) => genericAPIActionCreator(() => API.fetchListEntitySubject(token, id),
  ACTIONS.FETCH_LIST_ENTITY_SUBJECT, `Unable to fetch list entity subject for ${id}`);

export const fetchListEntitySubjectOf = (token, id) => genericAPIActionCreator(() => API.fetchListEntitySubjectOf(token, id),
  ACTIONS.FETCH_LIST_ENTITY_SUBJECT_OF, `Unable to fetch list entity subject of for ${id}`);

export const createListEntity = (token, entity) => genericAPIActionCreator(() => API.createListEntity(token, entity),
  ACTIONS.CREATE_LIST_ENTITY, 'Unable to create list entity');

export const updateListEntity = (token, id, action, params) => genericAPIActionCreator(() => API.updateListEntity(token, id, action, params),
  ACTIONS.UPDATE_LIST_ENTITY, `Unable to update list entity ${id}`);

export const listExportedEntities = (token, id, entityTypeClass) =>
  genericAPIActionCreator(() => API.listExportedEntities(token, id, entityTypeClass), ACTIONS.LIST_EXPORTED_ENTITIES, 'Unable to list exported entities');

export const setSelectedEntity = (entity) => ({ type: ACTIONS.SET_SELECTED_ENTITY, entity });
export const setSelectedEntityType = (entityType) => ({ type: ACTIONS.SET_SELECTED_ENTITY_TYPE, entityType });
export const setSelectedEntityPermissions = (permissions) => ({ type: ACTIONS.SET_SELECTED_ENTITY_PERMISSIONS, permissions });
export const setSelectedChildEntityPermissions = (permissions) => ({ type: ACTIONS.SET_SELECTED_CHILD_ENTITY_PERMISSIONS, permissions });

export const clearCurrentRelationships = () => ({ type: ACTIONS.CLEAR_CURRENT_RELATIONSHIPS });

export const createCurrentRelationship = (token, relationship) => genericAPIActionCreator(() => API.createCurrentRelationship(token, relationship),
  ACTIONS.CREATE_CURRENT_RELATIONSHIP, 'Unable to create current relationship');

export const updateCurrentRelationship = (token, id, relationship) => genericAPIActionCreator(() => API.updateCurrentRelationship(token, id, relationship),
  ACTIONS.UPDATE_CURRENT_RELATIONSHIP, `Unable to update current relationship ${id}`);

export const deleteCurrentRelationship = (token, id) => genericAPIActionCreator(() => API.deleteCurrentRelationship(token, id),
  ACTIONS.DELETE_CURRENT_RELATIONSHIP, `Unable to delete current relationship ${id}`);

export const clearListRelationships = () => ({ type: ACTIONS.CLEAR_LIST_RELATIONSHIPS });

export const createListRelationship = (token, relationship) => genericAPIActionCreator(() => API.createListRelationship(token, relationship),
  ACTIONS.CREATE_LIST_RELATIONSHIP, 'Unable to create list relationship');

export const updateListRelationship = (token, id, relationship) => genericAPIActionCreator(() => API.updateListRelationship(token, id, relationship),
  ACTIONS.UPDATE_LIST_RELATIONSHIP, `Unable to update list relationship ${id}`);

export const deleteListRelationship = (token, id) => genericAPIActionCreator(() => API.deleteListRelationship(token, id),
  ACTIONS.DELETE_LIST_RELATIONSHIP, `Unable to delete list relationship ${id}`);

export const updatePage = (token, id, content) => genericAPIActionCreator(() => API.updatePage(token, id, content),
  ACTIONS.UPDATE_PAGE, `Unable to update page ${id}`);

export const updateEvent = (token, id, event) => genericAPIActionCreator(() => API.updateEvent(token, id, event),
  ACTIONS.UPDATE_EVENT, `Unable to update event ${id}`);

export const createDataSetResponse = (token, dataSetId, subject, owner, status, values) =>
  genericAPIActionCreator(() => API.createDataSetResponse(token, dataSetId, subject, owner, status, values),
    ACTIONS.CREATE_DATA_SET_RESPONSE, `Unable to create data set response for data set ${dataSetId}`);

export const updateDataSetResponse = (token, id, status, values) =>
  genericAPIActionCreator(() => API.updateDataSetResponse(token, id, status, values),
    ACTIONS.UPDATE_DATA_SET_RESPONSE, `Unable to update data set response ${id}`);

export default ACTIONS;
