import { connect } from 'react-redux';

import FinderView from './finder_view';

import { getCurrentUser, getSelectedEntity, getEntityType, getEntityTypeBySlug, getUserEntities, getCurrentEntity } from '../../../reducer';

const mapStateToProps = (state, { match: { params: { entityId, entityTypeSlug } } }) => {
  const currentEntity = getCurrentEntity(state, entityId);
  const selectedEntity = getSelectedEntity(state);

  return {
    user: getCurrentUser(state),
    userEntities: getUserEntities(state),
    entityTypeBySlug: getEntityTypeBySlug(state, entityTypeSlug),
    getEntityType: (id) => getEntityType(state, id),
    selectedEntity,
    selectedEntityType: selectedEntity && getEntityType(state, selectedEntity.entity_type),
    currentEntity,
    currentEntityType: currentEntity && getEntityType(state, currentEntity.entity_type),
  };
};

const FinderContainer = connect(mapStateToProps)(FinderView);

export default FinderContainer;
