import { normalize } from 'normalizr';
import { saveAs } from 'file-saver';
import * as schema from './schema';
import * as RealBackend from './real_backend';
import * as MockBackend from './mock_backend';

let SelectedBackend;

if (process.env.REACT_APP_API_ENDPOINT === undefined) {
  SelectedBackend = MockBackend;
} else {
  SelectedBackend = RealBackend;
}

const normalizeWidget = (response) => normalize(response.data, schema.widget);
const normalizeWidgets = (response) => normalize(response.data, [schema.widget]);

const normalizeInvitation = (response) => normalize(response.data, schema.invitation);
const normalizeInvitations = (response) => normalize(response.data, [schema.invitation]);

const normalizeUserEntity = (response) => normalize(response.data, schema.userEntity);
const normalizeUserEntities = (response) => normalize(response.data, [schema.userEntity]);

const normalizeCurrentEntity = (response) => normalize(response.data, schema.currentEntity);
const normalizeCurrentEntities = (response) => normalize(response.data, [schema.currentEntity]);
const normalizeCurrentEntityReference = (response) => normalize(response.data, schema.currentEntityReference);
const normalizeCurrentEntitySubjectOf = (response) => normalize(response.data, [schema.currentEntitySubjectOf]);

const normalizeCurrentRelationship = (response) => normalize(response.data, schema.currentRelationship);

const normalizeListEntity = (response) => normalize(response.data, schema.listEntity);
const normalizeListEntities = (response) => ({ count: response.headers['x-total-count'], ...normalize(response.data, [schema.listEntity]) });

const normalizeListEntityReference = (response) => normalize(response.data, schema.listEntityReference);
const normalizeListEntitySubjectOf = (response) => normalize(response.data, [schema.listEntitySubjectOf]);

const normalizeListRelationship = (response) => normalize(response.data, schema.listRelationship);

const normalizeEntityTypes = (response) => normalize(response.data, [schema.entityType]);
const normalizeEntityTypeSubjectOfs = (response) => normalize(response.data, [schema.entityTypeSubjectOf]);

const normalizePage = (response) => normalize(response.data, schema.page);

const normalizeEvent = (response) => normalize(response.data, schema.event);

const normalizeDataSetResponse = (response) => normalize(response.data, schema.dataSetResponse);

export const signIn = (email, password) => SelectedBackend.signIn(email, password);
export const finishSignIn = () => SelectedBackend.finishSignIn();
export const refreshToken = (user) => SelectedBackend.refreshToken(user);
export const register = (info) => SelectedBackend.register(info);
export const confirmEmail = (token) => SelectedBackend.confirmEmail(token);
export const updateCurrentUser = (token, props) => SelectedBackend.updateCurrentUser(token, props);

export const forgotPassword = (email) => SelectedBackend.forgotPassword(email);
export const resetPassword = (token, password) => SelectedBackend.resetPassword(token, password);

export const listWidgets = () => SelectedBackend.listWidgets().then(normalizeWidgets);
export const updateWidget = (uid, props) => SelectedBackend.updateWidget(uid, props).then(normalizeWidget);

export const listGlobalSettings = () => SelectedBackend.listGlobalSettings();

export const listInvitations = (token) => SelectedBackend.listInvitations(token).then(normalizeInvitations);
export const createInvitation = (token, invitation) => SelectedBackend.createInvitation(token, invitation).then(normalizeInvitation);
export const deleteInvitation = (token, id) => SelectedBackend.deleteInvitation(token, id).then(() => ({ id }));

export const fetchUserEntity = (token, id) => SelectedBackend.fetchEntity(token, id).then(normalizeUserEntity);
export const fetchUserEntityAncestors = (token, id) => SelectedBackend.fetchEntityAncestors(token, id).then(normalizeUserEntities);
export const createUserEntity = (token, entity) => SelectedBackend.createEntity(token, entity).then(normalizeUserEntity);

export const fetchCurrentEntity = (token, id) => SelectedBackend.fetchEntity(token, id).then(normalizeCurrentEntity);
export const fetchCurrentEntityParents = (token, id, entityTypeClass, ids) => SelectedBackend.fetchEntityParents(token, id, entityTypeClass, ids)
  .then(normalizeCurrentEntities);
export const fetchCurrentEntityChildren = (token, id, entityTypeClass, associated) =>
  SelectedBackend.fetchEntityChildren(token, id, entityTypeClass, associated).then(normalizeCurrentEntities);
export const fetchCurrentEntityReference = (token, id) => SelectedBackend.fetchEntityReference(token, id).then(normalizeCurrentEntityReference);
export const fetchCurrentEntitySubject = (token, id) => SelectedBackend.fetchEntitySubject(token, id).then(normalizeCurrentEntity);
export const fetchCurrentEntitySubjectOf = (token, id) => SelectedBackend.fetchEntitySubjectOf(token, id).then(normalizeCurrentEntitySubjectOf);
export const listCurrentEntities = (token, entityTypeSlug, page, size) =>
  SelectedBackend.listEntities(token, entityTypeSlug, page, size).then(normalizeCurrentEntities);
export const createCurrentEntity = (token, entity) => SelectedBackend.createEntity(token, entity).then(normalizeCurrentEntity);
export const updateCurrentEntity = (token, id, entity) => SelectedBackend.updateEntity(token, id, entity).then(normalizeCurrentEntity);

export const fetchListEntity = (token, id) => SelectedBackend.fetchEntity(token, id).then(normalizeListEntity);
export const fetchListEntityParents = (token, id, entityTypeClass, ids) =>
  SelectedBackend.fetchEntityParents(token, id, entityTypeClass, ids).then(normalizeListEntities);
export const fetchListEntityChildren = (token, id, entityTypeClass, associated, num, size, sort, query, statuses, filters) =>
  SelectedBackend.fetchEntityChildren(token, id, entityTypeClass, associated, num, size, sort, query, statuses, filters).then(normalizeListEntities);
export const fetchListEntityReference = (token, id) => SelectedBackend.fetchEntityReference(token, id).then(normalizeListEntityReference);
export const fetchListEntitySubject = (token, id) => SelectedBackend.fetchEntitySubject(token, id).then(normalizeListEntity);
export const fetchListEntitySubjectOf = (token, id) => SelectedBackend.fetchEntitySubjectOf(token, id).then(normalizeListEntitySubjectOf);
export const createListEntity = (token, entity) => SelectedBackend.createEntity(token, entity).then(normalizeListEntity);
export const updateListEntity = (token, id, entity) => SelectedBackend.updateEntity(token, id, entity).then(normalizeListEntity);

export const listExportedEntities = (token, id, entityTypeClass) => SelectedBackend.listExportedEntities(token, id, entityTypeClass)
  .then((response) => saveAs(response.data, 'export.csv') || response);

export const createCurrentRelationship = (token, relationship) => SelectedBackend.createRelationship(token, relationship).then(normalizeCurrentRelationship);
export const updateCurrentRelationship = (token, id, relationship) => SelectedBackend.updateRelationship(token, id, relationship)
  .then(normalizeCurrentRelationship);
export const deleteCurrentRelationship = (token, id) => SelectedBackend.deleteRelationship(token, id).then(() => ({ id }));

export const createListRelationship = (token, relationship) => SelectedBackend.createRelationship(token, relationship).then(normalizeListRelationship);
export const updateListRelationship = (token, id, relationship) => SelectedBackend.updateRelationship(token, id, relationship)
  .then(normalizeListRelationship);
export const deleteListRelationship = (token, id) => SelectedBackend.deleteRelationship(token, id).then(() => ({ id }));

export const listEntityTypes = (token) => SelectedBackend.listEntityTypes(token).then(normalizeEntityTypes);
export const fetchEntityTypeSubjectOf = (token, id) => SelectedBackend.fetchEntityTypeSubjectOf(token, id).then(normalizeEntityTypeSubjectOfs);

export const updatePage = (token, id, content) => SelectedBackend.updatePage(token, id, content).then(normalizePage);

export const updateEvent = (token, id, event) => SelectedBackend.updateEvent(token, id, event).then(normalizeEvent);

export const createDataSetResponse = (token, dataSetId, subject, owner, status, values) =>
  SelectedBackend.createDataSetResponse(token, dataSetId, subject, owner, status, values).then(normalizeDataSetResponse);

export const updateDataSetResponse = (token, id, status, values) =>
  SelectedBackend.updateDataSetResponse(token, id, status, values).then(normalizeDataSetResponse);

export const { OAUTH } = SelectedBackend;
