import { connect } from 'react-redux';

import EntitiesListFilterView from './entities_list_filter_view';

import { getForm, getDataSets } from '../../../../reducer';

const mapStateToProps = (state, { entity, entityType }) => {
  const form = `entity:${entity.id}:filter_advanced`;

  let fieldsObject = null;
  if (entityType.entity_class === 'ec_data_set') fieldsObject = getDataSets(state).filter((dataSet) => dataSet.referenced_by === entity.id).first();

  return {
    form,
    formData: entity && getForm(state).get(form),
    fieldsObject,
  };
};

const EntitiesListFilterContainer = connect(mapStateToProps)(EntitiesListFilterView);

export default EntitiesListFilterContainer;
