import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import theme from '../../common/theme';

import Button from '../button';

import ModalStyles, { CloseButton } from './modal_styles';

class ModalView extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    closeButton: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    offset: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    isOpen: false,
    closeButton: true,
    offset: '10%',
    children: null,
  };

  modalStyles() {
    const { offset } = this.props;

    return {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10000,
      },
      content: {
        backgroundColor: theme.foundation.themeBackgroundColorPrimary,
        borderTopRightRadius: 30,
        borderBottomLeftRadius: 30,
        top: offset,
        left: offset,
        right: offset,
        bottom: offset,
      },
    };
  }

  render() {
    const { isOpen, closeButton, closeModal, children } = this.props;

    return (
      <ReactModal isOpen={isOpen} style={this.modalStyles()} onRequestClose={closeModal}>
        <ModalStyles>
          {closeButton && (
            <CloseButton>
              <Button treatment="link" onClick={closeModal}><i className="fas fa-times-circle" /></Button>
            </CloseButton>
          )}

          {children}
        </ModalStyles>
      </ReactModal>
    );
  }
}


export default ModalView;
