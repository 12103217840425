import styled from 'styled-components';

const ContentStyles = styled.div`
  margin-bottom: 80px;
`;

export const ModalStyles = styled.div`
  .row .box {
    margin-top: 20px;
    border: 0 none;
    box-shadow: none;
  }
`;

export const DirectoryStyles = styled.div``;

export const DashboardStyles = styled.div``;

export default ContentStyles;
