import { connect } from 'react-redux';

import HierarchyShowView from './hierarchy_show_view';

import { getCurrentUser, getEntityTypeByEntityClass, getListDirectRelationshipsBetween } from '../../../../reducer';

import { fetchListEntity, updateListEntity, createCurrentEntity, deleteListRelationship } from '../../../actions';

const mapStateToProps = (state, { entity, childEntity }) => ({
  user: getCurrentUser(state),
  relationship: getListDirectRelationshipsBetween(state, entity, childEntity).first(),
  tagEntityType: getEntityTypeByEntityClass(state, 'ec_tag'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchListEntity: (token, id) => dispatch(fetchListEntity(token, id)),
  createCurrentEntity: (token, entity) => dispatch(createCurrentEntity(token, entity)),
  updateListEntity: (token, id, entity) => dispatch(updateListEntity(token, id, entity)),
  deleteListRelationship: (token, id) => dispatch(deleteListRelationship(token, id)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doFetchListEntity: (id) => dispatchProps.fetchListEntity(stateProps.user.authToken, id),
  doCreateCurrentEntity: (entity) => dispatchProps.createCurrentEntity(stateProps.user.authToken, entity),
  doDeleteListRelationship: (id) => dispatchProps.deleteListRelationship(stateProps.user.authToken, id),
  doHandleAddition: (id, entity) => dispatchProps.updateListEntity(stateProps.user.authToken, id, entity),
  doHandleDelete: (id, entity) => dispatchProps.updateListEntity(stateProps.user.authToken, id, entity),
});

const HierarchyShowContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(HierarchyShowView);

export default HierarchyShowContainer;
