import { connect } from 'react-redux';

import PageEditView from './page_edit_view';

import { getForm, getCurrentUser, getPages } from '../../../../reducer';
import { updatePage } from '../../../actions';

const mapStateToProps = (state, { entity }) => {
  const user = getCurrentUser(state);
  const page = getPages(state).filter((p) => p.referenced_by === entity.id).first();
  const id = page && page.id;
  const form = `entity:${entity.id}:edit`;

  return {
    user,
    page,
    id,
    form,
    pageForm: getForm(state).get(form),
  };
};

const mapDispatchToProps = {
  updatePage,
};

const PageEditContainer = connect(mapStateToProps, mapDispatchToProps)(PageEditView);

export default PageEditContainer;
