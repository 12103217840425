import styled from 'styled-components';

const ModalStyles = styled.div`
  background-color: white;
  height: 100%;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: relative;
  color: ${(props) => props.theme.foundation.modal.textColor};

  .modal-header {
    text-align: left;
    font-size: 1.3em;
    padding: 30px 0 25px;
    width: 90%;
    margin: 0 auto;
  }

  .modal-body {
    width: 90%;
    margin: 0 auto;

    > div {
      margin-bottom: 20px;

      label {
        font-weight: bold;

        > div.label-description {
          font-weight: normal;
          margin: 5px 0;
        }
      }

      select {
        width: 100%;
        padding: 10px;
        border: 1px solid #CCCCCC;
      }
    }
  }

  .modal-footer {
    text-align: center;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;

  button {
    color: black;
    background-color: white;
    border: 1px solid black;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    text-align: center;
  }
`;

export default ModalStyles;
