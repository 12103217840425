import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Map, Record } from 'immutable';
import { reduxForm, Form } from 'redux-form/lib/immutable';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';
import DataSetResponseModel from '../../../models/data_set_response_model';

import FoundationField from '../../foundation_field';
import Button from '../../button';
import StyledLink from '../../styled_link';

import DataSetResponseEditStyles from './data_set_response_edit_styles';

class DataSetResponseEditView extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.instanceOf(UserModel).isRequired,
    entity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]).isRequired,
    entityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]).isRequired,
    childEntity: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityModel)]).isRequired,
    childEntityType: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(EntityTypeModel)]).isRequired,
    dataSet: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetModel)]),
    dataSetResponse: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(DataSetResponseModel)]),
    dataSetResponseForm: PropTypes.instanceOf(Map),
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    updateDataSetResponse: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
    dataSet: null,
    dataSetResponse: null,
    dataSetResponseForm: new Map(),
    pristine: false,
    invalid: false,
    submitting: false,
    submitSucceeded: null,
  };

  constructor(props) {
    super(props);

    this.updateDataSetResponse = this.updateDataSetResponse.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  updateDataSetResponse() {
    const { user, id, dataSetResponseForm, updateDataSetResponse } = this.props;

    if (user) updateDataSetResponse(user.authToken, id, dataSetResponseForm.get('values').get('fields'));
  }

  renderField(field) {
    const { dataSetResponseForm } = this.props;

    const valuesByField = dataSetResponseForm.get('values') && dataSetResponseForm.get('values').get('fields');
    return DataSetModel.displayConditionalField(field, valuesByField) && <FoundationField key={field} field={field} />;
  }

  render() {
    const { entity, entityType, childEntity, childEntityType, dataSet, dataSetResponse, handleSubmit, pristine, invalid, submitting,
      submitSucceeded } = this.props;

    if (submitSucceeded) {
      return <Redirect to={entityType.absoluteUrl(entity.id) + childEntityType.absoluteUrl(childEntity.id)} />;
    }

    return Record.isRecord(dataSet) && Record.isRecord(dataSetResponse) && (
      <DataSetResponseEditStyles>
        <Form onSubmit={handleSubmit(this.updateDataSetResponse)}>
          <div className="row">
            {dataSet.get('fields').sortBy((field) => field.get('sort_order')).map((field) => this.renderField(field))}
          </div>
          <div className="row">
            <div className="col-xs-6">
              <StyledLink treatment="button" secondary="true" thin="true" to={entityType.absoluteUrl(entity.id) + childEntityType.absoluteUrl(childEntity.id)}>
                Cancel
              </StyledLink>
            </div>
            <div className="col-xs-6" style={{ textAlign: 'right' }}>
              <Button treatment="button" primary="true" thin="true" disabled={pristine || invalid || submitting}>Save Changes</Button>
            </div>
          </div>
        </Form>
      </DataSetResponseEditStyles>
    );
  }
}

export default reduxForm({ warn: DataSetModel.reduxFormValidate, enableReinitialize: true })(DataSetResponseEditView);
