import { connect } from 'react-redux';
import InvitationsListView from './invitations_list_view';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const InvitationsListContainer = connect(mapStateToProps, mapDispatchToProps)(InvitationsListView);

export default InvitationsListContainer;
