import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import RelationshipModel from '../models/relationship_model';

const reducer = new ResourcefulReducer('current_relationships', RelationshipModel, {
  create: ACTIONS.CREATE_CURRENT_RELATIONSHIP,
  update: ACTIONS.UPDATE_CURRENT_RELATIONSHIP,
  remove: ACTIONS.DELETE_CURRENT_RELATIONSHIP,
});

const CurrentRelationshipReducer = (state, action) => {
  if (action.type === ACTIONS.CLEAR_CURRENT_RELATIONSHIPS) state = undefined; // eslint-disable-line no-param-reassign

  return reducer(state, action);
};

CurrentRelationshipReducer.selectors = reducer.selectors;

export default CurrentRelationshipReducer;
