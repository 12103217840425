import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { OrderedSet, List, Record } from 'immutable';

import Button from '../../button';
import StyledLink from '../../styled_link';
import SubjectOwnerModal from '../../subject_owner_modal';

import UserModel from '../../../../session/models/user_model';
import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';
import DataSetModel from '../../../models/data_set_model';

import DataSetResponseCreateStyles from './data_set_response_create_styles';

class DataSetResponseCreateView extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModel).isRequired,
    entity: PropTypes.instanceOf(EntityModel),
    entityType: PropTypes.instanceOf(EntityTypeModel),
    userEntities: PropTypes.instanceOf(OrderedSet),
    userEntitiesManager: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
    dataSet: PropTypes.instanceOf(DataSetModel),
    getDataSetResponse: PropTypes.func.isRequired,
    createDataSetResponse: PropTypes.func.isRequired,
    getCurrentEntity: PropTypes.func.isRequired,
    getEntityType: PropTypes.func.isRequired,
  }

  static defaultProps = {
    entity: null,
    entityType: null,
    userEntities: new OrderedSet(),
    userEntitiesManager: new OrderedSet(),
    dataSet: null,
  };

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.newDataSetResponse = this.newDataSetResponse.bind(this);
    this.createDataSetResponse = this.createDataSetResponse.bind(this);
    this.renderNewEntityButton = this.renderNewEntityButton.bind(this);

    this.state = { creating: false, isOpen: false, response: null };
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  newDataSetResponse() {
    const { user, userEntities, userEntitiesManager, dataSet, createDataSetResponse } = this.props;

    const potentialSubjects = dataSet.filterEntities(userEntities, 'response_subject_entity_type');
    const potentialOwners = dataSet.filterEntities(userEntitiesManager, 'response_owner_entity_type');

    if (potentialSubjects.size === 1 && potentialOwners.size === 1) {
      this.setState({ creating: true });
      // the immediate create button in the action bar creates a draft, hence 0 for status
      createDataSetResponse(user.authToken, dataSet.id, { value: potentialSubjects.first().id }, { value: potentialOwners.first().id }, 0)
        .then((response) => this.setState({ response }));
    } else {
      this.setState({ isOpen: true });
    }
  }

  createDataSetResponse(subject, owner) {
    const { user, dataSet, createDataSetResponse } = this.props;

    // the immediate create button in the action bar creates a draft, hence 0 for status
    createDataSetResponse(user.authToken, dataSet.id, subject, owner, 0).then((response) => this.setState({ response }));
  }

  renderNewEntityButton(potentialSubjects, potentialOwners) {
    const { entity, entityType, dataSet, getEntityType } = this.props;
    const { creating } = this.state;

    const responseEntityType = getEntityType(dataSet.response_entity_type);

    const statelessFormButton = responseEntityType && (
      <StyledLink treatment="button" primary="true" to={`${entityType.absoluteUrl(entity.id)}/responses/new`}>
        <i className={`fas fa-${responseEntityType.icon}`} /> {!entity.redirect_id && <React.Fragment>New</React.Fragment>} {entity.name}
      </StyledLink>
    );

    const immediateDraftButton = responseEntityType && (
      <Button treatment="button" primary="true" onClick={this.newDataSetResponse} disabled={creating}>
        <i className={`fas fa-${responseEntityType.icon}`} /> New {entity.name}
      </Button>
    );

    if (entity.visibility === 'v_global' || dataSet.response_subject_force_new || dataSet.response_immediate_draft_disabled) {
      return statelessFormButton;
    } if (potentialSubjects.size > 0 && potentialOwners.size > 0) {
      return immediateDraftButton;
    }

    return null;
  }

  render() {
    const { userEntities, userEntitiesManager, entity, entityType, dataSet, getDataSetResponse, getCurrentEntity, getEntityType } = this.props;
    const { creating, isOpen, response } = this.state;

    if (response) {
      const dataSetResponse = getDataSetResponse(response.result);
      const dataSetResponseEntity = getCurrentEntity(dataSetResponse.referenced_by);
      const dataSetResponseEntityType = getEntityType(dataSetResponseEntity.entity_type);
      return <Redirect to={`${dataSetResponseEntityType.absoluteUrl(dataSetResponseEntity.id)}/edit`} />;
    }

    if (!Record.isRecord(entity) || !Record.isRecord(entityType) || !Record.isRecord(dataSet)) return null;

    const potentialSubjects = dataSet.filterEntities(userEntities, 'response_subject_entity_type');
    const potentialOwners = dataSet.filterEntities(userEntitiesManager, 'response_owner_entity_type');

    return (
      <DataSetResponseCreateStyles>
        {this.renderNewEntityButton(potentialSubjects, potentialOwners)}

        {creating && <i className="fas fa-spinner fa-spin" />}

        {isOpen && (
          <SubjectOwnerModal
            entity={entity}
            isOpen
            potentialSubjects={potentialSubjects}
            potentialOwners={potentialOwners}
            submitCallback={this.createDataSetResponse}
            closeCallback={this.closeModal}
          />
        )}
      </DataSetResponseCreateStyles>
    );
  }
}

export default DataSetResponseCreateView;
