import { fromJS, OrderedSet, Record } from 'immutable';
import { combineReducers } from 'redux-immutable';

const EMPTY_MAP = fromJS({});
const DEFAULT_STATE = Record({ byId: EMPTY_MAP, lastUpdated: false });

const getOne = (state, id) => state.getIn(['byId', id]);
const getList = (state, list = new OrderedSet(state.get('byId').keySeq())) => list.map((id) => getOne(state, id));
const getLastUpdated = (state) => state.get('lastUpdated');

// Supports read-only resources.
export default function ReadonlyResourcefulReducer(resourceName, model, { actionTypes } = {}) {
  if (!model) {
    throw Error('Missing model');
  }

  const actionTypeList = fromJS(actionTypes || []);
  const ResourceModel = model;
  const asModels = (resources) => fromJS(resources).map((resource) => new ResourceModel(resource));

  const byId = (state = EMPTY_MAP, action) => {
    if (action.response && action.response.entities && action.response.entities[resourceName]) {
      return state.merge(asModels(action.response.entities[resourceName]));
    }
    if (!(state instanceof Map)) {
      return fromJS(state);
    }
    return state;
  };

  const lastUpdated = (state = false, action) => {
    if (actionTypeList.includes(action.type)) {
      return Date.now();
    }
    return state;
  };

  const reducer = combineReducers({ byId, lastUpdated }, DEFAULT_STATE);
  reducer.selectors = { getOne, getList, getLastUpdated };
  return reducer;
}
