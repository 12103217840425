import { connect } from 'react-redux';

import HierarchyCreateView from './hierarchy_create_view';

import { getForm, getCurrentUser, getEntityType } from '../../../../reducer';

import { createListEntity } from '../../../actions';

const mapStateToProps = (state, { entity, entityType }) => ({
  user: getCurrentUser(state),
  form: `entity:${entity.id}`,
  newEntityForm: getForm(state).get(`entity:${entity.id}`),
  childEntityType: entityType && getEntityType(state, entityType.child_entity_type),
});

const mapDispatchToProps = {
  createListEntity,
};

const HierarchyCreateContainer = connect(mapStateToProps, mapDispatchToProps)(HierarchyCreateView);

export default HierarchyCreateContainer;
