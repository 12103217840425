import styled from 'styled-components';

const VisibilityModalStyles = styled.div`
  .modal-body .visibility-option {
    padding: 10px 15px;

    .header {
      font-weight: bold;
    }

    .visibility-icon {
      text-align: right;

      i {
        margin: 0 15px 0 10px;
      }
    }

    .description {
      margin-top: 4px;
      font-size: 0.8em;
      color: ${(props) => props.theme.foundation.modal.textColorLight};
    }
  }

  .modal-footer {
    margin-top: 25px;
  }
`;

export default VisibilityModalStyles;
