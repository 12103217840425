import styled from 'styled-components';

const HierarchyProfileStyles = styled.div`
  color: ${(props) => props.theme.foundation.content.textColor};
  margin-bottom: 20px;

  .entity-name {
    min-height: 20px;
    margin-bottom: 15px;

    button {
      font-size: 1.3rem;
      text-align: left;
      padding: 5px 0;
      border: 1px dashed transparent;
      width: 70%;
      color: ${(props) => props.theme.foundation.content.textColor};
    }

    input {
      font-size: 1.3rem;
      width: 70%;
      color: ${(props) => props.theme.foundation.content.textColor};
      border: 1px solid ${(props) => props.theme.foundation.content.borderColor};
    }

    &.entity-name-editable {
      button {
        i {
          display: none;
          position: absolute;
          right: 2px;
          bottom: 5px;
          color: ${(props) => props.theme.foundation.content.textColorLight};
          font-size: 0.8em;
          margin-left: 12px;
        }

        &:hover {
          border: 1px dashed ${(props) => props.theme.foundation.content.borderColor};
          text-decoration: none;

          i {
            display: inline-block;
          }
        }
      }
    }
  }

  .profile-left {
    padding: 10px 40px 10px 10px;
    text-align: left;

    .profile-icon-container {
      float: left;

      span.profile-icon {
        display: inline-block;
        background-color: ${(props) => props.theme.foundation.content.iconBackgroundColor};
        color: white;
        border-radius: 10px;
        padding: 10px;
        margin-right: 15px;
      }
    }

    .profile-name-description {
      padding: 4px 0 0 100px;

      .profile-description {
        color: ${(props) => props.theme.foundation.content.textColorLight};
        line-height: 1.3em;
      }
    }
  }

  .profile-right {
    padding: 14px 10px 10px;
    position: relative;
  }

  .profile-details {
    .profile-label {
      font-size: 0.85em;
      margin-bottom: 4px;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: ${(props) => props.theme.foundation.content.textColorLight};
    }

    .profile-value {
      font-size: 0.9em;
      margin-bottom: 10px;
      padding-left: 4px;

      a {
        color: ${(props) => props.theme.foundation.content.textColor};
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .profile-create {
    text-align: left;
    padding-top: 20px;
  }

  .profile-edit {
    position: absolute;
    top: 6px;
    right: 10px;

    a {
      color: ${(props) => props.theme.foundation.content.textColorLight};
      text-decoration: none;
    }

    a:hover {
      color: ${(props) => props.theme.foundation.content.textColor};
    }
  }
`;

export default HierarchyProfileStyles;
