import styled from 'styled-components';

const EventEditStyles = styled.div`
  margin: 0 10px;

  .wrapper {
    border: 1px solid #EEE;

    .editor {
      margin: 0 5px;

      .public-DraftEditor-content {
        height: 150px;
      }
    }
  }

  .row > div {
    margin-bottom: 15px;
  }

  .event-date-fields {
    > div, > span {
      display: inline-block;
      margin-right: 10px;

      input {
        display: block;
        margin-top: 5px;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #979797
      }
    }
  }

  .event-all-day-field {
    padding: 0 5px 0 0;
  }

  .event-label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 8px;
  }
`;

export default EventEditStyles;
