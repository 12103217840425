import { connect } from 'react-redux';

import EventMetaView from './event_meta_view';

import { getCurrentUser, getEntityType } from '../../../../../reducer';
import { updateCurrentEntity } from '../../../../actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  getEntityType: (id) => getEntityType(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentEntity: (token, id, entity) => dispatch(updateCurrentEntity(token, id, entity)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  doUpdateCurrentEntity: (id, name) => dispatchProps.updateCurrentEntity(stateProps.user.authToken, id, { name }),
});

const EventMetaContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventMetaView);

export default EventMetaContainer;
