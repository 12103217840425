import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router/immutable';
import IdleTimer from 'react-idle-timer';
import ErrorBoundary from './util/error_boundary';
import './app.css';
import theme from './common/theme';
import configureStore from './store';
// import Flamelink from './vendor/flamelink';
import featureFlag from './util/feature_flag';
import Vinetta from './vinetta';
import LandingPage from './landing_page';
import { getCurrentUser } from './reducer';
import { setTreatment, refreshToken, signOut } from './session/actions';

export const { store, history, persistor } = configureStore();

// Flamelink.init(store);

global.setTreatment = (key, value) => store.dispatch(setTreatment(key, value));
global.showApp = () => global.setTreatment('showApp', 'show');
global.hideApp = () => global.setTreatment('showApp', false);

const Root = featureFlag('showApp', Vinetta, LandingPage, 'show');

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IdleTimer
              timeout={1000 * 60 * 55}
              throttle={60000}
              onAction={() => {
                const currentUser = getCurrentUser(store.getState());
                return currentUser && currentUser.signedIn && store.dispatch(refreshToken(currentUser));
              }}
              onIdle={() => store.dispatch(signOut())}
            />
            <PersistGate loading={null} persistor={persistor}>
              <Root />
            </PersistGate>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
