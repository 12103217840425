import * as api from '../../api';
import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';

const ACTIONS = {
  LIST_WIDGETS: genericAPIActionTypes('ExampleApp:ListWidgets'),
};

export const listWidgets = genericAPIActionCreator(api.listWidgets, ACTIONS.LIST_WIDGETS, 'Unable to list widgets');

export default ACTIONS;
