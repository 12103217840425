import styled from 'styled-components';

const FoundationFieldStyles = styled.div`
  padding: 10px;
  margin-bottom: 10px;

  label {
    color: ${(props) => props.theme.foundation.textColor};
    font-weight: 500;
    font-size: 1em;

    input[type='text'], input[type='password'], input[type='email'], textarea, select {
      border: 1px solid hsl(0,0%,80%);
    }

    div {
      font-weight: normal;
      text-transform: none;
    }
  }
`;

export const SubLabel = styled.div`
  p {
    font-size: 0.75em;
    margin: 0;
    padding: 4px 0 0;
    color: ${(props) => props.theme.foundation.textColorLighter};
  }
`;

export const ContentName = styled.div`
  font-size: 1.05em;
  font-weight: bold;
`;

export const ContentDescription = styled.div`
  font-size: 0.8em;
`;

export default FoundationFieldStyles;
