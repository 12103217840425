import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';

import EntitiesList, { initialSortCreated } from '../../entities_list';

import EntityModel from '../../../models/entity_model';
import EntityTypeModel from '../../../models/entity_type_model';

import { associatedPermissionTypes } from '../../../../reducer';

import TagShowStyles, { Body } from './tag_show_styles';

const TagShowView = ({ entity, entityType, currentRelationships }) => (
  <TagShowStyles>
    <Body>
      <EntitiesList
        entity={entity}
        entityType={entityType}
        currentRelationships={currentRelationships}
        childEntityTypeClass="ec_hierarchy"
        parentEntityTypeClass="ec_data_set_response"
        hideFields={['Permission', 'DataSetResponses', 'Updated']}
        initialSort={initialSortCreated}
        showPermissionTypes={associatedPermissionTypes.toArray()}
      />
    </Body>
  </TagShowStyles>
);

TagShowView.propTypes = {
  entity: PropTypes.instanceOf(EntityModel).isRequired,
  entityType: PropTypes.instanceOf(EntityTypeModel).isRequired,
  currentRelationships: PropTypes.instanceOf(OrderedSet),
};

TagShowView.defaultProps = {
  currentRelationships: new OrderedSet(),
};

export default TagShowView;
