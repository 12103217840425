import { connect } from 'react-redux';

import InvitationModalView from './invitation_modal_view';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const InvitationModalContainer = connect(mapStateToProps, mapDispatchToProps)(InvitationModalView);

export default InvitationModalContainer;
