import styled from 'styled-components';

const DataSetResponseNewStyles = styled.div``;

export const Completed = styled.div`
  position: fixed;
  top: 35%;
  left: 35%;
  right: 35%;
  bottom: 35%;
  text-align: center;
`;

export const CompletedInset = styled.span`
  display: inline-block;
  color: green;
  background-color: white;
  height: 200px;
  width: 200px;
  border-radius: 100px;
  border: 1px solid #CCCCCC;
  box-shadow: 0 0 10px black;

  i {
    opacity: 0.6;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  span {
    font-weight: bold;
  }
`;

export default DataSetResponseNewStyles;
