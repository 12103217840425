import styled from 'styled-components';

const PageMetaStyles = styled.div`
  color: ${(props) => props.theme.foundation.content.textColor};
  margin-bottom: 20px;

  .entity-name {
    min-height: 20px;
    margin-bottom: 15px;

    button {
      font-size: 1.3rem;
      text-align: left;
      padding: 5px 0;
      border: 1px dashed transparent;
      width: 40%;
      color: ${(props) => props.theme.foundation.content.textColor};
    }

    input {
      font-size: 1.3rem;
      width: 40%;
      color: ${(props) => props.theme.foundation.content.textColor};
      border: 1px solid ${(props) => props.theme.foundation.content.borderColor};
    }

    &.entity-name-editable {
      button {
        i {
          display: none;
          position: absolute;
          right: 2px;
          bottom: 5px;
          color: ${(props) => props.theme.foundation.content.textColorLight};
          font-size: 0.8em;
          margin-left: 12px;
        }

        &:hover {
          border: 1px dashed ${(props) => props.theme.foundation.content.borderColor};
          text-decoration: none;

          i {
            display: inline-block;
          }
        }
      }
    }
  }

  .page-meta {
    padding: 10px 40px 10px 10px;
    text-align: left;

    .entity-icon-container {
      float: left;

      span.entity-icon {
        display: inline-block;
        background-color: ${(props) => props.theme.foundation.content.iconBackgroundColor};
        color: white;
        border-radius: 10px;
        padding: 10px;
        margin-right: 15px;
      }
    }

    .entity-name-container {
      padding: 4px 0 0 100px;
    }
  }
`;

export default PageMetaStyles;
